import { ApolloError } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Popover,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionQueryVariables,
  IGetDetectionVariables,
  SantizedDetection,
} from '@gamma/investigator/pages/detections';
import {
  Detection,
  IQueryAlertMetadataExcludedEntitiesPaginated,
  useQueryEscalationConfigurationsPublic,
} from '@gamma/investigator/queries';
import { RouterLink } from '@gamma/navigation';
import { LoadingSpinner } from '@gamma/progress';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  AssignUserContent,
  CloseDetectionContent,
  SendToContent,
  SuppressEntityContent,
} from '../PopoverContent';

const { viewAlertCategory, viewDetection } = i18n.pages.detections;

const { excludeEntity } = i18n.pages.entityDetails;

const { detectionIsClosed, suppress, unSuppress } = excludeEntity;

const { close, controls, detectionIsSent, sendTo, SNConfigureAndEnable } =
  i18n.pages.detections;

const { assignTo } = controls;

interface ActionsMenuProps {
  isRelated?: boolean;
  refetchQueries: any;
  isRowActions?: boolean;
  isRowActionsExpanded?: boolean;
  detectionData?: Detection | SantizedDetection;
  excludedEntitiesData?: IQueryAlertMetadataExcludedEntitiesPaginated;
  excludedEntitiesError?: ApolloError;
  excludedEntitiesLoading: boolean;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
}

export const ActionsMenu = ({
  isRelated,
  isRowActions,
  isRowActionsExpanded,
  detectionData,
  refetchQueries,
  excludedEntitiesData,
  excludedEntitiesError,
  excludedEntitiesLoading,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
}: ActionsMenuProps) => {
  const { userRole } = useContext(AuthContext);

  const toast = useToast();

  const [closeLoading, setCloseLoading] = useState<boolean>(false);
  const [suppressLoading, setSuppressLoading] = useState<boolean>(false);
  const [assignLoading, setAssignLoading] = useState<boolean>(false);
  const [sendToLoading, setSendToLoading] = useState<boolean>(false);

  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [isSuppressed, setIsSuppressed] = useState<boolean>(false);
  const [isSentTo, setIsSentTo] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const detailsTo = useMemo(() => {
    const searchDetectionId = detectionData?.detection_id;
    if (searchDetectionId) {
      searchParams.set('detection_id', searchDetectionId);
    }
    if (detectionData) {
      return {
        pathName: `${ROUTES.detections}/${detectionData.detection_id}/details`,
        search: searchParams.toString(),
      };
    }
    return undefined;
  }, [searchParams, detectionData]);

  const {
    isOpen: closeIsOpen,
    onClose: onCloseClose,
    onToggle: onToggleClose,
  } = useDisclosure();
  const {
    isOpen: suppressIsOpen,
    onClose: onCloseSuppress,
    onToggle: onToggleSuppress,
  } = useDisclosure();
  const {
    isOpen: sendToIsOpen,
    onClose: onCloseSendTo,
    onToggle: onToggleSendTo,
  } = useDisclosure();
  const {
    isOpen: assignIsOpen,
    onClose: onCloseAssign,
    onToggle: onToggleAssign,
  } = useDisclosure();

  const {
    loading: escalationConfigurationsLoading,
    error: escalationConfigurationsError,
    data: escalationConfigurationsData,
  } = useQueryEscalationConfigurationsPublic({
    skip: !detectionData?.tenant_info?.tenant_id,
    variables: {
      tenant: detectionData?.tenant_info?.tenant_id,
    },
  });

  const serviceNowConfiguration =
    escalationConfigurationsData?.queryEscalationConfigurations
      ?.escalation_configurations?.[0];

  useEffect(() => {
    setIsClosed(detectionData?.detection_status !== 'open');
    setIsSentTo(!!detectionData?.escalation_data?.escalation_status);
    if (excludedEntitiesData) {
      setIsSuppressed(
        excludedEntitiesData?.queryAlertMetadataExcludedEntitiesPaginated
          ?.excluded_entities?.length > 0,
      );
    }
  }, [detectionData, excludedEntitiesData]);

  return (
    <HStack>
      <ButtonGroup
        data-testid={
          userRole !== 'viewer'
            ? 'detection-actions-menu'
            : 'detection-actions-menu-viewer'
        }
      >
        {userRole !== 'viewer' && (
          <>
            <Popover
              isOpen={closeIsOpen}
              onClose={onCloseClose}
              placement="bottom-start"
            >
              <PopoverTrigger>
                <span>
                  <Tooltip
                    placement="bottom-start"
                    shouldWrapChildren={true}
                    label={isClosed ? detectionIsClosed : close}
                  >
                    <IconButton
                      size="box-sm"
                      variant="solid"
                      colorScheme="gray"
                      aria-label="action"
                      onClick={() => {
                        onToggleClose();
                        toast.closeAll();
                      }}
                      isDisabled={isClosed || closeLoading}
                      data-testid="close-detection-trigger"
                      icon={
                        closeLoading ? (
                          <LoadingSpinner size="sm" />
                        ) : (
                          <MuiIcon>check_circle</MuiIcon>
                        )
                      }
                    />
                  </Tooltip>
                </span>
              </PopoverTrigger>
              <CloseDetectionContent
                onClose={onCloseClose}
                setIsClosed={setIsClosed}
                detectionData={detectionData}
                setIsLoading={setCloseLoading}
                refetchQueries={refetchQueries}
                refetchMain={isRelated && isRowActions}
                excludedEntitiesLoading={excludedEntitiesLoading}
              />
            </Popover>
            {!isRowActions || isRowActionsExpanded ? (
              <Popover
                isOpen={sendToIsOpen}
                onClose={onCloseSendTo}
                placement="bottom-start"
              >
                <PopoverTrigger>
                  <span>
                    <Tooltip
                      placement="bottom-start"
                      shouldWrapChildren={true}
                      label={
                        isSentTo
                          ? detectionIsSent
                          : !sendToLoading && !serviceNowConfiguration?.enabled
                            ? SNConfigureAndEnable
                            : sendTo
                      }
                    >
                      <IconButton
                        size="box-sm"
                        variant="solid"
                        colorScheme="gray"
                        aria-label="action"
                        onClick={() => {
                          onToggleSendTo();
                          toast.closeAll();
                        }}
                        isDisabled={
                          isSentTo ||
                          sendToLoading ||
                          !serviceNowConfiguration ||
                          !serviceNowConfiguration?.enabled
                        }
                        data-testid="send-to-trigger"
                        icon={
                          sendToLoading || escalationConfigurationsLoading ? (
                            <LoadingSpinner size="sm" />
                          ) : (
                            <MuiIcon>forward</MuiIcon>
                          )
                        }
                      />
                    </Tooltip>
                  </span>
                </PopoverTrigger>
                <SendToContent
                  onClose={onCloseSendTo}
                  setIsSentTo={setIsSentTo}
                  detectionData={detectionData}
                  setIsLoading={setSendToLoading}
                  refetchQueries={refetchQueries}
                  refetchMain={isRelated && isRowActions}
                  serviceNowConfiguration={serviceNowConfiguration}
                  excludedEntitiesLoading={excludedEntitiesLoading}
                />
              </Popover>
            ) : null}
            <Popover
              isOpen={suppressIsOpen}
              onClose={onCloseSuppress}
              placement="bottom-start"
            >
              <PopoverTrigger>
                <span>
                  <Tooltip
                    placement="bottom-start"
                    shouldWrapChildren={true}
                    label={isSuppressed ? unSuppress : suppress}
                  >
                    <IconButton
                      size="box-sm"
                      variant="solid"
                      colorScheme="gray"
                      aria-label="action"
                      onClick={() => {
                        onToggleSuppress();
                        toast.closeAll();
                      }}
                      data-testid="suppress-entity-trigger"
                      icon={
                        suppressLoading ? (
                          <LoadingSpinner size="sm" />
                        ) : !isSuppressed ? (
                          <MuiIcon>notifications_off</MuiIcon>
                        ) : (
                          <MuiIcon>notifications</MuiIcon>
                        )
                      }
                    />
                  </Tooltip>
                </span>
              </PopoverTrigger>
              <SuppressEntityContent
                onClose={onCloseSuppress}
                detectionData={detectionData}
                setIsSuppressed={setIsSuppressed}
                setIsLoading={setSuppressLoading}
                refetchMain={isRelated && isRowActions}
                excludedEntitiesData={excludedEntitiesData}
                excludedEntitiesError={excludedEntitiesError}
              />
            </Popover>
            <Popover
              isOpen={assignIsOpen}
              onClose={onCloseAssign}
              placement="bottom-start"
            >
              <PopoverTrigger>
                <span>
                  <Tooltip
                    placement="bottom-start"
                    shouldWrapChildren={true}
                    label={assignTo}
                  >
                    <IconButton
                      size="box-sm"
                      variant="solid"
                      colorScheme="gray"
                      aria-label="action"
                      onClick={() => {
                        onToggleAssign();
                        toast.closeAll();
                      }}
                      data-testid="assign-detection-trigger"
                      icon={
                        assignLoading ? (
                          <LoadingSpinner size="sm" />
                        ) : (
                          <MuiIcon>account_circle</MuiIcon>
                        )
                      }
                    />
                  </Tooltip>
                </span>
              </PopoverTrigger>
              <AssignUserContent
                onClose={onCloseAssign}
                detectionData={detectionData}
                setIsLoading={setAssignLoading}
                refetchMain={isRelated && isRowActions}
                getDetectionVariables={getDetectionVariables}
                queryDetectionsPaginatedVariables={
                  queryDetectionsPaginatedVariables
                }
              />
            </Popover>
          </>
        )}
        {isRowActions && !isRowActionsExpanded && (
          <Tooltip label={viewDetection} placement="bottom-start">
            <IconButton
              as={RouterLink}
              size={'box-sm'}
              variant="solid"
              colorScheme="blue"
              aria-label="action"
              to={`${detailsTo?.pathName}?${detailsTo?.search}`}
              icon={<MuiIcon>visibility</MuiIcon>}
            />
          </Tooltip>
        )}
        {isRowActionsExpanded && (
          <>
            {detectionData?.alert_info?.content_id && (
              <Button
                size="sm"
                as={RouterLink}
                variant="solid"
                colorScheme="gray"
                to={`${ROUTES.systemSettingsAlertCatalogId(
                  detectionData.alert_info.content_id,
                  `?${detailsTo?.search}`,
                )}`}
              >
                {viewAlertCategory}
              </Button>
            )}
            <Button
              size="sm"
              as={RouterLink}
              variant="solid"
              colorScheme="blue"
              rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
              to={`${detailsTo?.pathName}?${detailsTo?.search}`}
            >
              {viewDetection}
            </Button>
          </>
        )}
      </ButtonGroup>
    </HStack>
  );
};
