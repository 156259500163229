import { Flex, useToast } from '@chakra-ui/react';
import { Switch } from '@gamma/form-fields';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { IActiveRow } from '@gamma/investigator/pages/system';
import {
  AlertMetadata,
  QUERY_ALERT_METADATA_PAGINATED,
  QueryAlertMetadataPaginatedVariables,
  useSetAlertActiveStatus,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import { cloneDeep } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';

const {
  active: activeText,
  alertCategoryStatus,
  errorRes,
  inactive,
  updateStatus,
  successRes,
  updateUnsuccessful,
} = i18n.pages.system.alertCatalog;

export interface AlertMetaStatusSwitchProps {
  tenant: string;
  activeRow?: Partial<AlertMetadata>;
  variables: QueryAlertMetadataPaginatedVariables;
  setActiveRow?: Dispatch<SetStateAction<IActiveRow>>;
}

export const AlertMetaStatusSwitch = ({
  tenant,
  activeRow,
  variables,
  setActiveRow,
}: AlertMetaStatusSwitchProps) => {
  const { active, title, logsource, content_id } = activeRow || {};

  const showToastMessage = useToast();

  const { userRole } = useContext(AuthContext);

  const handleToggleAlertStatus = () => {
    setAlertActiveStatus();
  };

  const [setAlertActiveStatus, { data, loading, error }] =
    useSetAlertActiveStatus({
      variables: {
        items: [
          {
            tenant,
            content_id,
            active: !active,
            alert_name: title,
            alert_type: logsource?.category,
          },
        ],
      },
      update: (cache, { data }) => {
        const prev: any = cache.readQuery({
          query: QUERY_ALERT_METADATA_PAGINATED,
          variables,
        });

        if (prev) {
          const prevDup = cloneDeep(prev);
          const [updatedMetaData] = data?.setAlertActiveStatus?.metadata || [];

          prevDup.queryAlertMetadataPaginated.metadata = [
            ...prevDup.queryAlertMetadataPaginated.metadata,
          ].map((meta) =>
            meta.content_id === updatedMetaData.content_id
              ? updatedMetaData
              : meta,
          );

          cache.writeQuery({
            query: QUERY_ALERT_METADATA_PAGINATED,
            data: prevDup,
            variables,
          });
        }
      },
      onError: () => {
        showToastMessage({
          status: 'error',
          title: errorRes,
          description: updateUnsuccessful,
          isClosable: true,
          position: 'bottom-right',
        });
      },
      onCompleted: ({ setAlertActiveStatus }) => {
        const activeStatus = setAlertActiveStatus?.metadata?.[0]
          ?.active as boolean;
        showToastMessage({
          status: 'success',
          title: successRes,
          description: i18n.formatString(
            updateStatus,
            activeStatus ? activeText : inactive,
          ),
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  useEffect(() => {
    if (data) {
      const { active, content_id, title, logsource } =
        data?.setAlertActiveStatus?.metadata?.[0];
      setActiveRow && setActiveRow({ active, content_id, title, logsource });
    }
  }, [data, setActiveRow]);

  return useMemo(() => {
    if (loading) {
      return (
        <Flex ml="6px">
          <GraphQLReqStatus spinnerSize="md" loading={loading} />
        </Flex>
      );
    }

    return (
      <Switch
        name="status"
        isChecked={active}
        isLabelHidden={true}
        label={alertCategoryStatus}
        isDisabled={userRole === 'viewer'}
        data-testid="alert-meta-status-switch"
        onChange={handleToggleAlertStatus}
      />
    );
  }, [alertCategoryStatus, loading, active, handleToggleAlertStatus]);
};
