import {
  Box,
  Button,
  ButtonGroup,
  CloseButton,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Tag,
  TagLabel,
  chakra,
  useTheme,
} from '@chakra-ui/react';
import { getColor, transparentize } from '@chakra-ui/theme-tools';
import { MuiIcon } from '@gamma/display';
import { ReactNode } from 'react';
import { OptionType } from '../../hooks';

export interface ComboboxWrapperProps<Option extends OptionType> {
  children: ReactNode;
  id?: string;
  onOpen: () => void;
  isDisabled: boolean;
  isOpen: boolean;
  hasValue: boolean;
  hasMultiValue: boolean;
  value: Option[];
  category: string;
  ['data-testid']?: string;
  onChange?: (value: Option[]) => void;
  onClearAll: () => void;
  onCancel: () => void;
  onApply: () => void;
  size: 'xs' | 'sm' | 'md';
}

export const ComboboxWrapper = <Option extends OptionType>({
  children,
  id,
  onOpen: onOpenProp,
  isDisabled,
  isOpen,
  hasValue,
  hasMultiValue,
  value,
  category,
  onChange: onChangeProp,
  onClearAll,
  onCancel,
  onApply,
  'data-testid': dataTestId,
  size,
}: ComboboxWrapperProps<Option>) => {
  const theme = useTheme();
  const onChange: Exclude<typeof onChangeProp, undefined> = (options) => {
    if (!isDisabled && onChangeProp) {
      onChangeProp(options);
    }
  };
  const onOpen = () => {
    if (!isDisabled) {
      onOpenProp();
    }
  };
  return (
    <Popover isOpen={isOpen} onClose={onApply} placement="bottom-start">
      <PopoverTrigger>
        <Box
          id={id}
          onKeyDown={(event) => {
            if ([' ', 'Enter', 'ArrowDown'].includes(event.key)) {
              event.preventDefault(); // stops select search from being changed on open
              onOpen();
            }
          }}
          tabIndex={0}
          onClick={onOpen}
          w="fit-content"
          boxShadow="inset 0 0 0 1px var(--chakra-colors-border-1)"
          borderRadius="base"
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          opacity={isDisabled ? 0.5 : undefined}
          bg={
            hasValue
              ? transparentize(getColor(theme, 'blue.500'), 0.1)(theme)
              : 'layer.0.active'
          }
          borderColor={'border.1'}
          py={{ xs: 0.5, sm: 1, md: 1.5 }[size]}
          px={2}
          fontSize={{ xs: 'sm', sm: 'sm', md: 'md' }[size]}
          lineHeight={{ xs: 'sm', sm: 'sm', md: 'md' }[size]}
          position="relative"
          role="combobox"
          aria-label={`${category}${hasValue ? `: ${value[0].label}` : ''}`} // category || category: first value
          data-testid={`${dataTestId}-trigger`}
        >
          <HStack spacing={1}>
            <chakra.span data-testid={`${dataTestId}-label`}>
              {category}
              {hasValue ? `: ${value[0].label}` : ''}
            </chakra.span>
            {hasMultiValue ? (
              <>
                <Tag
                  variant="solid"
                  colorScheme="blue"
                  size={{ xs: 'xs', sm: 'sm', md: 'sm' }[size]}
                  data-testid={`${dataTestId}-multi-value-tag`}
                >
                  <TagLabel>+{value.length - 1}</TagLabel>
                </Tag>
                <CloseButton
                  cursor="inherit"
                  boxSize={5}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange([]);
                  }}
                  data-testid={`${dataTestId}-clear`}
                >
                  <MuiIcon size="sm">close</MuiIcon>
                </CloseButton>
              </>
            ) : (
              <MuiIcon>keyboard_arrow_down</MuiIcon>
            )}
          </HStack>
        </Box>
      </PopoverTrigger>
      {isOpen && !isDisabled && (
        <PopoverContent>
          <PopoverBody data-testid={`${dataTestId}-popover`}>
            {children}
          </PopoverBody>
          <PopoverFooter>
            <Flex justifyContent="space-between">
              <Button
                variant="link"
                size="xs"
                onClick={onClearAll}
                data-testid={`${dataTestId}-clear-all`}
              >
                Clear all
              </Button>
              <ButtonGroup variant="solid" size="xs">
                <Button
                  colorScheme="gray"
                  onClick={onCancel}
                  data-testid={`${dataTestId}-cancel`}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={onApply}
                  data-testid={`${dataTestId}-apply`}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      )}
    </Popover>
  );
};
