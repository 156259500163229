import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

export interface IGetPrivileges {
  getPrivileges: string;
}

export const GET_PRIVILEGES = gql`
  query getPrivileges {
    getPrivileges
  }
`;

export const useGetPrivileges = (
  options?: QueryHookOptions<IGetPrivileges, OperationVariables>,
) => {
  return useQuery(GET_PRIVILEGES, options);
};
