import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const UPDATE_IDENTITY_PROVIDER = gql`
  mutation updateIdentityProvider(
    $provider_details: String
    $attribute_mapping: String
    $idp_identifiers: [String]
    $provider_type: String
    $idp_user_role_mapping: String
  ) {
    updateIdentityProvider(
      attribute_mapping: $attribute_mapping
      idp_identifiers: $idp_identifiers
      provider_details: $provider_details
      provider_type: $provider_type
      idp_user_role_mapping: $idp_user_role_mapping
    ) {
      status_code
      body {
        data
        message
        success
      }
    }
  }
`;

export const useUpdateIdentityProvider = (
  options: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(UPDATE_IDENTITY_PROVIDER, options);
};
