import { LicenseInfo } from '@gamma/investigator/queries';
import { AuthState, CognitoUserInterface } from '@gamma/shared/types';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { AuthContext, UserPrivileges, UserRole } from './AuthContext';

interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<CognitoUserInterface>();
  const [userAlias, setUserAlias] = useState<string>();
  const [userRole, setUserRole] = useState<UserRole>();
  const [userPrivileges, setUserPrivileges] = useState<UserPrivileges>();
  const [userPrivilegesLoading, setUserPrivilegesLoading] =
    useState<boolean>(true);
  const [userLicense, setUserLicense] = useState<LicenseInfo>();
  const [userLicenseLoading, setUserLicenseLoading] = useState<boolean>(true);
  const [isOrgTenant, setIsOrgTenant] = useState<boolean>(false);
  const [isPOVTenant, setIsPOVTenant] = useState<boolean>(false);
  const [isGPTEnabled, setIsGPTEnabled] = useState<boolean>(false);
  const [isGPTPrivateDataEnabled, setIsGPTPrivateDataEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (userPrivileges) {
      setIsGPTEnabled(userPrivileges?.chatgpt);
      setIsGPTPrivateDataEnabled(userPrivileges?.chatgpt_private_data ?? false);
    }
  }, [userPrivileges]);

  const value = useMemo(() => {
    return {
      authState,
      setAuthState,
      user,
      setUser,
      userAlias,
      setUserAlias,
      userRole,
      setUserRole,
      userPrivileges,
      setUserPrivileges,
      userPrivilegesLoading,
      setUserPrivilegesLoading,
      userLicense,
      setUserLicense,
      userLicenseLoading,
      setUserLicenseLoading,
      isOrgTenant,
      setIsOrgTenant,
      isPOVTenant,
      setIsPOVTenant,
      isGPTEnabled,
      isGPTPrivateDataEnabled,
    };
  }, [
    authState,
    setAuthState,
    user,
    setUser,
    userAlias,
    setUserAlias,
    userRole,
    setUserRole,
    userPrivileges,
    setUserPrivileges,
    userPrivilegesLoading,
    setUserPrivilegesLoading,
    userLicense,
    setUserLicense,
    userLicenseLoading,
    setUserLicenseLoading,
    isOrgTenant,
    setIsOrgTenant,
    isPOVTenant,
    setIsPOVTenant,
    isGPTEnabled,
    isGPTPrivateDataEnabled,
  ]);

  return (
    <AuthContext.Provider value={{ ...value }}>{children}</AuthContext.Provider>
  );
};
