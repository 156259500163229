import { HStack, Text, VStack } from '@chakra-ui/react';
import { TimestampCell } from '@gamma/investigator/components';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { removeLastBracketPair } from '@gamma/investigator/utilities';
import { Column, Panel, PanelHeader, PanelHeading } from '@gamma/layout';
import moment from 'moment';

import { ReactNode } from 'react';
import { SanitizedMlAlert } from '../../../DetectionAlerts';
import { LogscaleMenuButton } from '../LogscaleMenuButton';
import { MLAnalysisModal } from './MLAnalysisModal';

const { alerts } = i18n.pages.detections;

interface AlertRowMLProps {
  item: Partial<SanitizedMlAlert>;
  logScaleQuery?: string;
  showContent?: boolean;
}

interface AlertRowMLSplitProps {
  item: SanitizedMlAlert;
  loading: boolean | undefined;
  logScaleQuery?: string;
}

const MLDetailItem = ({
  header,
  detail,
}: {
  header: string;
  detail: ReactNode;
}) => (
  <HStack spacing={3} alignItems="start">
    <Text textStyle="body-md" minWidth="110px">
      {header}
    </Text>
    <Text textStyle="body-md">{detail}</Text>
  </HStack>
);

const MLTimestampCol = ({ item }: AlertRowMLProps) => {
  return (
    <MLDetailItem
      header={alerts.timestamp}
      detail={
        <TimestampCell
          timestamp={item?.alert_timestamp?.observed}
          isFromNow={false}
        />
      }
    />
  );
};

const MLScoreCol = ({ item }: AlertRowMLProps) => {
  return (
    <MLDetailItem
      header={alerts.ml.mlScore}
      detail={<Text>{item?.models?.[0]?.model_score ?? 'N/A'}</Text>}
    />
  );
};

const MLSipCol = ({ item }: AlertRowMLProps) => {
  const { alert_entity } = item;
  return (
    <MLDetailItem
      header={alerts.sourceIp}
      detail={<Text>{alert_entity?.entity_name}</Text>}
    />
  );
};

const MLDipCol = ({ item }: AlertRowMLProps) => {
  const { alert_entity } = item;
  return (
    <MLDetailItem
      header={alerts.destinationIp}
      detail={<Text>{alert_entity?.entity_name}</Text>}
    />
  );
};

const MLSipDipOneCol = ({ item }: AlertRowMLProps) => {
  const { alert_entity } = item;
  return (
    <MLDetailItem
      header={alerts.sourceIp}
      detail={<Text>{alert_entity?.entity_name}</Text>}
    />
  );
};

const MLSipDipTwoCol = ({ item }: AlertRowMLProps) => {
  const { related_entities } = item;
  return (
    <MLDetailItem
      header={alerts.destinationIp}
      detail={<Text>{related_entities?.[0]?.entity_name}</Text>}
    />
  );
};

const MLDomainCol = ({ item }: AlertRowMLProps) => {
  const { alert_entity } = item;
  return (
    <MLDetailItem
      header={alerts.domain}
      detail={<Text>{alert_entity?.entity_name}</Text>}
    />
  );
};

const MLSipDomainOneCol = ({ item }: AlertRowMLProps) => {
  const { related_entities } = item;
  return (
    <MLDetailItem
      header={alerts.sourceIp}
      detail={<Text>{related_entities?.[0]?.entity_name}</Text>}
    />
  );
};

const MLSipDomainTwoCol = ({ item }: AlertRowMLProps) => {
  const { alert_entity } = item;
  return (
    <MLDetailItem
      header={alerts.domain}
      detail={<Text>{alert_entity?.entity_name}</Text>}
    />
  );
};

export const MLButtonsCol = ({
  item,
  logScaleQuery,
  showContent,
}: AlertRowMLProps) => {
  const { start, end } = useDateRangeQueryString();

  const { alert_entity, alert_timestamp } = item;

  const timestamp = alert_timestamp?.observed;

  const humioStart = moment(timestamp * 1000)
    .subtract(1, 'hour')
    .valueOf();

  const humioEnd = moment(timestamp * 1000)
    .add(5, 'minutes')
    .valueOf();

  const formattedEntityName = removeLastBracketPair(alert_entity?.entity_name);

  const widgetType = logScaleQuery ? 'table-view' : 'list-view';

  const searchQueryString = `?query=${
    logScaleQuery ? encodeURIComponent(logScaleQuery) : formattedEntityName
  }&live=false&humioStart=${humioStart}&humioEnd=${humioEnd}&fullscreen=false&widgetType=${widgetType}&columns=%5B%7B"type"%3A"field"%2C"fieldName"%3A"%40timestamp"%2C"width"%3A210%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"%23path"%2C"width"%3A99%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"id.orig_h"%2C"width"%3A222%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"id.resp_h"%2C"width"%3A167%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"id.resp_p"%2C"width"%3A99%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"uid"%2C"width"%3A198%7D%5D&newestAtBottom=true&showOnlyFirstLine=false&start=${start}&end=${end}`;

  return (
    <>
      <LogscaleMenuButton
        searchQueryString={searchQueryString}
        showContent={showContent}
      />
      {showContent && <MLAnalysisModal item={item as SanitizedMlAlert} />}
    </>
  );
};

export const AlertRowML = ({ item, logScaleQuery }: AlertRowMLProps) => {
  return (
    <HStack spacing={4}>
      <VStack alignItems="start">
        <TimestampCell
          timestamp={item?.alert_timestamp?.observed}
          isFromNow={false}
        />
        <VStack spacing={1} alignItems="start" color="text.200">
          <HStack>
            <Text textStyle="body-md">{item?.alert_info?.alert_name}</Text>
            <Text textStyle="body-md">|</Text>
            <Text textStyle="body-md">{item?.alert_entity?.entity_name}</Text>
          </HStack>
          <HStack>
            <Text textStyle="body-md">{alerts.ml.mlScore}</Text>
            <Text textStyle="body-md">
              {item?.models?.[0]?.model_score ?? 'N/A'}
            </Text>
          </HStack>
        </VStack>
      </VStack>
      <MLButtonsCol item={item} logScaleQuery={logScaleQuery} />
    </HStack>
  );
};

export const AlertRowSplitML = ({ item }: AlertRowMLSplitProps) => {
  const { pipeline } = item;

  let components;

  if (pipeline === 'sip') {
    components = [MLSipCol, MLScoreCol];
  } else if (pipeline === 'dip') {
    components = [MLDipCol, MLScoreCol];
  } else if (pipeline === 'sipdip') {
    components = [MLSipDipOneCol, MLSipDipTwoCol, MLScoreCol];
  } else if (pipeline === 'domain') {
    components = [MLDomainCol, MLScoreCol];
  } else if (pipeline === 'sipdomain') {
    components = [MLSipDomainOneCol, MLSipDomainTwoCol, MLScoreCol];
  } else {
    components = [MLScoreCol];
  }

  return (
    <span data-testid="ml-alerts-wrapper">
      <Column>
        <Panel data-testid="ml-details-panel">
          <PanelHeader divider data-testid="ml-details-heading">
            <PanelHeading>{alerts.ml.details}</PanelHeading>
          </PanelHeader>
          <VStack alignItems="start">
            <VStack spacing={4} alignItems="start">
              <VStack spacing={1} alignItems="start">
                <MLDetailItem
                  header={alerts.category}
                  detail={<Text>{item?.alert_info?.alert_name}</Text>}
                />
                <MLTimestampCol item={item} />
              </VStack>
              <VStack spacing={1} alignItems="start">
                {components.map((component) => component({ item }))}
              </VStack>
            </VStack>
          </VStack>
        </Panel>
      </Column>
    </span>
  );
};
