import { ApolloError } from '@apollo/client';
import { Text, VStack } from '@chakra-ui/react';
import { SnippetBox } from '@gamma/display';
import {
  ChatGPTAnswer,
  ChatGPTPromptCloudPanel,
  ChatGPTQAPromptCloudPanel,
} from '@gamma/investigator/components';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  IGetAlertMetadata,
  IGetDetections,
  useGetAlertMetadataNonSuricataAlertAttack,
  useGetAlertMetadataNonSuricataAlertTTP,
  useGetAlertMetadataSuricataAlertExplain,
  useGetAlertMetadataSuricataInvestigateAlert,
  useGetAlertMetadataSuricataRuleDescription,
} from '@gamma/investigator/queries';
import { LoadingSpinner } from '@gamma/progress';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import dedent from 'ts-dedent';

import { IGetDetectionVariables } from '../../../../Components';
import { IDetectionContext } from '../../../Detection';

const { alerts, detection: detectionText } = i18n.pages.detections;

const { chatGPT } = i18n.pages.integrations;

interface GPTPromptsProps {
  detailsProps?: {
    detectionData?: IGetDetections;
    detectionError?: ApolloError;
    detectionLoading?: boolean;
    metadataData?: IGetAlertMetadata;
    metadataError?: ApolloError;
    metadataLoading?: boolean;
    getDetectionVariables?: IGetDetectionVariables;
  };
}

export const GPTPrompts = ({ detailsProps }: GPTPromptsProps) => {
  const {
    detectionData,
    detectionError,
    detectionLoading,
    metadataData,
    metadataError,
    metadataLoading,
    getDetectionVariables,
  } = useOutletContext<IDetectionContext>() || {};

  const { isGPTEnabled } = useContext(AuthContext);

  const detectionDetailsProps = useMemo(() => {
    return detailsProps
      ? {
          ...detailsProps,
        }
      : {
          detectionData,
          detectionError,
          detectionLoading,
          metadataData,
          metadataError,
          metadataLoading,
          getDetectionVariables,
        };
  }, [
    detailsProps,
    detectionData,
    detectionError,
    detectionLoading,
    metadataData,
    metadataError,
    metadataLoading,
    getDetectionVariables,
  ]);

  const [descriptionText, setDescriptionText] = useState<string>('');
  const [chatGPTAnswersList, setChatGPTAnswersList] = useState<ChatGPTAnswer[]>(
    [],
  );
  const [chatGPTQuestionsList, setChatGPTQuestionsList] = useState<string[]>(
    [],
  );
  const [suricataRuleText, setSuricataRuleText] = useState('');

  const detectionInfo =
    detectionDetailsProps?.detectionData?.getDetections?.detections[0];

  const { alert_info } = detectionInfo || {};

  const GPTQuestionList = Object.values(
    alert_info?.alert_type === 'suricata_corelight'
      ? chatGPT.suricataQuestions
      : chatGPT.nonSuricataQuestions,
  );

  useEffect(() => {
    if (alert_info?.alert_type) {
      setChatGPTQuestionsList(GPTQuestionList);
    }
  }, [alert_info?.alert_type]);

  const { description } =
    detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata[0] || {};

  const gptQueryVariables = {
    items: [
      {
        content_id: detectionInfo?.alert_info?.content_id,
        tenant: detectionInfo?.tenant_info?.tenant_id,
      },
    ],
  };

  // suri_alert_explain
  const [
    getSuricataAlertExplain,
    {
      loading: getSuricataAlertExplainLoading,
      data: getSuricataAlertExplainData,
      error: getSuricataAlertExplainError,
    },
  ] = useGetAlertMetadataSuricataAlertExplain({
    variables: gptQueryVariables,
  });

  // suri_investigate_alert
  const [
    getSuricataAlertInvestigation,
    {
      loading: getSuricataAlertInvestigationLoading,
      data: getSuricataAlertInvestigationData,
      error: getSuricataAlertInvestigationError,
    },
  ] = useGetAlertMetadataSuricataInvestigateAlert({
    variables: gptQueryVariables,
  });

  // non_suri_alert_attack
  const [
    getNonSuricataAlertAttack,
    {
      loading: getNonSuricataAlertAttackLoading,
      data: getNonSuricataAlertAttackData,
      error: getNonSuricataAlertAttackError,
    },
  ] = useGetAlertMetadataNonSuricataAlertAttack({
    variables: gptQueryVariables,
  });

  // non_suri_alert_ttp
  const [
    getNonSuricataAlertTTP,
    {
      loading: getNonSuricataAlertTTPLoading,
      data: getNonSuricataAlertTTPData,
      error: getNonSuricataAlertTTPError,
    },
  ] = useGetAlertMetadataNonSuricataAlertTTP({
    variables: gptQueryVariables,
  });

  const isLoadingGPTQuestionPromptData =
    getSuricataAlertExplainLoading ||
    getSuricataAlertInvestigationLoading ||
    getNonSuricataAlertAttackLoading ||
    getNonSuricataAlertTTPLoading;

  const hasGPTQuestionPromptError =
    getSuricataAlertExplainError ||
    getSuricataAlertInvestigationError ||
    getNonSuricataAlertAttackError ||
    getNonSuricataAlertTTPError;

  const chatGPTQuestionPromptData = useMemo(() => {
    return {
      suricataAlertExplain:
        getSuricataAlertExplainData?.getAlertMetadata?.metadata[0]
          ?.chatbot_chats?.suri_alert_explain,
      suricataAlertInvestigation:
        getSuricataAlertInvestigationData?.getAlertMetadata?.metadata[0]
          ?.chatbot_chats?.suri_investigate_alert,
      nonSuricataAlertAttack:
        getNonSuricataAlertAttackData?.getAlertMetadata?.metadata[0]
          ?.chatbot_chats?.non_suri_alert_attack,
      nonSuricataAlertTTP:
        getNonSuricataAlertTTPData?.getAlertMetadata?.metadata[0]?.chatbot_chats
          ?.non_suri_alert_ttp,
    };
  }, [
    getSuricataAlertExplainData,
    getSuricataAlertInvestigationData,
    getNonSuricataAlertAttackData,
    getNonSuricataAlertTTPData,
  ]);

  const callChatGPTPromptDataFunction = (question: string) => {
    const questionToAnswerMap = {
      [`${chatGPT.suricataQuestions.whatDoesThisAlertMean.toLowerCase()}`]:
        getSuricataAlertExplain,
      [`${chatGPT.suricataQuestions.whatNextStepShouldITake.toLowerCase()}`]:
        getSuricataAlertInvestigation,
      [`${chatGPT.nonSuricataQuestions.whatTypeOfAttacksMayBeAssociated.toLowerCase()}`]:
        getNonSuricataAlertAttack,
      [`${chatGPT.nonSuricataQuestions.whatAreSomeTechniquesSpecificToThisAlert.toLowerCase()}`]:
        getNonSuricataAlertTTP,
    };

    const gptFunction = questionToAnswerMap[question.toLowerCase()];

    if (typeof gptFunction === 'function') {
      gptFunction();
    }
  };

  // update answer list based on result of lazilyDynamicallyLoadGetAlertMetadata
  useEffect(() => {
    const whatDoesThisAlertMean =
      chatGPTQuestionPromptData?.suricataAlertExplain;
    const nextStepsAnswerText =
      chatGPTQuestionPromptData?.suricataAlertInvestigation;
    const associatedAttacksText =
      chatGPTQuestionPromptData?.nonSuricataAlertAttack;
    const ttpAdversariesText = chatGPTQuestionPromptData?.nonSuricataAlertTTP;

    const hasWhatDoesThisAlertMean = chatGPTAnswersList.some(
      (answer) =>
        answer.questionText.toLowerCase() ===
        chatGPT.suricataQuestions.whatDoesThisAlertMean.toLowerCase(),
    );

    const hasNextStepsInAnswersList = chatGPTAnswersList.some(
      (answer) =>
        answer.questionText.toLowerCase() ===
        chatGPT.suricataQuestions.whatNextStepShouldITake.toLowerCase(),
    );

    const hasAssociatedAttacksInAnswersList = chatGPTAnswersList.some(
      (answer) =>
        answer.questionText.toLowerCase() ===
        chatGPT.nonSuricataQuestions.whatTypeOfAttacksMayBeAssociated.toLowerCase(),
    );

    const hasTTPAdversariesInAnswersList = chatGPTAnswersList.some(
      (answer) =>
        answer.questionText.toLowerCase() ===
        chatGPT.nonSuricataQuestions.whatAreSomeTechniquesSpecificToThisAlert.toLowerCase(),
    );

    if (whatDoesThisAlertMean && !hasWhatDoesThisAlertMean) {
      setChatGPTAnswersList((prev) => [
        ...prev,
        {
          questionText: chatGPT.suricataQuestions.whatDoesThisAlertMean,
          answerText: whatDoesThisAlertMean,
        },
      ]);
    }

    if (nextStepsAnswerText && !hasNextStepsInAnswersList) {
      setChatGPTAnswersList((prev) => [
        ...prev,
        {
          questionText: chatGPT.suricataQuestions.whatNextStepShouldITake,
          answerText: nextStepsAnswerText,
        },
      ]);
    }

    if (associatedAttacksText && !hasAssociatedAttacksInAnswersList) {
      setChatGPTAnswersList((prev) => [
        ...prev,
        {
          questionText:
            chatGPT.nonSuricataQuestions.whatTypeOfAttacksMayBeAssociated,
          answerText: associatedAttacksText,
        },
      ]);
    }

    if (ttpAdversariesText && !hasTTPAdversariesInAnswersList) {
      setChatGPTAnswersList((prev) => [
        ...prev,
        {
          questionText:
            chatGPT.nonSuricataQuestions
              .whatAreSomeTechniquesSpecificToThisAlert,
          answerText: ttpAdversariesText,
        },
      ]);
    }
  }, [chatGPTQuestionPromptData]);

  const hasChatGPTQuestionPromptData = Boolean(
    (chatGPTQuestionPromptData?.suricataAlertExplain ||
      chatGPTQuestionPromptData?.suricataAlertInvestigation ||
      chatGPTQuestionPromptData?.nonSuricataAlertAttack ||
      chatGPTQuestionPromptData?.nonSuricataAlertTTP) &&
      descriptionText?.length > 0,
  );

  const {
    data: suricataAlertDescriptionData,
    loading: suricataAlertDescriptionLoading,
    error: suricataAlertDescriptionError,
  } = useGetAlertMetadataSuricataRuleDescription({
    skip:
      !isGPTEnabled ||
      !detectionInfo?.alert_info?.content_id ||
      detectionDetailsProps?.metadataLoading ||
      alert_info?.alert_type !== 'suricata_corelight' ||
      hasChatGPTQuestionPromptData,
    variables: gptQueryVariables,
  });

  const [currentQuestionPromptText, setCurrentQuestionPromptText] =
    useState<string>('');

  const handleUpdatingChatGPTQuestionsAndAnswersList = async (
    question: string,
    hasError: boolean,
  ) => {
    setCurrentQuestionPromptText(question);

    callChatGPTPromptDataFunction(question);

    const isQuestionInList = chatGPTQuestionsList.includes(question);

    if (isQuestionInList && !hasError) {
      const newQuestionsList = chatGPTQuestionsList.filter(
        (q) => q !== question,
      );
      setChatGPTQuestionsList(newQuestionsList);
    } else {
      setChatGPTQuestionsList([...chatGPTQuestionsList, question]);
    }

    if (hasError) {
      setChatGPTQuestionsList(GPTQuestionList);
    }
  };

  // set panel description
  useEffect(() => {
    if (detectionDetailsProps?.metadataLoading) {
      return;
    }

    if (
      alert_info?.alert_type === 'suricata_corelight' &&
      !descriptionText?.length
    ) {
      setDescriptionText(
        suricataAlertDescriptionData?.getAlertMetadata?.metadata?.[0]
          ?.chatbot_chats?.suri_describe_rule ?? '',
      );
    }

    if (
      description &&
      alert_info?.alert_type !== 'suricata_corelight' &&
      alert_info?.alert_type &&
      !descriptionText?.length
    ) {
      setDescriptionText(description);
    }

    const suricataRuleText =
      ((alert_info?.alert_type === 'suricata_corelight' &&
        suricataAlertDescriptionData?.getAlertMetadata?.metadata?.[0]?.rule) ||
        detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata[0]
          ?.rule) ??
      '';

    setSuricataRuleText(suricataRuleText);
  }, [
    suricataAlertDescriptionData,
    description,
    detectionDetailsProps?.metadataLoading,
    alert_info?.alert_type,
    detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata,
    descriptionText?.length,
  ]);

  return (
    <VStack w="100%" alignItems="start">
      {alert_info?.alert_type === 'suricata_corelight' && (
        <VStack w="100%" alignItems="start">
          <ChatGPTPromptCloudPanel
            titleText={chatGPT.description}
            descriptionText={descriptionText}
            isLoadingDescription={
              suricataAlertDescriptionLoading ||
              detectionDetailsProps?.metadataLoading
            }
            isDescriptionQuickView={false}
            loadingDescriptionError={suricataAlertDescriptionError}
            corelightDescription={description}
            storageKey="gpt-prompt-panel-suri-details"
            showGPTDisabled={false}
          />
          {detectionDetailsProps?.metadataLoading ? (
            <LoadingSpinner />
          ) : suricataRuleText ? (
            <>
              <Text>
                {alerts.suricata.title} {detectionText?.rule}
              </Text>
              <SnippetBox
                snippet={dedent`${suricataRuleText}`}
                isCopyable={true}
              />
            </>
          ) : null}
          <ChatGPTQAPromptCloudPanel
            titleText={chatGPT.askGPT}
            descriptionText={descriptionText}
            questionsTextList={chatGPTQuestionsList}
            answersTextList={chatGPTAnswersList}
            isLoadingAnswerList={isLoadingGPTQuestionPromptData}
            questionLoadingText={currentQuestionPromptText}
            onQuestionClick={handleUpdatingChatGPTQuestionsAndAnswersList}
            loadingAnswerListError={hasGPTQuestionPromptError}
            storageKey="gpt-prompt-panel-suri-ask-details"
          />
        </VStack>
      )}
      {alert_info?.alert_type !== 'suricata_corelight' && !description && (
        <ChatGPTPromptCloudPanel
          titleText={chatGPT.description}
          descriptionText={descriptionText}
          isLoadingDescription={
            !descriptionText && suricataAlertDescriptionLoading
          }
          isDescriptionQuickView={false}
          loadingDescriptionError={suricataAlertDescriptionError}
          storageKey="gpt-prompt-panel-details"
          showGPTDisabled={true}
        />
      )}
      {alert_info?.alert_type !== 'suricata_corelight' && description && (
        <ChatGPTQAPromptCloudPanel
          titleText={chatGPT.askGPT}
          descriptionText={descriptionText}
          questionsTextList={chatGPTQuestionsList}
          answersTextList={chatGPTAnswersList}
          isLoadingAnswerList={isLoadingGPTQuestionPromptData}
          questionLoadingText={currentQuestionPromptText}
          onQuestionClick={handleUpdatingChatGPTQuestionsAndAnswersList}
          loadingAnswerListError={hasGPTQuestionPromptError}
          isLoadingDescription={
            !descriptionText && suricataAlertDescriptionLoading
          }
          storageKey="gpt-prompt-non-suri-search-panel-details"
        />
      )}
    </VStack>
  );
};
