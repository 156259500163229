import { Box, useColorMode } from '@chakra-ui/react';
import {
  Detection,
  DetectionAlertSummary,
  useGetChartTimelineFilters,
  useGetDetectionTimelineSummary,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { OptionType } from '@gamma/form-fields';
import { i18n } from '@gamma/investigator/localization';
import _ from 'lodash';
import { RelatedDetectionsAlerts } from './Components/RelatedDetectionsAlerts';
import { ApexDetectionsChart } from './Components/RelatedDetectionsChart';

const { correlationChart } = i18n.pages.detections;
const { headings } = correlationChart;

interface RelatedDetectionsProps {
  detection: Detection;
  suricataRuleText: string;
  onPreviewOpen: () => void;
  selectedDetection?: Detection;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getDetectionsLoading: boolean;
  getAppConfigurationsData: string | null;
}

export const RelatedDetections = ({
  detection,
  onPreviewOpen,
  suricataRuleText,
  selectedDetection,
  getDetectionsLoading,
  setSelectedDetectionId,
  getAppConfigurationsData,
}: RelatedDetectionsProps) => {
  const { colorMode } = useColorMode();

  const [ts, setTs] = useState(0);

  const handleResize = () => {
    setTs(moment().unix());
  };
  window.onresize = _.debounce(handleResize, 500);

  useEffect(() => {
    setTs(moment().unix());
  }, [colorMode]);

  const {
    tenant,
    detection_id,
    earliest_start_timestamp,
    latest_start_timestamp,
  } = detection || {};

  const latestAlertDateTime = moment(latest_start_timestamp * 1000);

  const currentDiffLatest = moment
    .duration(moment().diff(latestAlertDateTime))
    .get('days');

  const showPastFrameAlerts =
    moment.duration(moment().diff(latestAlertDateTime)).get('months') > 0 ||
    moment.duration(moment().diff(latestAlertDateTime)).get('years') > 0;

  const endDateTimeOne =
    showPastFrameAlerts || currentDiffLatest >= 15
      ? moment(latest_start_timestamp * 1000).add(15, 'days')
      : moment();

  const endDateTimeTwo =
    showPastFrameAlerts || currentDiffLatest >= 15
      ? moment(latest_start_timestamp * 1000).add(15, 'days')
      : moment();

  const end = endDateTimeOne;
  const start = endDateTimeTwo.subtract(30, 'days');

  const [startSet, setStartSet] = useState<number | undefined>();
  const [endSet, setEndSet] = useState<number | undefined>();

  useEffect(() => {
    if (end && !endSet) {
      setEndSet(end.unix());
    }
    if (start && !startSet) {
      setStartSet(start.unix());
    }
  }, [end, start, endSet, startSet, setEndSet, setStartSet]);

  const maxRows = 5;

  const [interval, setInterval] = useState<number>(86400);
  const [alertFilters, setAlertFilters] = useState<OptionType[]>([]);
  const [entityFilters, setEntityFilters] = useState<OptionType[]>([]);
  const [severityFilters, setSeverityFilters] = useState<OptionType[]>([]);

  const {
    loading: getChartTimelineFiltersLoading,
    error: getChartTimelineFiltersError,
    data: getChartTimelineFiltersData,
  } = useGetChartTimelineFilters({
    skip: !tenant || !detection_id || !endSet || !startSet || !interval,
    variables: {
      end: endSet,
      start: startSet,
      interval,
      detection_id,
      top_n: maxRows,
      detection_tenant: tenant,
      ip_filter: (function () {
        const sourceFilters: string[] = [];
        const destFilters: string[] = [];
        const sourceDestFilters: { source: string[]; dest: string[] } = {
          source: [],
          dest: [],
        };
        entityFilters?.forEach((entity) => {
          const entityValue = entity.value as string;
          if (entity.type === 'source') {
            sourceFilters.push(entityValue);
          } else if (entity.type === 'dest') {
            destFilters.push(entityValue);
          } else if (entity.type === 'source_dest') {
            const parsedEntity = JSON.parse(entityValue);
            sourceDestFilters.source.push(parsedEntity.source);
            sourceDestFilters.dest.push(parsedEntity.dest);
          }
        });
        return sourceFilters?.length > 0 ||
          destFilters?.length > 0 ||
          sourceDestFilters?.source?.length > 0
          ? {
              ...(sourceFilters?.length > 0 && { source: sourceFilters }),
              ...(destFilters?.length > 0 && { dest: destFilters }),
              ...(sourceDestFilters?.source?.length > 0 &&
                sourceDestFilters?.dest?.length > 0 && {
                  source_dest: {
                    source: sourceDestFilters.source,
                    dest: sourceDestFilters.dest,
                  },
                }),
            }
          : undefined;
      })(),
      alert_name_filter:
        alertFilters?.length > 0
          ? alertFilters.map((alert) => JSON.parse(alert.value as string))
          : undefined,
      severity_filter:
        severityFilters?.length > 0
          ? severityFilters.map((severity) => severity.value)
          : undefined,
    },
  });

  const { alert_name_filter, ip_filter, severity_filter } =
    getChartTimelineFiltersData?.getChartTimelineFilters || {};

  const alertOptions = useMemo(
    () =>
      alert_name_filter?.length
        ? alert_name_filter.map((alert) => {
            return {
              value: JSON.stringify(alert),
              label: alert.alert_name,
            };
          })
        : [],
    [alert_name_filter],
  );

  const entityOptions = useMemo(
    () =>
      ip_filter
        ? [
            ip_filter?.source?.length
              ? {
                  label: headings.source,
                  options: ip_filter?.source.map((sourceEntity) => {
                    return {
                      value: sourceEntity,
                      label: sourceEntity,
                      type: 'source',
                    };
                  }),
                }
              : { label: headings.source, options: [] },
            ip_filter?.dest?.length
              ? {
                  label: headings.destination,
                  options: ip_filter?.dest.map((destEntity) => {
                    return {
                      value: destEntity,
                      label: destEntity,
                      type: 'dest',
                    };
                  }),
                }
              : { label: headings.destination, options: [] },
            ip_filter?.source_dest?.source
              ? {
                  label: headings.sourceDest,
                  options: ip_filter?.source_dest?.source?.map(
                    (sourceEntity, index) => {
                      return {
                        value: JSON.stringify({
                          source: sourceEntity,
                          dest: ip_filter?.source_dest?.dest[index],
                        }),
                        label: `${sourceEntity} - ${ip_filter?.source_dest?.dest[index]}`,
                        type: 'source_dest',
                      };
                    },
                  ),
                }
              : { label: headings.destination, options: [] },
          ]
        : [],
    [ip_filter],
  );

  const severityOptions = useMemo(
    () =>
      severity_filter?.length
        ? severity_filter.map((severity) =>
            severity === 'low'
              ? { value: severity, label: headings.low }
              : severity === 'medium'
                ? { value: severity, label: headings.medium }
                : severity === 'high'
                  ? { value: severity, label: headings.high }
                  : null,
          )
        : [],
    [severity_filter],
  );

  const variables = useMemo(() => {
    return {
      end: endSet,
      start: startSet,
      interval,
      detection_id,
      top_n: maxRows,
      detection_tenant: tenant,
      ip_filter,
      severity_filter:
        severity_filter && severity_filter?.length > 0
          ? severity_filter
          : undefined,
      alert_name_filter:
        alert_name_filter && alert_name_filter?.length > 0
          ? alert_name_filter
          : undefined,
    };
  }, [
    endSet,
    startSet,
    interval,
    detection_id,
    maxRows,
    tenant,
    ip_filter,
    severity_filter,
    alert_name_filter,
  ]);

  const { loading, error, data } = useGetDetectionTimelineSummary({
    skip:
      variables?.ip_filter === undefined &&
      variables?.severity_filter === undefined &&
      variables?.alert_name_filter === undefined,
    variables,
  });

  const {
    getCurrentDetectionTimelineSummary,
    getDetectionSourceDestinationTimelineSummary,
    getDetectionSourceTimelineSummary,
    getDetectionDestinationTimelineSummary,
  } = data || {};

  const currentDetectionDetections = useMemo(
    () =>
      getCurrentDetectionTimelineSummary?.summary?.flatMap(
        (summary) => summary.detections,
      ),
    [getCurrentDetectionTimelineSummary],
  );

  const detectionSourceDestinationDetections = useMemo(
    () =>
      getDetectionSourceDestinationTimelineSummary?.summary?.flatMap(
        (summary) => summary.detections,
      ),
    [getDetectionSourceDestinationTimelineSummary],
  );

  const detectionSourceDetections = useMemo(
    () =>
      getDetectionSourceTimelineSummary?.summary?.flatMap(
        (summary) => summary.detections,
      ),
    [getDetectionSourceTimelineSummary],
  );

  const detectionDestinationDetections = useMemo(
    () =>
      getDetectionDestinationTimelineSummary?.summary?.flatMap(
        (summary) => summary.detections,
      ),
    [getDetectionDestinationTimelineSummary],
  );

  const [chartDetections, setChartDetections] = useState<{
    currentDetectionDetections?: DetectionAlertSummary[];
    detectionSourceDetections?: DetectionAlertSummary[];
    detectionDestinationDetections?: DetectionAlertSummary[];
    detectionSourceDestinationDetections?: DetectionAlertSummary[];
  }>({
    currentDetectionDetections: [],
    detectionSourceDetections: [],
    detectionDestinationDetections: [],
    detectionSourceDestinationDetections: [],
  });

  useEffect(() => {
    setChartDetections({
      currentDetectionDetections,
      detectionSourceDetections,
      detectionDestinationDetections,
      detectionSourceDestinationDetections,
    });
  }, [
    currentDetectionDetections,
    detectionSourceDetections,
    detectionDestinationDetections,
    detectionSourceDestinationDetections,
  ]);

  return loading ||
    getChartTimelineFiltersLoading ||
    error ||
    getChartTimelineFiltersError ? (
    <GraphQLReqStatus
      error={error || getChartTimelineFiltersError}
      loading={!!loading || getChartTimelineFiltersLoading}
    />
  ) : (
    <Box key={ts}>
      <ApexDetectionsChart
        end={end.unix()}
        start={start.unix()}
        maxRows={maxRows}
        chartsData={data}
        interval={interval}
        setInterval={setInterval}
        alertOptions={alertOptions}
        alertFilters={alertFilters}
        setAlertFilters={setAlertFilters}
        entityOptions={entityOptions}
        entityFilters={entityFilters}
        setEntityFilters={setEntityFilters}
        severityOptions={severityOptions}
        severityFilters={severityFilters}
        setSeverityFilters={setSeverityFilters}
        setSelectedDetectionId={setSelectedDetectionId}
        earliest_start_timestamp={earliest_start_timestamp}
        latest_start_timestamp={latest_start_timestamp}
      />
      <RelatedDetectionsAlerts
        data={data}
        detection={detection}
        onPreviewOpen={onPreviewOpen}
        chartDetections={chartDetections}
        suricataRuleText={suricataRuleText}
        selectedDetection={selectedDetection}
        getDetectionsLoading={getDetectionsLoading}
        setSelectedDetectionId={setSelectedDetectionId}
        getAppConfigurationsData={getAppConfigurationsData}
      />
    </Box>
  );
};
