import { ComponentStyleConfig, cssVar } from '@chakra-ui/react';

import { textStyles } from '../../common';

const $width = cssVar('page-width');
const $py = cssVar('page-padding-y');

export const splitPageStyles: ComponentStyleConfig = {
  parts: [
    'background',
    'panel',
    'content',
    'body',
    'heading',
    'backButton',
    'cta',
  ],
  baseStyle: (props: any) => ({
    background: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      h: 'full',
      w: 'full',
      bg: 'layer.0',
      [$width.variable]: '280px',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDir: 'column',
      minW: $width.reference,
      maxW: $width.reference,
      position: 'relative',
      py: 8,
    },
    panel: {
      display: 'flex',
      flexDir: 'column',
      position: 'relative',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxH: '100vh',
      py: '60px',
      alignSelf: 'center',
      overflowY: 'auto',
    },
    body: {
      display: 'flex',
      flexDir: 'column',
      w: 'full',
    },
    heading: {
      ...textStyles['h5'],
      textAlign: 'center',
      fontWeight: 'normal',
      mb: 5,
    },
    backButton: {
      position: 'absolute',
      top: 7,
      left: 0,
    },
    cta: {
      mt: 5,
      w: 'full',
    },
  }),
};
