import { Text } from '@chakra-ui/react';

export const customLineBreaks = (text: string) => {
  // will split the text string into multiple tags
  const lines = text.split(/\n/);
  const withBreaks = lines.map((line, index) => (
    <Text key={index} data-testid="linebreak">
      {line}
    </Text>
  ));
  return withBreaks;
};
