import {
  Center,
  Circle,
  Grid,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import { ModelSummaryContribution } from '@gamma/investigator/queries';
import { chartColorsByArraySize } from '@gamma/investigator/utilities';
import { Column } from '@gamma/layout';

import { smartNumber } from '../LegacyCharts/helpers';

const { detection } = i18n.pages.detections;

export interface TopFeatureContributionsProps {
  features: ModelSummaryContribution[];
}

export const TopFeatureContributions = ({
  features,
}: TopFeatureContributionsProps) => {
  const { title } =
    i18n.pages.entityDetails.ml.analysis.cards.topFeatureContributions;

  const colors = chartColorsByArraySize(features?.length || 0);
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  if (!features) {
    return null;
  }

  return (
    <Column col={5} display="flex" flexDir="column" justifyContent="start">
      <Text textStyle="h6">{title}</Text>
      <Stack spacing={0} paddingTop="80px">
        {features.map(
          (
            {
              description,
              contribution,
              display_name,
              feature_name,
            }: ModelSummaryContribution,
            index,
          ) => (
            <Center
              h="50px"
              key={index}
              data-testid="top-feature-contributions"
            >
              <Tooltip
                color="text.primary"
                label={
                  <Text>
                    {display_name || feature_name}
                    <br />
                    <br />
                    {description || detection.noDescriptionForFeature}
                  </Text>
                }
              >
                <Grid
                  w="full"
                  p={2}
                  layerStyle="second"
                  borderRadius="base"
                  alignItems="center"
                  templateColumns={'max-content 1fr 80%'}
                  gap={2}
                >
                  <Circle size={2} bg={colors[index]} />
                  <Text textStyle="body-md">{`${(
                    smartNumber(contribution) * 100
                  ).toFixed(0)}%`}</Text>
                  <Text
                    textStyle="body-md"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {display_name || feature_name}
                  </Text>
                </Grid>
              </Tooltip>
            </Center>
          ),
        )}
      </Stack>
    </Column>
  );
};
