import { Divider } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { BullsEyeIcon } from '@gamma/icons';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext, LogScaleContext } from '@gamma/investigator/context';
import {
  useDateRangeContext,
  useDateRangeQueryString,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useGetHumioDashboards } from '@gamma/investigator/queries';
import {
  PopUpMenuLinksProps,
  SidebarItem,
  SidebarList,
} from '@gamma/navigation';
import moment from 'moment';
import { useCallback, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const {
  alertCatalog,
  securityAudit,
  logSearchWithParams,
  adminUsers: adminUsersRoute,
  overviewWithParams: overviewRoute,
  detectionsWithParams: detectionsRoute,
  systemSettingsIntegrations: integrationsRoute,
  systemSettingsIntegrationsConfig: integrationsConfigRoute,
  systemSettingsGeneral: systemSettingsGeneralRoute,
  systemSettingsAlertCatalog: systemSettingsAlertCatalogRoute,
  systemSettingsSecurityAudit: systemSettingsSecurityAuditRoute,
  systemSettingsSensorMonitoring: systemSettingsSensorMonitoringRoute,
} = ROUTES;

const { overview, search, detections, system, admin } = i18n.navigation;

interface ISidebarLinks {
  areItemsDisabled?: boolean;
}

export const SidebarLinks = ({ areItemsDisabled }: ISidebarLinks) => {
  const { isOrgTenant, userRole } = useContext(AuthContext);
  const {
    sessionIdTokenCorelight,
    setLogScaleDashboardsLoading,
    setLogScaleDashboardsError,
    setLogScaleDashboardsData,
  } = useContext(LogScaleContext);

  const [params] = useSearchParams();
  const tenant = params.get('tenant');

  const { start, end } = useDateRangeQueryString();

  const datesParams = `start=${start}&end=${end}`;
  const tenantParam = `tenant=${tenant}`;
  const humioDatesParams = `humioStart=${start * 1000}&humioEnd=${end * 1000}`;
  const queryString = `?${datesParams}${tenant ? `&${tenantParam}` : ''}`;

  const { loading, error, data } = useGetHumioDashboards({
    skip: !sessionIdTokenCorelight,
    variables: {
      humioSessionToken: sessionIdTokenCorelight || '',
    },
  });

  const alertCatalogLink = {
    to: systemSettingsAlertCatalogRoute(`${tenant ? `?${tenantParam}` : ''}`),
    label: system.alertCatalog,
    icon: <BullsEyeIcon boxSize={5} />,
    rootPath: alertCatalog,
  };

  const systemSettingsSensorMonitoringLink = {
    to: systemSettingsSensorMonitoringRoute,
    label: system.sensorMonitoring,
    icon: <MuiIcon>sensors</MuiIcon>,
  };

  const integrationsConfigLink = {
    to: integrationsConfigRoute,
    label: system.integrations,
    rootPath: integrationsRoute,
    icon: <MuiIcon>integration_instructions</MuiIcon>,
  };

  const adminSystemAndUserMenuLinks: PopUpMenuLinksProps[] = [
    {
      to: systemSettingsGeneralRoute,
      label: system.generalSettings,
      icon: <MuiIcon>settings</MuiIcon>,
    },
    alertCatalogLink,
    ...(!isOrgTenant ? [integrationsConfigLink] : []),
    {
      to: systemSettingsSecurityAuditRoute(
        `${tenant ? `?${tenantParam}` : ''}`,
      ),
      label: system.securityAudit,
      icon: <MuiIcon>fact_check</MuiIcon>,
      rootPath: securityAudit,
    },
    systemSettingsSensorMonitoringLink,
    {
      to: adminUsersRoute,
      label: admin.users,
      icon: <MuiIcon>manage_accounts</MuiIcon>,
      hasDivider: true,
    },
  ];

  const analystSystemAndUserMenuLinks: PopUpMenuLinksProps[] = [
    alertCatalogLink,
    ...(!isOrgTenant ? [integrationsConfigLink] : []),
    systemSettingsSensorMonitoringLink,
  ];

  const systemAndUserMenuLinks =
    userRole === 'admin'
      ? adminSystemAndUserMenuLinks
      : analystSystemAndUserMenuLinks;

  const { dateRange } = useDateRangeContext();

  const getStartAndEndParams = useCallback(() => {
    if (dateRange) {
      const end = moment().unix();
      const start = moment().subtract(dateRange[0], dateRange[1]).unix();

      return [start, end];
    }
    return [0, 0];
  }, [dateRange]);

  const [startParam, endParam] = getStartAndEndParams();

  useEffect(() => {
    setLogScaleDashboardsLoading && setLogScaleDashboardsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setLogScaleDashboardsError && setLogScaleDashboardsError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.getHumioDashboards) {
      setLogScaleDashboardsData && setLogScaleDashboardsData(data);
    }
  }, [data]);

  return (
    <>
      <SidebarItem
        exact={false}
        icon={<MuiIcon>local_police</MuiIcon>}
        isDisabled={areItemsDisabled}
        to={overviewRoute(
          start && end && tenant
            ? queryString
            : start && end
              ? `?start=${start}&end=${end}`
              : `?start=${startParam}&end=${endParam}${
                  tenant ? `&${tenantParam}` : ''
                }`,
        )}
        data-testid="sidebar-link-overview"
      >
        {overview}
      </SidebarItem>

      <SidebarItem
        exact={false}
        icon={<MuiIcon>list</MuiIcon>}
        isDisabled={areItemsDisabled}
        to={detectionsRoute(
          start && end && tenant
            ? queryString
            : start && end
              ? `?start=${start}&end=${end}`
              : `?start=${startParam}&end=${endParam}${
                  tenant ? `&${tenantParam}` : ''
                }`,
        )}
        data-testid="sidebar-link-detections"
      >
        {detections}
      </SidebarItem>

      <SidebarItem
        exact={false}
        isDisabled={areItemsDisabled}
        icon={<MuiIcon>frame_inspect</MuiIcon>}
        data-testid="sidebar-link-log-search"
        to={logSearchWithParams(`&${datesParams}&${humioDatesParams}`)}
      >
        {search}
      </SidebarItem>

      <Divider mb={2} />

      <SidebarList
        label={system.settings}
        isDisabled={areItemsDisabled}
        links={systemAndUserMenuLinks}
        icon={<MuiIcon>settings</MuiIcon>}
        data-testid="sidebar-link-system-and-user"
      />
    </>
  );
};
