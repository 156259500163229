import { Badge, Text } from '@chakra-ui/react';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  DetectionAssignmentInfo,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import moment from 'moment';
import { useContext } from 'react';

import {
  DetectionQueryVariables,
  SantizedDetection,
} from '../../../../Detections';
import { DetailsGrid, DetailsGridRow } from '../../../DetailsGrid';
import { DetectionAssignee, DetectionStatus } from './Components';

interface DetectionId {
  detection_id?: string;
  tenant?: string;
}

export interface IGetDetectionVariables {
  detection_ids: DetectionId[];
}

interface SummaryPanelProps {
  refetchMain?: boolean;
  detectionStatus?: string;
  detectionInfo?: Detection | SantizedDetection;
  assignmentInfo: DetectionAssignmentInfo;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
  total_alert_count?: number;
  earliest_start_timestamp?: number;
  latest_start_timestamp?: number;
}

export const SummaryPanel = ({
  refetchMain,
  detectionInfo,
  assignmentInfo,
  detectionStatus,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
  total_alert_count,
  earliest_start_timestamp,
  latest_start_timestamp,
}: SummaryPanelProps) => {
  const { isOrgTenant } = useContext(AuthContext);

  const { detection } = i18n.pages.detections;

  const {
    assignee,
    status,
    tenant,
    summary,
    firstTimeSeen,
    lastTimeSeen,
    numberOfAlerts,
  } = detection;

  return (
    <AccordionPanel
      w="full"
      title={summary}
      layerStyle="first"
      reduceMotion={true}
      data-testid="assignees-panel"
      storageKey="detection-assignees-panel"
    >
      <DetailsGrid>
        {detectionStatus && detectionInfo && (
          <DetailsGridRow data-testid="detection-alert-status" title={status}>
            <DetectionStatus
              refetchMain={refetchMain}
              detectionInfo={detectionInfo}
              detectionStatus={detectionStatus}
              getDetectionVariables={getDetectionVariables}
              queryDetectionsPaginatedVariables={
                queryDetectionsPaginatedVariables
              }
            />
          </DetailsGridRow>
        )}

        {detectionInfo && (
          <DetailsGridRow
            data-testid="detection-alert-assignee"
            title={assignee}
          >
            <DetectionAssignee
              refetchMain={refetchMain}
              detectionInfo={detectionInfo}
              assignmentInfo={assignmentInfo}
              getDetectionVariables={getDetectionVariables}
              queryDetectionsPaginatedVariables={
                queryDetectionsPaginatedVariables
              }
            />
          </DetailsGridRow>
        )}

        {total_alert_count && (
          <DetailsGridRow
            title={numberOfAlerts}
            data-testid="detection-alert-count"
          >
            <Text>{total_alert_count}</Text>
          </DetailsGridRow>
        )}
        {earliest_start_timestamp && (
          <DetailsGridRow
            title={firstTimeSeen}
            data-testid="detection-first-timestamp"
          >
            <Text>
              {moment.unix(earliest_start_timestamp).format('MMMM Do, h:mma')}
            </Text>
          </DetailsGridRow>
        )}
        {latest_start_timestamp && (
          <DetailsGridRow
            title={lastTimeSeen}
            data-testid="detection-last-timestamp"
          >
            <Text>
              {moment.unix(latest_start_timestamp).format('MMMM Do, h:mma')}
            </Text>
          </DetailsGridRow>
        )}
        {isOrgTenant && (
          <DetailsGridRow title={tenant} data-testid="detection-org-tenant">
            <Badge variant="outline">
              {detectionInfo?.tenant_info?.tenant_display_name}
            </Badge>
          </DetailsGridRow>
        )}
      </DetailsGrid>
    </AccordionPanel>
  );
};
