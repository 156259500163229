import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { AlertMetadataPaginated } from './types';

export interface IQueryAlertMetadataPaginated {
  queryAlertMetadataPaginated: AlertMetadataPaginated;
}

export const QUERY_ALERT_METADATA_PAGINATED = gql`
  query queryAlertMetadataPaginated(
    $query: String
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertMetadataPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      metadata {
        severity_info {
          custom_severity
          custom_severity_enabled
          default_severity
          last_updated_by_user_alias
          last_updated_by_username
          last_updated_timestamp
        }
        _score
        title
        severity
        active
        logsource {
          category
        }
        content_id
        tenant_alert
        custom_search_rule {
          query_string
          query_filter
          throttle_time
          time_interval
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const useQueryAlertMetadataPaginated = (
  options: QueryHookOptions<IQueryAlertMetadataPaginated, OperationVariables>,
) => {
  return useQuery<IQueryAlertMetadataPaginated>(
    QUERY_ALERT_METADATA_PAGINATED,
    options,
  );
};
