import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { IGetChartTimelineFilters } from './types';

export const GET_CHART_TIMELINE_FILTERS = gql`
  query getChartTimelineFilters(
    $alert_name_filter: [AlertNameFilterInput]
    $detection_id: String
    $detection_tenant: String
    $start: Float
    $end: Float
    $interval: Float
    $ip_filter: IPFilter
    $severity_filter: [DetectionAlertSummarySeverities]
  ) {
    getChartTimelineFilters(
      alert_name_filter: $alert_name_filter
      detection_id: $detection_id
      detection_tenant: $detection_tenant
      start: $start
      end: $end
      interval: $interval
      ip_filter: $ip_filter
      severity_filter: $severity_filter
    ) {
      alert_name_filter {
        alert_name
        detection_ids
      }
      ip_filter {
        dest
        source
        source_dest {
          dest
          source
        }
      }
      severity_filter
    }
  }
`;

export const useGetChartTimelineFilters = (
  options: QueryHookOptions<IGetChartTimelineFilters, OperationVariables>,
) => {
  return useQuery<IGetChartTimelineFilters>(
    GET_CHART_TIMELINE_FILTERS,
    options,
  );
};
