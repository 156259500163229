import { ComponentStyleConfig } from '@chakra-ui/react';

import { textStyles } from '../../common';

export const tagStyles: Partial<ComponentStyleConfig> = {
  baseStyle: (props) => ({
    container: {
      borderRadius: 'calc(var(--tag-min-height) / 2)',
      px: 2,
      'svg:not(button > *)': {
        lineHeight: 'revert-layer',
        width: '1lh',
        height: '1lh',
        padding: '2px', // improves visual consistency with figma
      },
    },
    closeButton: {
      height: 'var(--tag-min-height)',
      width: 'var(--tag-min-height)',
      my: -2,
      mr: -2,
      color: `${props.colorScheme}.50`,
      opacity: 1,
      _hover: {
        bgColor: 'whiteAlpha.300',
      },
      _disabled: {
        opacity: 0.5,
      },
      _pressed: {
        bgColor: 'whiteAlpha.400',
      },
    },
  }),
  sizes: {
    xs: {
      container: {
        '--tag-min-height': 'var(--chakra-sizes-4)',
        ...textStyles['body-xs'],
        py: 'px',
      },
      closeButton: {
        fontSize: 'sm',
      },
    },
    sm: {
      container: {
        ...textStyles['body-sm'],
        py: 0.5,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'md',
      },
    },
    md: {
      container: {
        ...textStyles['body-sm'],
        py: 1,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'md',
      },
    },
    lg: {
      container: {
        ...textStyles['body-md'],
        py: 1.5,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'lg',
      },
    },
  },
  variants: {
    solid: (props) => ({
      container: {
        bgColor: `${props.colorScheme}.600`,
        boxShadow: 'inset 0 0 0 1px var(--chakra-colors-whiteAlpha-300)',
      },
      closeButton: {
        color: `${props.colorScheme}.50`,
      },
    }),
    subtle: (props) => ({
      container: {
        boxShadow: 'inset 0 0 0 1px var(--chakra-colors-whiteAlpha-300)',
        color: `${props.colorScheme}.50`,
        bgColor: `${props.colorScheme}.800`,
      },
      closeButton: {
        color: `${props.colorScheme}.200`,
      },
    }),
    outline: (props) => ({
      container: {
        boxShadow: `inset 0 0 0 1px var(--chakra-colors-${props.colorScheme}-400)`,
      },
      closeButton: {
        color: `${props.colorScheme}.200`,
      },
    }),
  },
};
