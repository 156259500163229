import { useDisclosure, useTheme } from '@chakra-ui/react';
import { getColor, transparentize } from '@chakra-ui/theme-tools';
import { MuiIcon } from '@gamma/display';
import {
  ChakraStylesConfig,
  MultiValue,
  SingleValue,
} from 'chakra-react-select';
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { ComboboxWrapperProps } from '../../Components/ComboboxWrapper';
import { CustomSelectControl, OptionType } from '../useSelect';

export interface UseComboboxParams<Option extends OptionType> {
  'data-testid'?: string;
  id?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  category: string;
  placeholder?: string;
  readonly options: Option[];
  value: Option[];
  onChange?: (value: Option[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  size: 'xs' | 'sm' | 'md';
}

export const useCombobox = <
  Option extends OptionType = OptionType,
  Props extends UseComboboxParams<Option> = UseComboboxParams<Option>,
>(
  props: Props,
) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      setValue(props.value);
      props.onOpen?.();
    },
    onClose: () => {
      props.onClose?.();
    },
  });
  const [value, setValue] = useState<Option[]>(props.value);
  const theme = useTheme();

  const styles: ChakraStylesConfig<Option> = useMemo(() => {
    return {
      control: (provided) => ({
        ...provided,
        minWidth: 240,
        // margin: 8,
      }),
      menu: () => ({
        mt: 2,
        boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
        mx: -3,
        width: 'calc(100% + var(--chakra-space-6))',
        borderTop: 'solid 1px',
        borderColor: 'border.1',
      }),
      menuList: () => ({
        border: 'none',
        maxHeight: '280px',
        overflowY: 'scroll',
      }),
      option: (provided, state) => {
        return {
          ...provided,
          position: 'relative',
          paddingLeft: '36px',
          display: 'flex',
          alignItems: 'center',
          _after: {
            // MUI font styles
            content: props.isMulti ? '""' : '"radio_button_unchecked"',
            fontFamily: 'icon',
            fontVariationSettings: "'GRAD' -25, 'opsz' 20",
            textRendering: 'optimizeLegibility',
            MozOsxFontSmoothing: 'grayscale',
            WebkitFontSmoothing: 'antialiased',
            fontSmoothing: 'antialiased',
            display: 'inline-flex',
            lineHeight: '1',
            overflow: 'visible',
            whiteSpace: 'pre',
            cursor: 'inherit',
            textDecoration: 'none !important',
            fontWeight: 'bold',
            // positioning styles
            position: 'absolute',
            left: 4,
            // top: '7px',
            ...(props.isMulti
              ? {
                  fontSize: '12px',
                  width: '12px',
                  height: '12px',

                  border: 'solid 1px ',
                  borderColor: 'border.2',
                  borderRadius: 'base',
                }
              : {
                  fontSize: '16px',
                  width: '16px',
                  height: '16px',
                  color: 'border.2',
                }),
            ...(state.isSelected &&
              (props.isMulti
                ? {
                    content: '"check"',
                    borderColor: 'blue.500',
                    bg: 'blue.500',
                  }
                : {
                    content: '"radio_button_checked"',
                    color: 'blue.500',
                  })),
          },
          ...(state.isFocused && {
            bg: transparentize(getColor(theme, 'blue.500'), 0.1)(theme),
          }),
          ...(state.isSelected && {
            bg: transparentize(getColor(theme, 'gray.400'), 0.1)(theme),
            color: 'text.primary',
            _hover: {
              bg: transparentize(getColor(theme, 'blue.500'), 0.1)(theme),
            },
          }),
        };
      },
    };
  }, [props.isMulti]);

  const components = useMemo(
    () => ({
      DropdownIndicator: null,
      IndicatorSeparator: null,
      Control: CustomSelectControl({
        leftElement: <MuiIcon>search</MuiIcon>,
        size: 'md',
      }),
    }),
    [],
  );

  const { hasValue, hasMultiValue } = useMemo(() => {
    return {
      hasValue: props.value.length > 0,
      hasMultiValue: props.value.length > 1,
    };
  }, [props.value]);

  const handleClearAll = () => {
    setValue([]);
  };

  const handleApply = () => {
    props.onChange?.(value);
    onClose();
  };

  const handleCancel = () => {
    setValue(props.value);
    onClose();
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const wrapperProps: Omit<ComboboxWrapperProps<Option>, 'children'> = {
    isOpen,
    isDisabled: props.isDisabled || false,
    onOpen,
    hasValue,
    hasMultiValue,
    value: props.value,
    category: props.category,
    onChange: props.onChange,
    id: props.id,
    onApply: handleApply,
    onCancel: handleCancel,
    onClearAll: handleClearAll,
    'data-testid': props['data-testid'],
    size: props.size,
  };

  const selectProps = {
    isMulti: props.isMulti,
    onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    autoFocus: true,
    backspaceRemovesValue: false,
    components,
    escapeClearsValue: false,
    closeMenuOnSelect: false,
    controlShouldRenderValue: false,
    hideSelectedOptions: false,
    isClearable: false,
    blurInputOnSelect: false,
    menuIsOpen: true,
    onChange: (newValue: MultiValue<Option> | SingleValue<Option>) => {
      setValue(props.isMulti ? (newValue as Option[]) : [newValue as Option]);
    },
    options: props.options,
    placeholder: props.placeholder
      ? props.placeholder
      : `Search ${props.category}`,
    chakraStyles: styles,
    tabSelectsValue: false,
    value: value,
    'data-testid': `${props['data-testid']}-select`,
  };

  return { wrapperProps, selectProps };
};
