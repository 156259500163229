import { Flex, HStack } from '@chakra-ui/react';
import { AuthContext } from '@gamma/investigator/context';
import { Detection } from '@gamma/investigator/queries';
import { useContext, useState } from 'react';

import {
  DetectionQueryVariables,
  SantizedDetection,
} from '../../../../../../Detections';
import { SendToPill } from '../../../../../DetectionPills';
import { DetectionStatusPill } from '../../../../../DetectionStatusPill';
import { DetectionStatusActionsMenu } from '../../../../../DetectionStatusPopover';
import { IGetDetectionVariables } from '../../SummaryPanel';

interface DetectionStatusProps {
  refetchMain?: boolean;
  detectionStatus?: string;
  detectionInfo?: Detection | SantizedDetection;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
}

export const DetectionStatus = ({
  refetchMain,
  detectionInfo,
  detectionStatus,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
}: DetectionStatusProps) => {
  const { userRole } = useContext(AuthContext);

  const [isStatusHovered, setIsStatusHovered] = useState<boolean>(false);

  return userRole === 'viewer' ? (
    <HStack mr={2} flexGrow={1} maxW="calc(100% - 32px)">
      {detectionStatus && (
        <DetectionStatusPill
          text={detectionStatus}
          isStatusOpen={detectionStatus === 'open'}
        />
      )}
      {detectionInfo?.escalation_data?.escalation_status && (
        <SendToPill escalation_data={detectionInfo?.escalation_data} />
      )}
    </HStack>
  ) : (
    <Flex
      w="100%"
      p="2px 4px"
      minH="28px"
      borderRadius="2px"
      alignItems="center"
      position="relative"
      wordBreak="break-all"
      transition="all .1s ease-out"
      data-testid="summary-panel-inline-edit-status"
      onMouseOver={() => setIsStatusHovered(true)}
      onMouseLeave={() => setIsStatusHovered(false)}
      _hover={{
        backgroundColor: isStatusHovered ? 'whiteAlpha.50' : 'transparent',
      }}
    >
      <HStack mr={2} flexGrow={1} maxW="calc(100% - 32px)">
        {detectionStatus && (
          <DetectionStatusPill
            text={detectionStatus}
            isStatusOpen={detectionStatus === 'open'}
          />
        )}
        {detectionInfo?.escalation_data?.escalation_status && (
          <SendToPill escalation_data={detectionInfo?.escalation_data} />
        )}
      </HStack>
      <DetectionStatusActionsMenu
        refetchMain={refetchMain}
        detectionData={detectionInfo}
        isStatusHovered={isStatusHovered}
        getDetectionVariables={getDetectionVariables}
        queryDetectionsPaginatedVariables={queryDetectionsPaginatedVariables}
      />
    </Flex>
  );
};
