import {
  HumioLoaderOverlay,
  HumioUnavailable,
} from '@gamma/investigator/components';
import { humioDashboard } from '@gamma/investigator/constants';
import { LogScaleContext } from '@gamma/investigator/context';
import { useReloadHumioUI } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column, Panel } from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';

export const SensorsMonitoringDashboard = () => {
  const { dashboard } = i18n.pages.sensorMonitoring;

  const {
    iframeKey,
    logScaleDashboardsLoading,
    logScaleDashboardsError,
    logScaleDashboardsData,
  } = useContext(LogScaleContext);

  const [dashboardId, setDashboardId] = useState<string>('');

  useEffect(() => {
    logScaleDashboardsData?.getHumioDashboards?.searchDomain?.dashboards?.forEach(
      (dashboard: any) => {
        if (dashboard.name === humioDashboard.sensor) {
          setDashboardId(dashboard.id);
          return;
        }
      },
    );
  }, [logScaleDashboardsData]);

  const { iframeSrc } = useReloadHumioUI({
    iframeType: 'sensorsMonitoring',
    dashboardId,
  });

  if (logScaleDashboardsLoading) {
    return (
      <ErrorBoundary page="SensorsMonitoring" styleClass="main">
        <Panel>
          <GraphQLReqStatus
            isBackground={true}
            loading={logScaleDashboardsLoading}
          />
        </Panel>
      </ErrorBoundary>
    );
  } else if (logScaleDashboardsError || !dashboardId) {
    return (
      <HumioUnavailable
        unavailable={dashboard.unavailable}
        techSupport={dashboard.techSupport}
      />
    );
  }

  return (
    <ErrorBoundary page="SensorsMonitoring" styleClass="main">
      <Board
        flexGrow={1}
        flexWrap="inherit"
        flexDirection="column"
        data-testid="sensor-stats-humio-embed"
      >
        <Column
          flexGrow={1}
          display="flex"
          flexBasis="auto"
          overflow="hidden"
          flexDirection="column"
        >
          {isSafari && <HumioLoaderOverlay reloadKey={iframeKey} />}
          {iframeSrc && dashboardId && (
            <iframe
              data-testid="sensor-management-dashboard"
              title="ui"
              id="humio-ui"
              key={iframeKey}
              width="100%"
              height="100%"
              style={{
                flexGrow: 1,
                display: 'flex',
                paddingBottom: 0,
                marginTop: '-96px',
              }}
              src={iframeSrc}
            />
          )}
        </Column>
      </Board>
    </ErrorBoundary>
  );
};
