import {
  HumioLoaderOverlay,
  HumioUnavailable,
} from '@gamma/investigator/components';
import { LogScaleContext } from '@gamma/investigator/context';
import { useReloadHumioUI } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column, Panel } from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

export const HumioSearch = () => {
  const { humioSearch } = i18n.pages.humio;

  const {
    iframeKey,
    logScaleDashboardsLoading,
    logScaleDashboardsError,
    logScaleDashboardsData,
  } = useContext(LogScaleContext);

  const { search } = useLocation();

  const params = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const [referrerType, setReferrerType] = useState(params.get('referrer_type'));
  const [referrerAlertName, setReferrerAlertName] = useState(
    params.get('referrer_alert_name'),
  );
  const [referrerEntityName, setReferrerEntityName] = useState(
    params.get('referrer_entity_name'),
  );
  const [referrerAlertURL, setReferrerAlertURL] = useState(
    params.get('referrer_alert_url'),
  );
  const [referrerEntityURL, setReferrerEntityURL] = useState(
    params.get('referrer_entity_url'),
  );

  useEffect(() => {
    if (!params.get('query')) {
      setReferrerType(null);
      setReferrerAlertName(null);
      setReferrerEntityName(null);
      setReferrerAlertURL(null);
      setReferrerEntityURL(null);
    }
  }, [params.get('query')]);

  const end = moment(Number(params.get('humioEnd')));
  const roundUp =
    end.second() || end.millisecond()
      ? end.add(1, 'minute').startOf('minute')
      : end;

  params.set('humioEnd', String(roundUp.valueOf()));

  params.delete('start');
  params.delete('end');
  params.delete('referrer_type');
  params.delete('referrer_alert_url');
  params.delete('referrer_alert_name');
  params.delete('referrer_entity_url');
  params.delete('referrer_entity_name');

  const stringifyParams = useMemo(() => {
    let stringifyParams = String(params);
    const startRegex = /humioStart/i;
    stringifyParams = stringifyParams.replace(startRegex, 'start');
    const endRegex = /humioEnd/i;
    stringifyParams = stringifyParams.replace(endRegex, 'end');
    return stringifyParams;
  }, [params]);

  const { iframeRef, iframeSrc } = useReloadHumioUI({
    iframeType: 'search',
    params: stringifyParams,
  });

  if (logScaleDashboardsLoading) {
    return (
      <ErrorBoundary page="Search" styleClass="main">
        <Panel>
          <GraphQLReqStatus
            isBackground={true}
            loading={logScaleDashboardsLoading}
          />
        </Panel>
      </ErrorBoundary>
    );
  } else if (
    logScaleDashboardsError ||
    !logScaleDashboardsData?.getHumioDashboards?.searchDomain?.dashboards ||
    logScaleDashboardsData?.getHumioDashboards?.searchDomain?.dashboards
      ?.length === 0
  ) {
    return (
      <HumioUnavailable
        unavailable={humioSearch.unavailable}
        techSupport={humioSearch.techSupport}
      />
    );
  }

  return (
    <ErrorBoundary page="Search" styleClass="main">
      <Board
        flexGrow={1}
        flexWrap="inherit"
        flexDirection="column"
        data-testid="humio-search"
        height="inherit"
      >
        <Column
          mt={1}
          flexGrow={1}
          display="flex"
          flexBasis="auto"
          overflow="hidden"
          flexDirection="column"
        >
          {isSafari && <HumioLoaderOverlay reloadKey={iframeKey} />}
          {iframeSrc && (
            <iframe
              title="ui"
              width="100%"
              id="humio-ui"
              data-testid="humio-ui"
              style={{
                flexGrow: 1,
                display: 'flex',
                paddingBottom: 0,
                marginTop: '-52px',
              }}
              key={iframeKey}
              ref={iframeRef}
              src={iframeSrc}
            />
          )}
        </Column>
      </Board>
    </ErrorBoundary>
  );
};
