import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ReactNode } from 'react';

import { NavItem } from '../NavItem';

type NavMenuTooltipProps = Omit<TooltipProps, 'children'>;

export interface NavMenuProps {
  children?: ReactNode;
  /** The content to use for the menu trigger */
  label: ReactNode;
  /** this is for hiding the chevron down icon  pass true to hide it*/
  isCaretHidden?: boolean;
  /** props to enable tooltip */
  tooltipProps?: NavMenuTooltipProps | null;
}

interface NavMenuButtonProps {
  tooltipProps?: NavMenuTooltipProps | null;
  label: ReactNode;
  isCaretHidden?: boolean;
}

const NavMenuButton = ({
  tooltipProps,
  label,
  isCaretHidden,
}: NavMenuButtonProps) => {
  const buttonProps: ButtonProps = {
    variant: 'ghost',
    colorScheme: 'gray',
    _light: {
      textShadow: '1px 1px white',
      _hover: {
        bg: 'layer.1.active',
        color: 'gray.400',
      },
      _active: {
        bg: 'layer.1.active',
        color: 'gray.400',
      },
    },
  };
  if (tooltipProps) {
    return (
      <Tooltip {...tooltipProps}>
        <MenuButton
          data-testid="gamma-nav-menu-button"
          as={Button}
          {...buttonProps}
        >
          {label} {!isCaretHidden && <MuiIcon>keyboard_arrow_down</MuiIcon>}
        </MenuButton>
      </Tooltip>
    );
  }

  return (
    <MenuButton
      as={Button}
      data-testid="gamma-nav-menu-button"
      {...buttonProps}
    >
      {label} {!isCaretHidden && <MuiIcon>keyboard_arrow_down</MuiIcon>}
    </MenuButton>
  );
};

export const NavMenu = ({
  children,
  label,
  isCaretHidden = false,
  tooltipProps = null,
  ...rest
}: NavMenuProps) => (
  <NavItem data-testid="gamma-nav-menu">
    <Menu {...rest}>
      <NavMenuButton
        label={label}
        isCaretHidden={isCaretHidden}
        tooltipProps={tooltipProps}
      />
      <Portal>
        <MenuList textStyle="body-md" zIndex="popover">
          {children}
        </MenuList>
      </Portal>
    </Menu>
  </NavItem>
);
