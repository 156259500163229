import {
  Flex,
  Link,
  Spinner,
  Tag,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  DetectionAssignmentInfo,
  GET_DETECTIONS,
  QUERY_DETECTIONS_PAGINATED,
  useAssignDetections,
  useListUsers,
} from '@gamma/investigator/queries';
import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DetectionQueryVariables,
  SantizedDetection,
} from '../../../../../../Detections';
import { filterAssignableUsers } from '../../../../../../Utils';
import { DetectionAssigneePopover } from '../../../../../DetectionAssigneePopover';
import { IGetDetectionVariables } from '../../SummaryPanel';

interface DetectionAssigneeProps {
  refetchMain?: boolean;
  detectionInfo?: Detection | SantizedDetection;
  assignmentInfo?: DetectionAssignmentInfo;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
}

export const DetectionAssignee = ({
  refetchMain,
  detectionInfo,
  assignmentInfo,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
}: DetectionAssigneeProps) => {
  const showToastMessage = useToast();

  const { user, userRole } = useContext(AuthContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const { controls, detection, toasts } = i18n.pages.detections;

  const { assignToMe } = controls;

  const { unassigned, externalTenant } = detection;

  const [isAssigneeHovered, setIsAssigneeHovered] = useState<boolean>(false);

  const {
    loading: listUsersLoading,
    error: listUsersError,
    data: { listUsers = [] } = {},
  } = useListUsers({
    skip: !user?.attributes['custom:tenant_id'],
    variables: {
      tenants: [`${user?.attributes['custom:tenant_id']}`],
      groups: ['admin', 'analyst'],
    },
  });

  const assignableUsers = filterAssignableUsers({ user, users: listUsers });

  const isActiveUserAssignable =
    assignableUsers.some(
      (assignableUser) => assignableUser?.value === user?.username,
    ) && user?.username !== assignmentInfo?.assigned_to_username;

  const refetchQueries = [
    {
      query: GET_DETECTIONS,
      variables: getDetectionVariables,
    },
  ];

  const [assignDetections, { loading: assignLoading, error: assignError }] =
    useAssignDetections({
      awaitRefetchQueries: true,
      refetchQueries: !queryDetectionsPaginatedVariables
        ? refetchQueries
        : [
            ...refetchQueries,
            {
              query: QUERY_DETECTIONS_PAGINATED,
              variables: queryDetectionsPaginatedVariables,
            },
          ],
      onCompleted: (data) => {
        showToastMessage({
          status: 'success',
          title: toasts.success,
          description: toasts.assigneeSet,
          isClosable: true,
          position: 'bottom-right',
        });
        if (refetchMain) {
          setSearchParams((params) => {
            params.set('refetch_main', 'true');
            return params;
          });
        }
      },
      onError: (error) => {
        showToastMessage({
          status: 'error',
          title: toasts.error,
          description: toasts.assigneeNotSet,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const handleAssignDetections = () => {
    assignDetections({
      variables: {
        items: [
          {
            assignment_info: {
              assigned_to_username: user?.username || null,
            },
            update_info: {
              last_updated_timestamp:
                detectionInfo?.update_info?.last_updated_timestamp,
            },
            detection_id: detectionInfo?.detection_id,
            tenant: user?.attributes['custom:tenant_id'],
          },
        ],
      },
    });
  };

  return userRole === 'viewer' ? (
    <Tag
      mr={2}
      size="md"
      variant="solid"
      data-testid="detection-alert-assignee"
      colorScheme={assignmentInfo?.assigned_to_user_alias ? 'purple' : 'gray'}
    >
      {!assignmentInfo?.assigned_to_user_alias
        ? unassigned
        : assignmentInfo?.assigned_to_user_alias}
    </Tag>
  ) : (
    <Flex
      w="100%"
      zIndex={0}
      p="2px 4px"
      minH="28px"
      borderRadius="2px"
      alignItems="center"
      position="relative"
      wordBreak="break-all"
      transition="all .1s ease-out"
      data-testid="summary-panel-inline-edit-assignee"
      onMouseOver={() => setIsAssigneeHovered(true)}
      onMouseLeave={() => setIsAssigneeHovered(false)}
      _hover={{
        backgroundColor: isAssigneeHovered ? 'whiteAlpha.50' : 'transparent',
      }}
    >
      <Text
        mr={2}
        as="div"
        display="flex"
        flexGrow={1}
        alignItems="center"
        textStyle="body-md"
        color="text.secondary"
        maxW="calc(100% - 32px)"
        data-testid="entity-panel-source-value"
      >
        <Tag
          mr={2}
          size="md"
          variant="solid"
          data-testid="detection-alert-assignee"
          colorScheme={
            assignmentInfo?.assigned_to_user_alias ? 'purple' : 'gray'
          }
        >
          <Tooltip
            label={
              assignmentInfo?.assigned_to_user_alias === 'External User'
                ? externalTenant
                : ''
            }
          >
            {!assignmentInfo?.assigned_to_user_alias
              ? unassigned
              : assignmentInfo?.assigned_to_user_alias}
          </Tooltip>
        </Tag>
        {assignLoading || listUsersLoading ? (
          <Spinner size={'xs'} />
        ) : (
          isActiveUserAssignable && (
            <Link
              zIndex={1}
              minW="83px"
              color="text.link"
              position="relative"
              onClick={handleAssignDetections}
            >
              {assignToMe}
            </Link>
          )
        )}
      </Text>
      {detectionInfo && (
        <DetectionAssigneePopover
          refetchMain={refetchMain}
          detectionInfo={detectionInfo}
          isAssigneeHovered={isAssigneeHovered}
          getDetectionVariables={getDetectionVariables}
          queryDetectionsPaginatedVariables={queryDetectionsPaginatedVariables}
        />
      )}
    </Flex>
  );
};
