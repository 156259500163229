import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  chakra,
  useStyles,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { FileMetadata } from '../FileUploadBox';

export type FileListItemProps = {
  file: FileMetadata;
  ['data-testid']?: string;
  size?: 'sm' | 'md' | 'lg';
  index?: number;
  handleDelete: (file: File) => void;
};

export const FileListItem = ({
  file,
  'data-testid': dataTestId = 'file-upload-box',
  size,
  index = 0,
  handleDelete,
}: FileListItemProps) => {
  const styles = useStyles();

  const state =
    (file.validation?.length ?? 0) > 0
      ? 'error'
      : (file.warnings?.length ?? 0) > 0
        ? 'warning'
        : 'success';

  const { color, borderColor, icon } = {
    error: {
      color: 'state.error',
      borderColor: 'state.error',
      icon: 'error',
    },
    warning: {
      color: 'state.warning',
      borderColor: 'state.warning',
      icon: 'warning',
    },
    success: {
      color: 'state.success',
      borderColor: 'border.1',
      icon: 'check_circle',
    },
  }[state];

  const renderMessages = (status: 'error' | 'warning', messages: string[]) => (
    <>
      <Divider
        marginInlineStart={-2}
        marginInlineEnd={-2}
        w="calc(100% + 16px)"
      />
      {messages.map((errorMessage, i) => (
        <Text
          key={i}
          color={status === 'error' ? 'state.error' : 'state.warning'}
          data-testid={`file-${status}-text`}
          ml={7}
          role="alert"
        >
          {errorMessage}
        </Text>
      ))}
    </>
  );

  return (
    <chakra.li
      data-testid={`${dataTestId}-files-list-item`}
      __css={styles.listItem}
      borderColor={borderColor}
    >
      <Stack spacing={1}>
        <Flex justifyContent="space-between">
          <HStack>
            <MuiIcon data-testid="uploaded-file-icon" color={color}>
              {icon}
            </MuiIcon>
            <Text
              data-testid="uploaded-file-name"
              color="text.secondary"
              title={file.name}
            >
              {file.shortName}
            </Text>
          </HStack>
          <IconButton
            onClick={() => handleDelete(file.file)}
            size="box"
            aria-label={`Delete File - ${file.name}`}
            aria-describedby={
              state !== 'success'
                ? `${file.name}-${index}-error-message`
                : undefined
            }
            icon={<MuiIcon color="text.secondary">close</MuiIcon>}
            data-testid="uploaded-file-delete-button"
          />
        </Flex>
        {state !== 'success' && (
          <Box id={`${file.name}-${index}-error-message`}>
            {file.validation &&
              file.validation.length > 0 &&
              renderMessages('error', file.validation)}
            {file.warnings &&
              file.warnings.length > 0 &&
              renderMessages('warning', file.warnings)}
          </Box>
        )}
      </Stack>
    </chakra.li>
  );
};
