import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const DELETE_EXPORTER = gql`
  mutation deleteExporter($exporter_id: ExporterIdInput!) {
    deleteExporter(exporter_id: $exporter_id)
  }
`;

export const useDeleteExporter = (
  options: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(DELETE_EXPORTER, options);
};
