import { ApolloError } from '@apollo/client';
import { Box, Center } from '@chakra-ui/react';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  Alert,
  CrowdStrikeConfiguration,
  CustomSearchRuleAlert,
  EDRConfiguration,
  IGetAlertMetadata,
  IGetDetections,
  IQueryAlertsPaginated,
  MlAlert,
  NoticeAlert,
  SuricataAlert,
  useAnalystGetEDRConfiguration,
  useGetAlertMetadata,
  useGetDetections,
  useQueryDetectionsPaginated,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet, useHref, useLocation, useParams } from 'react-router-dom';
import { SortingRule } from 'react-table';

import { DetectionDetailsHeader, DetectionQueryVariables } from '../..';

interface IDetectionId {
  detection_id: string;
  tenant: string;
}

interface IGetDetectionVariables {
  detection_ids: IDetectionId[];
}

export type DetectionAlertsFilters = { id: string; value: string }[];

export interface IDetectionContext {
  detectionData: IGetDetections;
  detectionError: ApolloError;
  detectionLoading: boolean;
  getDetectionVariables: IGetDetectionVariables;
  handleFetchRelated: () => void;
  alertsSize: number;
  alertsOffset: number;
  alertsSortBy: SortingRule<Alert>[];
  alertsPageSize: number;
  alertsPageCount: number;
  alertsPageIndex: number;
  onFetchAlertsData: () => void;
  handleColFilters: () => void;
  handleFetchRelatedAlerts: () => void;
  alertsLoading: boolean;
  alertsError: ApolloError;
  alertsData: IQueryAlertsPaginated;
  metadataLoading: boolean;
  metadataData: IGetAlertMetadata;
  metadataError: ApolloError;
  edrConfig: CrowdStrikeConfiguration;
  edrConfigLoading: boolean;
  edrConfigError: ApolloError;
  selectedItem: CustomSearchRuleAlert | MlAlert | NoticeAlert | SuricataAlert;
  setSelectedItem: () => void;
  fetchRelatedDetectionsVariables: DetectionQueryVariables;
  setFetchRelatedLoading: Dispatch<SetStateAction<boolean>>;
  fetchOriginalRelatedCount: number;
  setFetchRelatedCount: Dispatch<SetStateAction<number | undefined>>;
}

export const Detection = ({
  getAppConfigurationsData,
}: {
  getAppConfigurationsData: string | null;
}) => {
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);

  const refetch_main = params.get('refetch_main');

  const { detection_id } = useParams();

  const { start, end } = useDateRangeQueryString();

  const { user } = useContext(AuthContext);

  const { backToTriage } = i18n.pages.detections;

  const { detections } = ROUTES;

  const getDetectionVariables = {
    detection_ids: [
      {
        detection_id,
        tenant: user?.attributes['custom:tenant_id'],
      },
    ],
  };

  const {
    loading: detectionLoading,
    data: detectionData,
    error: detectionError,
  } = useGetDetections({
    skip: !detection_id,
    variables: getDetectionVariables,
  });

  const detectionsData = detectionData?.getDetections?.detections;

  const detection = detectionsData?.[0];

  /* Alert Metadata */

  const {
    loading: metadataLoading,
    data: metadataData,
    error: metadataError,
  } = useGetAlertMetadata({
    skip:
      !detection?.tenant_info?.tenant_id ||
      !detectionsData?.[0]?.alert_info?.content_id,
    variables: {
      items: [
        {
          content_id: detectionsData?.[0]?.alert_info?.content_id,
          tenant: detection?.tenant_info?.tenant_id,
        },
      ],
    },
  });

  /* Related / Detections / Entities */

  const [fetchRelatedCount, setFetchRelatedCount] = useState<number>(0);
  const [fetchOriginalRelatedCount, setFetchOriginalRelatedCount] =
    useState<number>(0);
  const [fetchRelatedLoading, setFetchRelatedLoading] =
    useState<boolean>(false);

  const relatedQuery = useMemo(
    () =>
      `{"query":{"bool":{"must":[{"range":{"total_alert_count":{"gt":0}}},{"range":{"latest_start_timestamp":{"gte":${start}}}}${
        detection?.tenant_info?.tenant_id ? ',' : ''
      }${
        detection?.tenant_info?.tenant_id
          ? `{"terms":{"tenant":["${detection?.tenant_info?.tenant_id}"]}}`
          : ''
      },{"range":{"earliest_start_timestamp":{"lte":${end}}}},{"bool":{"should":[{"term":{"detection_keys.tenant_detection":"${
        detectionsData?.[0]?.detection_keys?.tenant_detection
      }"}},{"term":{"detection_keys.tenant_entity":"${
        detectionsData?.[0]?.detection_keys?.tenant_entity
      }"}}]}}],"must_not":{"term":{"detection_id":"${detection_id}"}}}}}`,
    [
      end,
      start,
      detection_id,
      detectionsData,
      detection?.tenant_info?.tenant_id,
    ],
  );

  const [fetchData, { called, loading, data, error, refetch }] =
    useQueryDetectionsPaginated({
      variables: {
        query: relatedQuery,
      },
      onCompleted: (data) => {
        setFetchRelatedCount(data?.queryDetectionsPaginated?.total_items);
        setFetchOriginalRelatedCount(
          data?.queryDetectionsPaginated?.total_items,
        );
      },
    });

  useEffect(() => {
    if (
      end &&
      start &&
      detection?.tenant_info?.tenant_id &&
      detectionsData?.[0]?.detection_keys
    ) {
      fetchData();
    }
  }, [
    end,
    start,
    fetchData,
    detectionsData,
    detection?.tenant_info?.tenant_id,
  ]);

  useEffect(() => {
    setFetchRelatedLoading(loading);
  }, [loading]);

  const triagePageParams = useMemo(() => {
    detection_id && params.set('detection_id', detection_id);
    return params.toString();
  }, [detection_id, refetch_main]);

  const triagePageHref = useHref({
    pathname: detections,
    search: triagePageParams,
  });

  if (!detection_id) {
    return null;
  }

  /* EDR Configuration */

  const {
    loading: edrConfigLoading,
    error: edrConfigError,
    data: edrConfigData,
  } = useAnalystGetEDRConfiguration({
    skip: !detection?.tenant_info?.tenant_id,
    variables: {
      provider_name: 'crowdstrike',
      tenant: detection?.tenant_info?.tenant_id,
    },
  });

  const edrConfig = edrConfigData?.getEDRConfiguration as EDRConfiguration;

  const isCustomSeverityScore = detectionsData?.[0]?.rank?.is_custom_severity;

  return (
    <Box data-testid="detection-wrapper">
      {detectionLoading || detectionError ? (
        <Center w="100%">
          <GraphQLReqStatus loading={detectionLoading} error={detectionError} />
        </Center>
      ) : (
        <DetectionDetailsHeader
          detectionData={detectionsData?.[0]}
          getAppConfigurationsData={getAppConfigurationsData}
          getDetectionVariables={getDetectionVariables}
          prevPage={triagePageHref}
          breadcrumbs={[
            {
              link: triagePageHref,
              label: backToTriage,
            },
          ]}
          isCustomSeverityScore={isCustomSeverityScore}
          metadataData={metadataData}
          metadataLoading={metadataLoading}
        />
      )}
      <Box>
        <Outlet
          context={{
            detectionData,
            detectionError,
            detectionLoading,
            getDetectionVariables,
            metadataLoading,
            metadataData,
            metadataError,
            edrConfig,
            edrConfigError,
            edrConfigLoading,
            fetchOriginalRelatedCount,
            setFetchRelatedCount,
            setFetchRelatedLoading,
          }}
        />
      </Box>
    </Box>
  );
};
