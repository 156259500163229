import { DateSelectOption } from '@gamma/form-fields';
import { ExtendedType } from '@gamma/progress';
import { Auth } from 'aws-amplify';

import { ROUTES } from './routes';

const getCurrentSession = async () => {
  try {
    const res = await Auth.currentSession();
    return res.getIdToken().getJwtToken();
  } catch (err) {
    console.error(err);
  }
};

export const CONSTANTS = {
  FEDERATION_TYPE: {
    ORG: 'ORG',
    MEMBER: 'MEMBER',
    NONE: 'NONE',
  },
  TENANT_TYPE: {
    POV: 'POV',
  },
  ROLE: {
    admin: 'admin',
    analyst: 'analyst',
    viewer: 'viewer',
  },
  APPSYNC: {
    url: `${process.env.NX_PUBLIC_INVESTIGATOR_BASE_HREF ?? '/'}api/graphql`,
    region: 'us-west-2',
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: async () => getCurrentSession(),
    },
  },
  MFA_OPTIONS: {
    SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  },
  MOCK_API: {
    URL: 'http://localhost:4000/graphql',
  },
  SSO: {
    domain:
      // @ts-ignore
      window.__env__?.sso.domain ??
      'develop-humio-server.auth.us-west-2.amazoncognito.com',
    // @ts-ignore
    client_id: window.__env__?.sso.client_id ?? '7akdknksa6nhbq4t2a18311m89',
    redirect_uri:
      // @ts-ignore
      window.__env__?.sso.redirect_uri ?? 'https://develop.ptrx.net/login',
    remote_manager_url: `urn:amazon:cognito:sp:${
      // @ts-ignore
      window.__env__?.cognito.cognito_user_pool ??
      process.env.REACT_APP_COGNITO_USER_POOL
    }`,
    remote_manager_sso_url: `https://${
      // @ts-ignore
      window.__env__?.sso.domain ??
      'develop-humio-server.auth.us-west-2.amazoncognito.com'
    }/saml2/idpresponse`,
  },
};

export const humioDashboard = {
  sensor: 'Data - Corelight Health Report - Sensor Status',
  session_token_url: '/search/api/v1/authenticate/token',
  pov_session_token_url: '/search-internal/api/v1/authenticate/token',
};

export enum graphqlTypeNames {
  user = 'User',
}

export enum USER_STATUS {
  active = 'active',
  inactive = 'inactive',
  invited = 'invited',
}

export enum MODAL_STEPS {
  login = 'login',
  mfa = 'mfa',
  details = 'details',
  nomfa = 'nomfa',
  warning = 'warning',
}

export enum MFA {
  NOMFA = 'NOMFA',
}

export const REGEX = {
  /**
   * Email pattern validation regex
   * [A-Z0-9._%+-]+ :- must start with string in the bracket [ ], must contain one or more (+)
   * @ :- must contain a "@" symbol
   * [A-Z0-9.-]+ :- follow by @ in the bracket [ ], must contain one or more (+)
   * . :- must contain a "." symbol
   * [A-Z]{2,}$/i :- follow by a dot "." and string in the bracket [ ], with minimum length of 2
   * updated to have max 10 character length
   */
  EMAIL: /^\w+([\.\-\+]?\w?)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/i, // eslint-disable-line
  AWS_DECORATORS: /@aws_[a-z_]+/gim,
  NUMBERS: /\D/g,
  ASTERISK: /\*/g,
  COLON: /:/g,
  HYPHEN: /-/g,
  FORWARD_SLASH: /\//g,
  DOUBLE_QUOTES: /"/g,
  ESCAPED_DOUBLE_QUOTES: /\\"/g,
  INCLUDES_SPACE: /^\S*$/,
  REPLACE_BRACKETS_AND_TEXT: /\[.*\]$/,
  ESCAPED_PERIODS: /\\./g,
  USER_ROLE: /^[A-Za-z\s_-]+$/,
};

export const URLS = {
  CORELIGHT_BLOG: 'https://corelight.com/blog',
  CORELIGHT_SUPPORT: 'https://corelight.com/support',
  CORELIGHT_PRIVACY_POLICY: 'https://corelight.com/privacy-policy/',
  INVESTIGATOR_DOCS: '/docs/index.html',
  CROWDSTRIKE_DOCS: '/docs/settings/crowdstrike-edr.html',
  SERVICENOW_DOCS: '/docs/settings/service-now-settings.html',
  INVESTIGATOR_DOCS_ALERT_EXPORT: '/docs/settings/alert-export.html',
  SPLUNK_DOCS_ENABLE_HTTP_EVENT_COLLECTOR:
    'https://docs.splunk.com/Documentation/Splunk/8.1.2/Data/UsetheHTTPEventCollector#Enable_HTTP_Event_Collector',
  ELASTIC_SEARCH_DOCS_HTTP_EXPORTERS:
    'https://www.elastic.co/guide/en/elasticsearch/reference/current/http-exporter.html#http-exporter',
  LOG_SCALE_DOCS: 'https://library.humio.com/',
  ALERT_CATALOG_SEARCH:
    '/docs/settings/alert-catalog.html#search-the-alert-catalog',
};

export const dateRangeOptions: DateSelectOption[] = [
  [1, 'hour'],
  [3, 'hours'],
  [12, 'hours'],
  [24, 'hours'],
  [3, 'days'],
  [7, 'days'],
  [2, 'weeks'],
  [1, 'month'],
  [3, 'months'],
];

export enum ActionType {
  PUBLISH = 'PUBLISH',
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
}

export enum Features {
  alertExport = 'alertExport',
  integrations = 'integrations',
  licenseDashboard = 'licenseDashboard',
}

export enum ExportServiceType {
  SPLUNK = 'splunk',
}

export enum ErrorCode {
  UNAUTHORIZED = 401,
  ACCESS_DENIED = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}

export enum momentJSDateFormats {
  D_MMM_HH_mm = 'D MMM HH:mm',
}

export enum expireWarningDays {
  EVAL_WARNING = 7,
  FIRST_WARNING = 30,
  SECOND_WARNING = 60,
}

export const graphqlErrorRedirects: ExtendedType = {
  loginRoute: ROUTES.login,
  notFoundRoute: ROUTES.notFound,
  serverErrorRoute: ROUTES.serverError,
};

export const FETCH_AUDIT_LOGS_LIMIT = 30;

export enum licenseType {
  STANDARD_EVAL = 'STANDARD_EVAL',
  STANDARD = 'STANDARD',
  ADVANCED_EVAL = 'ADVANCED_EVAL',
  ADVANCED = 'ADVANCED',
}

export const DETECTION_AUTO_CLOSE_DURATION = 7;

export const DISABLE_SSO_CONFIRMATION_TEXT = 'confirm';
