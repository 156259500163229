export const getFormattedAlias = (
  alias: string,
  limit: number,
  break_after: number,
) => {
  if (alias && alias.length > limit) {
    return `${alias.slice(0, break_after)}...${alias.slice(-3)}`;
  }
  return alias ? alias : '-';
};
