export const getDateTimeNumberValue = (
  currentValue: string,
  string: string,
  number: number,
): { newValue: string; shouldBlur: boolean } => {
  const valueStartsWithZero = currentValue.startsWith('0');
  const valueIsFull = currentValue.length === 2;
  const inputIsClear = string.length === 0;

  const newValue =
    valueIsFull && string.length > 2
      ? currentValue
      : valueStartsWithZero
        ? string
        : number.toString();

  const shouldBlur =
    (valueStartsWithZero && newValue.length === 2) || string.length > 2;

  return { newValue: inputIsClear ? '' : newValue, shouldBlur };
};
