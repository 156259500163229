import {
  Badge,
  chakra,
  Flex,
  HStack,
  MenuDivider,
  MenuItem,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { usePrevious } from '@gamma/hooks';
import { CorelightSupportIcon } from '@gamma/icons';
import { ROUTES, URLS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useLogOperationCall, useUserSession } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { setHumioTheme } from '@gamma/investigator/queries';
import { getFormattedAlias } from '@gamma/investigator/utilities';
import { NavMenu, NavUserLabel, RouterLink } from '@gamma/navigation';
import { useContext, useEffect } from 'react';

const { accountSettings } = ROUTES;

export const TopbarLinks = () => {
  const { isPOVTenant, userAlias, authState, userRole } =
    useContext(AuthContext);
  const { signOut } = useUserSession();
  const { colorMode, toggleColorMode } = useColorMode();
  const sessionId = localStorage.getItem('session_id_token');

  const prevColorMode = usePrevious(colorMode);

  useEffect(() => {
    const setHumioConfig = async () => {
      try {
        await setHumioTheme(colorMode, isPOVTenant);
      } catch (err) {
        // console.log(err);
      }
    };
    if (sessionId && colorMode !== prevColorMode) {
      setHumioConfig();
    }
  }, [colorMode, authState, sessionId, prevColorMode, isPOVTenant]);

  const colorModeIcon = useColorModeValue(
    <MuiIcon>dark_mode</MuiIcon>,
    <MuiIcon>light_mode</MuiIcon>,
  );
  const oppositeColorMode = useColorModeValue('Dark', 'Light');
  const logOperationCall = useLogOperationCall();

  const role = userRole ? i18n.roles[userRole] : undefined;

  return (
    <NavMenu
      label={
        <NavUserLabel
          isAdmin={userRole === 'admin'}
          username={userAlias ? getFormattedAlias(userAlias, 15, 10) : ''}
        >
          {userRole && (
            <Badge
              variant="outline"
              colorScheme={
                userRole === 'admin'
                  ? 'purple'
                  : userRole === 'analyst'
                    ? 'orange'
                    : userRole === 'viewer'
                      ? 'gray'
                      : 'blue'
              }
              data-testid="gamma-nav-user-label-role"
            >
              {role}
            </Badge>
          )}
        </NavUserLabel>
      }
      isCaretHidden
    >
      <MenuItem as="div">
        <RouterLink
          to={`${accountSettings}`}
          data-testid="topbar-link-account-settings"
        >
          <HStack alignItems="center">
            <MuiIcon>settings</MuiIcon>
            <chakra.span>{i18n.navigation.account.settings}</chakra.span>
          </HStack>
        </RouterLink>
      </MenuItem>
      <MenuItem onClick={toggleColorMode} data-testid="topbar-link-color-mode">
        <HStack alignItems="center">
          {colorModeIcon}
          <chakra.span>
            {i18n.formatString(i18n.navigation.colorMode, oppositeColorMode)}
          </chakra.span>
        </HStack>
      </MenuItem>
      <MenuDivider />
      <MenuItem
        onClick={() => window.open(URLS.INVESTIGATOR_DOCS, '_blank')}
        data-testid="topbar-link-docs"
      >
        <HStack alignItems="center" w="full">
          <MuiIcon>description</MuiIcon>
          <Flex justifyContent="space-between" alignItems="center" flex="1">
            <Text textStyle="body-md">{i18n.navigation.docs}</Text>
            <MuiIcon size="sm">open_in_new</MuiIcon>
          </Flex>
        </HStack>
      </MenuItem>
      <MenuItem
        onClick={() => window.open(URLS.CORELIGHT_SUPPORT, '_blank')}
        data-testid="topbar-link-support"
      >
        <HStack alignItems="center" w="full">
          <CorelightSupportIcon boxSize={5} />
          <Flex justifyContent="space-between" alignItems="center" flex="1">
            <Text textStyle="body-md">{i18n.navigation.corelightSupport}</Text>
            <MuiIcon size="sm">open_in_new</MuiIcon>
          </Flex>
        </HStack>
      </MenuItem>
      <MenuDivider />
      <MenuItem
        onClick={() => {
          logOperationCall('LOG_OUT', 'USER');
          signOut();
          localStorage.removeItem('shouldShowGPTToastGeneralSettingsLink');
        }}
        data-testid="topbar-link-logout"
      >
        <HStack alignItems="center">
          <MuiIcon color="red.400">power_settings_new</MuiIcon>
          <chakra.span>{i18n.authentication.logout}</chakra.span>
        </HStack>
      </MenuItem>
    </NavMenu>
  );
};
