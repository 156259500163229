export const ROUTES = {
  base: '/',
  notFound: '/404',
  serverError: '/500',
  home: '/overview/security-overview',
  ssoLogin: 'sso-login',
  login: '/login',
  accountSettings: '/account/settings',
  admin: '/admin',
  adminUsers: '/admin/users',
  adminAccess: '/admin/access',
  logSearch: '/log-search',
  privacyPolicy: '/privacy-policy',
  networkOverview: '/overview/network-overview',
  securityPosture: '/overview/security-posture',
  moreOverview: '/overview/more-dashboards',
  systemSettingsGeneral: '/system/settings/general',
  systemSettingsIntegrations: '/system/settings/integrations',
  systemSettingsIntegrationsConfig:
    '/system/settings/integrations/configuration',
  systemSettingsIntegrationsAlertExports:
    '/system/settings/integrations/alert-exports',
  systemSettingsSensorMonitoring: '/system/settings/sensor-monitoring',
  detections: '/detections',
  detectionDetails: (detectionId: string, queryString?: string) =>
    `/detections/${detectionId}/details${queryString ?? ''}`,
  securityAudit: '/system/settings/audit-logs',
  systemSettingsSecurityAudit: (queryString?: string) =>
    `/system/settings/audit-logs${queryString ?? ''}`,
  dashboards: (queryString?: string) =>
    `/overview/more-dashboards${queryString ?? ''}`,
  overviewWithParams: (queryString?: string) =>
    `/overview/security-overview${queryString ?? ''}`,
  detectionsWithParams: (queryString?: string) =>
    `/detections${queryString ?? ''}`,
  logSearchWithParams: (params?: string) =>
    `/log-search?live=false&fullscreen=false&widgetType=list-view&columns=%5B%7B"type"%3A"field"%2C"fieldName"%3A"%40timestamp"%2C"width"%3A210%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"%23path"%2C"width"%3A203%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"uid"%2C"width"%3A198%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"id.orig_h"%2C"width"%3A210%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"id.orig_p"%2C"width"%3A99%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"id.resp_h"%2C"width"%3A191%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"id.resp_p"%2C"width"%3A99%7D%2C%7B"type"%3A"field"%2C"fieldName"%3A"_system_name"%2C"width"%3A162%7D%5D&newestAtBottom=true&showOnlyFirstLine=false${
      params ?? ''
    }`,
  alertCatalog: '/system/settings/alert-catalog',
  systemSettingsAlertCatalog: (queryString?: string) => {
    return `/system/settings/alert-catalog${queryString ?? ''}`;
  },
  systemSettingsAlertCatalogId: (id: string, queryString?: string) => {
    return `/system/settings/alert-catalog/${id}${queryString ?? ''}`;
  },
};
