import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
  useMergeRefs,
} from '@chakra-ui/react';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Label, LabelProps } from '../Components';

export interface SwitchProps extends Omit<LabelProps, 'children'> {
  /** The name of the input */
  name: string;
  /** The size of the switch */
  size?: ChakraSwitchProps['size'];
  /** The variant name specifying how the switch should render */
  variant?: string;
  /** The default checked state for the switch */
  defaultChecked?: boolean;
  /** Specifying whether or not the switch is enabled */
  isChecked?: boolean;
  /** The function triggered whenever the switch is toggled */
  onChange?: ChakraSwitchProps['onChange'];
  /** The function triggered whenever the switch is focused */
  onFocus?: ChakraSwitchProps['onFocus'];
  /** The function triggered whenever the switch is blurred */
  onBlur?: ChakraSwitchProps['onBlur'];
  'aria-expanded'?: boolean;
  'aria-controls'?: string;
  'data-testid'?: string;
}

function useAddAriaAttribute(
  el: Element | null,
  attribute: string,
  value: string | boolean | undefined,
) {
  useEffect(() => {
    if (el) {
      const backup = el.getAttribute(attribute);
      if (value === undefined) {
        el.removeAttribute(attribute);
      } else {
        el.setAttribute(attribute, String(value));
      }
      return () => {
        if (backup === null) {
          el.removeAttribute(attribute);
        } else {
          el.setAttribute(attribute, backup);
        }
      };
    }
    return () => {
      /*noop*/
    };
  }, [el, attribute, value]);
}

function useSuppressAriaAttribute(
  el: Element | null,
  attribute: string,
  value: unknown,
) {
  useEffect(() => {
    if (el) {
      const backup = el.getAttribute(attribute);
      el.removeAttribute(attribute);
      return () => {
        if (backup !== null) {
          el.setAttribute(attribute, backup);
        }
      };
    }
    return () => {
      /*noop*/
    };
  }, [el, attribute, value]);
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      name,
      isChecked,
      defaultChecked,
      onChange,
      onFocus,
      onBlur,
      variant,
      size,
      'aria-expanded': ariaExpanded,
      'aria-controls': ariaControls,
      'data-testid': dataTestId = 'gamma-switch',
      ...rest
    },
    ref,
  ) => {
    const [input, setInput] = useState<HTMLInputElement | null>(null);

    useAddAriaAttribute(input, 'aria-controls', ariaControls);
    useAddAriaAttribute(input, 'aria-expanded', ariaExpanded);

    const labelClass = 'switch-label-needing-cleanup';
    const label = useMemo(
      () => input?.closest(`.${labelClass}`) || null,
      [input],
    );

    useSuppressAriaAttribute(label, 'aria-controls', ariaControls);
    useSuppressAriaAttribute(label, 'aria-expanded', ariaExpanded);

    return (
      <Label name={name} htmlFor={name} {...rest}>
        <ChakraSwitch
          id={name}
          className={labelClass}
          size={size}
          name={name}
          variant={variant}
          isChecked={isChecked}
          defaultChecked={defaultChecked}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={useMergeRefs(ref, (el) => el && setInput(el))}
          aria-expanded={ariaExpanded}
          aria-controls={ariaControls}
          data-testid={dataTestId}
        />
      </Label>
    );
  },
);
