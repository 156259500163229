import { RefObject, useEffect, useMemo } from 'react';

interface useMutationObserverProps {
  target: RefObject<HTMLElement>;
  callback: MutationCallback;
  options?: MutationObserverInit;
}

export const useMutationObserver = ({
  target,
  options = {},
  callback,
}: useMutationObserverProps): void => {
  const observer = useMemo(
    () =>
      new MutationObserver((mutationRecord, mutationObserver) => {
        callback(mutationRecord, mutationObserver);
      }),
    [callback],
  );

  useEffect(() => {
    if (observer && target.current) {
      observer.observe(target!.current!, options);
    }
    return () => observer?.disconnect();
  }, [target, observer, options]);
};
