import {
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ComponentType, MouseEvent } from 'react';
import { CellProps, Hooks, Row } from 'react-table';

export interface UseTableExpansionColumnParams<
  DataType extends Record<string, unknown>,
> {
  rowIsExpandable?: (row: Row<DataType>) => boolean | string;
  renderExpandedRow?: ComponentType<Row<DataType>>;
  isExpandable?: boolean;
}

export const useTableExpansionColumn =
  <DataType extends Record<string, unknown>>({
    rowIsExpandable = () => true,
    renderExpandedRow,
    isExpandable = true,
  }: UseTableExpansionColumnParams<DataType>) =>
  (hooks: Hooks<DataType>) => {
    if (isExpandable && renderExpandedRow !== undefined) {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'expanded',
          disableResizing: true,
          minWidth: 40,
          width: 40,
          maxWidth: 40,
          Header: (headerProps) => {
            return null;
          },
          Cell: (cellProps: CellProps<DataType>) => {
            const isRowExpandable = rowIsExpandable?.(cellProps.row);
            const toggleProps = cellProps.row.getToggleRowExpandedProps();
            const { isExpanded } = cellProps.row;

            const isExpandDisabled =
              typeof isRowExpandable === 'string' || !isRowExpandable;

            if (typeof isRowExpandable === 'string') {
              return (
                <Popover trigger="hover" placement="auto">
                  <PopoverTrigger>
                    <IconButton
                      size="box"
                      icon={<MuiIcon>keyboard_arrow_down</MuiIcon>}
                      aria-label="Expand Row"
                      {...toggleProps}
                      isDisabled
                      data-testid="data-table-expand-row"
                      variant="link"
                    />
                  </PopoverTrigger>
                  <PopoverContent px={4} py={3} w="auto">
                    {isRowExpandable}
                  </PopoverContent>
                </Popover>
              );
            }

            return (
              <IconButton
                size="box"
                icon={
                  <MuiIcon>
                    {isExpandDisabled
                      ? 'keyboard_arrow_down'
                      : isExpanded
                        ? 'keyboard_arrow_up'
                        : 'keyboard_arrow_down'}
                  </MuiIcon>
                }
                aria-label="Expand Row"
                isDisabled={!isRowExpandable}
                color="gray.500"
                {...toggleProps}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  // Yes, I shouldn't do this but `onClick` EXISTS ON THE INTERFACE
                  // AND TS WON'T SHUT UP ABOUT IT "NOT EXISTING"
                  //@ts-ignore
                  toggleProps.onClick(e);
                }}
                data-testid="data-table-expand-row"
              />
            );
          },
        },
        ...columns,
      ]);
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        const expansionGroupHeader = headerGroups[0].headers[0];
        expansionGroupHeader.canResize = false;
      });
    }
  };
