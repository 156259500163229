import {
  Button,
  ButtonGroup,
  HStack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Input, OptionType, Select } from '@gamma/form-fields';
import { EntityTypeIcon } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import {
  QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
  useAddExcludedEntitiesToAlertMetadata,
} from '@gamma/investigator/queries';
import { Drawer } from '@gamma/overlay';
import { chakraComponents, OptionProps } from 'chakra-react-select';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';

import { QueryVariables } from '../../';

export interface AlertCatalogDetailsForm {
  type?: EntityTypeOption;
  name: string;
}

export interface EntityTypeOption {
  value: string;
  label: string;
  type: ReactNode;
}

export interface AddEntityDrawerProps {
  type: string;
  title: string;
  tenant?: string;
  content_id: string;
  disclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
  };
  queryVariables: QueryVariables;
  refetchExcludedEntitiesPaginated: () => void;
}

export const AddEntityDrawer = ({
  type,
  title,
  tenant,
  disclosure,
  content_id,
  queryVariables,
  refetchExcludedEntitiesPaginated,
}: AddEntityDrawerProps) => {
  const showToastMessage = useToast();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [entityName, setName] = useState<string | undefined>('');
  const [entityType, setType] = useState<string | undefined>('');

  const { control, register, reset, watch } = useForm<AlertCatalogDetailsForm>({
    mode: 'onSubmit',
    defaultValues: {
      type: {},
      name: '',
    },
  });

  const clearForm = () => {
    reset();
    disclosure.onClose();
    setName(undefined);
    setType(undefined);
  };

  const {
    addNewEntity,
    cancel,
    entityLabel,
    entityTypeLabel,
    excludedEntitiesText,
    chooseEntityType,
    successRes,
    errorRes,
    entityAdded,
    entityNotAdded,
  } = i18n.pages.system.alertCatalog;

  const lowerTitle = title?.toLowerCase();
  const domainAlerts = [
    'ascii homograph',
    'dns reconnaissance',
    'domain typosquatting',
    'exfiltration via dns',
    'idn homograph',
    'nxdomain beaconing',
    'social engineering domain',
    'domain combosquatting',
    'attempted connection to a dga domain',
    'malicious ssl certificate',
  ];

  const domainOption = {
    value: 'DOMAIN',
    label: 'Domain',
    type: <EntityTypeIcon type={'domain'} boxSize={5} m={-1} mr={2} />,
  };

  const entityTypeOptions: EntityTypeOption[] = domainAlerts.includes(
    lowerTitle,
  )
    ? [domainOption]
    : [
        {
          value: 'IP',
          label: 'IP',
          type: <EntityTypeIcon type={'ip'} boxSize={5} m={-1} mr={2} />,
        },
        {
          value: 'IP_RANGE',
          label: 'CIDR',
          type: <EntityTypeIcon type={'ip_range'} boxSize={5} m={-1} mr={2} />,
        },
      ];

  useEffect(() => {
    // @ts-ignore
    const subscription = watch((value, { name, type }) => {
      if (name === 'name') {
        setName(value?.name);
      } else if (name === 'type') {
        setType(value?.type?.value);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (entityType === 'IP') {
      entityName && validator.isIP(entityName)
        ? setIsValid(true)
        : setIsValid(false);
    }
    if (entityType === 'IP_RANGE') {
      entityName && validator.isIPRange(entityName)
        ? setIsValid(true)
        : setIsValid(false);
    }
    if (entityType === 'DOMAIN') {
      entityName && validator.isFQDN(entityName.split('[')[0])
        ? setIsValid(true)
        : setIsValid(false);
    }
  }, [entityName, entityType]);

  const [addEntity, { loading: addLoading, error: addError }] =
    useAddExcludedEntitiesToAlertMetadata({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
          variables: queryVariables,
        },
      ],
      onCompleted: () => {
        showToastMessage({
          status: 'success',
          title: successRes,
          description: entityAdded,
          isClosable: true,
          position: 'bottom-right',
        });
        clearForm();
        refetchExcludedEntitiesPaginated();
      },
      onError: (error) => {
        showToastMessage({
          status: 'error',
          title: errorRes,
          description: entityNotAdded,
          isClosable: true,
          position: 'bottom-right',
        });
        clearForm();
      },
    });

  const handleAddSubmit = () => {
    const input = {
      tenant,
      content_id,
      alert_name: title,
      alert_type: type,
      entity_name: entityName,
      entity_type: entityType,
    };
    if (tenant) {
      addEntity({
        variables: {
          items: [input],
        },
      });
    }
  };

  const exampleIp = 'e.g. 165.191.55.221';
  const exampleRange = 'e.g. 165.191.55.221/21';
  const exampleDomain = 'e.g. corelight.com';

  return (
    <Drawer
      {...disclosure}
      onClose={() => {
        clearForm();
      }}
      title={addNewEntity}
      body={
        <>
          <Text mb={4}>{excludedEntitiesText}</Text>
          <VStack as="form" spacing={4} borderRadius="base" w="100%">
            <HStack w="100%">
              {/* @ts-ignore */}
              <Controller
                control={control}
                name={'type'}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select<false, EntityTypeOption>
                      isRequired
                      name={name}
                      minWidth="400px"
                      label={entityTypeLabel}
                      onChange={(values) => {
                        onChange(values as EntityTypeOption);
                      }}
                      options={
                        type === 'custom_search_rule'
                          ? [domainOption, ...entityTypeOptions]
                          : entityTypeOptions
                      }
                      placeholder={chooseEntityType}
                      components={customOption}
                    />
                  );
                }}
              />
            </HStack>
            <HStack w="100%">
              <Input
                {...register('name')}
                label={entityLabel}
                isRequired
                error={
                  entityName && !isValid
                    ? entityType === 'IP'
                      ? exampleIp
                      : entityType === 'IP_RANGE'
                        ? exampleRange
                        : entityType === 'DOMAIN'
                          ? exampleDomain
                          : undefined
                    : undefined
                }
                placeholder={
                  entityType === 'IP'
                    ? exampleIp
                    : entityType === 'IP_RANGE'
                      ? exampleRange
                      : entityType === 'DOMAIN' ||
                          domainAlerts.includes(lowerTitle)
                        ? exampleDomain
                        : exampleIp
                }
                isDisabled={!entityType && !entityName}
              />
            </HStack>
          </VStack>
        </>
      }
      footer={
        <ButtonGroup spacing={2} w="100%" variant="solid" colorScheme="gray">
          <Button
            w="50%"
            onClick={() => {
              clearForm();
            }}
          >
            {cancel}
          </Button>
          <Button
            w="50%"
            onClick={handleAddSubmit}
            disabled={
              !entityType ||
              !entityName ||
              !isValid ||
              (addLoading && !addError)
            }
            isLoading={addLoading && !addError}
          >
            Save
          </Button>
        </ButtonGroup>
      }
    />
  );
};

const CustomSelectOption = <
  IsMulti extends boolean,
  Option extends OptionType,
>({
  children,
  ...rest
}: OptionProps<Option, IsMulti, any>) => (
  <chakraComponents.Option {...rest}>
    {rest.data.type} {children}
  </chakraComponents.Option>
);

const customOption = {
  Option: CustomSelectOption,
};
