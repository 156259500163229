import {
  Center,
  Flex,
  Stack,
  StylesProvider,
  Text,
  VisuallyHiddenInput,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { MuiIcon, MuiIconProps } from '@gamma/display';
import { usePrevious } from '@gamma/hooks';
import { LoadingSpinner } from '@gamma/progress';
import { gammaContext } from '@gamma/theme';
import { mergeRefs, truncateFileName } from '@gamma/util';
import { without } from 'lodash';
import {
  FocusEventHandler,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Label } from '../Components';
import { FileListItem } from './components';

type ValidationResult = boolean | string | string[];
export type ValidationRule = (
  file: File,
) => ValidationResult | Promise<ValidationResult>;
export interface ValidationRules {
  [index: string]: ValidationRule;
}

export interface FileMetadata {
  name: string;
  shortName: string;
  validation?: string[];
  warnings?: string[];
  file: File;
}

const iconSizeMap = new Map<FileUploadBoxProps['size'], MuiIconProps['size']>([
  ['sm', 'md'],
  ['md', 'lg'],
  ['lg', 'lg'],
]);

export interface FileUploadBoxProps {
  label: string;
  name: string;
  accept?: string;
  uploadText?: string;
  isLabelHidden?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  rules?: ValidationRules;
  warningRules?: ValidationRules;
  isLoading?: boolean;
  ['data-testid']?: string;
  onChange?: (files: File[]) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFilesValidated?: (isValid: boolean, errors: string[]) => void;
  fieldError?: string;
  value?: File[];
  isRequired?: boolean;
  size?: 'sm' | 'md' | 'lg';
  helpText?: string;
}

export const FileUploadBox = forwardRef<HTMLInputElement, FileUploadBoxProps>(
  (
    {
      label,
      name,
      accept,
      isDisabled,
      isLabelHidden,
      isMultiple,
      uploadText = 'Drag and drop files here or click to upload',
      rules: additionalRules,
      warningRules = {},
      'data-testid': dataTestId = 'file-upload-box',
      onChange,
      onFocus,
      onBlur,
      onFilesValidated,
      fieldError,
      value,
      size = 'md',
      helpText,
      isLoading,
      isRequired,
    },
    ref,
  ) => {
    const isMounted = useRef(false);
    const { i18n } = useContext(gammaContext);
    const [files, setFiles] = useState<File[]>([]);
    const [filesMeta, setFilesMeta] = useState<FileMetadata[]>([]);
    const oldFiles = usePrevious(files);
    const onDrop = useCallback(
      <T extends File>(acceptedFiles: T[]) => {
        // Do something with the files
        const newFiles = isMultiple
          ? [...files, ...acceptedFiles]
          : [acceptedFiles[0]];
        setFiles(newFiles);
        onChange?.(newFiles);
      },
      [isMultiple, files],
    );
    const { getInputProps, getRootProps, inputRef, isFocused } = useDropzone({
      onDrop,
      disabled: isDisabled || isLoading,
    });

    useEffect(() => {
      isMounted.current = true;

      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      if (value) {
        setFiles(value);
      }
    }, [value]);

    const rules = useMemo<ValidationRules>(
      () => ({
        hasSize: (file: File) => file.size > 0 || i18n.files.errors.blank,
        hasUniqueName: (file: File) => {
          return (
            !files.some(
              (otherFile) => file !== otherFile && file.name === otherFile.name,
            ) || i18n.files.errors.unique
          );
        },
        ...additionalRules,
      }),
      [files, additionalRules],
    );

    const validate = useCallback(
      async (file: File, rules: ValidationRules): Promise<string[]> => {
        // checks all rules and maps the result to an array
        const validation = Object.values(rules).map((validator) =>
          validator(file),
        );
        const result = await Promise.all(validation);
        const resolution = result
          .flat()
          .filter((result) => typeof result === 'string') as string[];

        return Promise.resolve(resolution);
      },
      [rules],
    );

    useEffect(() => {
      const createMetaItem = async (file: File) => {
        const validation = await validate(file, rules);
        const warnings = await validate(file, warningRules);

        return {
          name: file.name,
          shortName: truncateFileName(file.name, 38),
          validation,
          warnings,
          file,
        };
      };

      const createMetaList = async () => {
        return Promise.all(files.map(createMetaItem));
      };

      // if oldFiles is undefined, this is first mount, and we shouldn't validate
      if (oldFiles !== undefined && files !== oldFiles) {
        if (files.length > 0) {
          createMetaList().then((filesMeta) => {
            if (isMounted.current) {
              const validationErrors = filesMeta.reduce<string[]>(
                (errors, { validation }) => {
                  if (validation.length > 0) {
                    errors.push(...validation);
                  }
                  return errors;
                },
                [],
              );

              const allFilesValid = validationErrors.length === 0;
              const hasFiles = filesMeta.length > 0;

              if (!hasFiles) {
                validationErrors.push('No files uploaded');
              }

              setFilesMeta(filesMeta);
              onFilesValidated?.(allFilesValid && hasFiles, validationErrors);
            }
          });
        } else {
          // tell the parent that the files are not valid, as there aren't any files
          onFilesValidated?.(false, ['No files uploaded']);
          setFilesMeta([]);
        }
      }
    }, [files, oldFiles, validate, onFilesValidated]);

    const handleDelete = (file: File) => {
      const newFiles = without(files, file);
      setFiles(newFiles);
      onChange?.(newFiles);
    };

    const styles = useMultiStyleConfig('FileUploadBox', {
      size,
      isFocused,
      isDisabled,
      isLoading,
    });

    return (
      <StylesProvider value={styles}>
        <Stack>
          <Label
            label={label}
            name={name}
            error={fieldError}
            isRequired={isRequired}
            isDisabled={isDisabled}
            isLabelHidden={isLabelHidden}
            data-testid={dataTestId}
          >
            <Stack>
              {helpText && <Text color="text.secondary">{helpText}</Text>}
              <Flex __css={styles.dropzone} {...getRootProps()}>
                {isLoading ? (
                  <Center w="full">
                    <LoadingSpinner size={size} />
                  </Center>
                ) : (
                  <>
                    <MuiIcon color="text.heading" size={iconSizeMap.get(size)}>
                      upload_file
                    </MuiIcon>
                    <Text
                      aria-live="assertive"
                      aria-label={'No file selected'}
                      textStyle="body-md"
                      fontWeight="normal"
                      data-testid={`${dataTestId}-upload-text`}
                    >
                      {uploadText}
                    </Text>
                  </>
                )}
                <VisuallyHiddenInput
                  {...getInputProps()}
                  ref={mergeRefs(ref, inputRef)}
                  type="file"
                  aria-required={true}
                  disabled={isDisabled}
                  name={name}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  multiple={isMultiple}
                  accept={accept}
                  data-testid={`${dataTestId}-input`}
                />
              </Flex>
            </Stack>
          </Label>
          {filesMeta.length > 0 && (
            <Stack
              as="ul"
              role="list"
              listStyleType="none"
              data-testid={`${dataTestId}-files-list`}
              spacing={2}
            >
              {filesMeta.map((file: FileMetadata, index: number) => (
                <FileListItem
                  key={index}
                  file={file}
                  data-testid={dataTestId}
                  size={size}
                  index={index}
                  handleDelete={handleDelete}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </StylesProvider>
    );
  },
);
