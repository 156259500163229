import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Radio,
  RadioGroup,
  Text,
  UseDisclosureProps,
  useToast,
} from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { IAlertCatalogOutletContext } from '@gamma/investigator/pages/system';
import {
  AlertMetadata,
  GET_ALERT_METADATA,
  QUERY_ALERT_METADATA_PAGINATED,
  useSetSeverityOnAlerts,
} from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';

const {
  corelightScore,
  customScore,
  pleaseSelectNewScore,
  save,
  cancel,
  editScore,
  successAlertCategoryScoreDescription,
  successRes,
  errorAlertCategoryScoreDescription,
  errorRes,
  customSeverityScoreText,
  editScoreFor,
} = i18n.pages.system.alertCatalog;

export interface CustomSeverityScoreDrawerProps {
  alert: Partial<AlertMetadata>;
  isFromAlertCatalogTable?: boolean;
  severityDisclosure: UseDisclosureProps;
}

export const CustomSeverityScoreModal = ({
  alert,
  severityDisclosure,
  isFromAlertCatalogTable,
}: CustomSeverityScoreDrawerProps) => {
  const isCustom = alert?.severity_info?.custom_severity_enabled;
  const severityInfo = alert?.severity_info;
  const customSeverityScoreProp =
    severityInfo?.custom_severity ?? severityInfo?.default_severity ?? null;
  const [customSeverityScore, setCustomSeverityScore] = useState<number | null>(
    customSeverityScoreProp,
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  const showToastMessage = useToast();

  const initialValues = useMemo(
    () => ({
      isCustomScore: isCustom ? 'true' : 'false',
    }),
    [isCustom],
  );

  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const form = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const { control, register, watch, setValue } = form;

  const formData = watch();

  const { content_id: contentIdFromParams } = useParams();

  const content_id = contentIdFromParams ?? alert?.content_id;

  useEffect(() => {
    if (customSeverityScoreProp !== customSeverityScore) {
      setIsSaveDisabled(false);
    }

    if (
      !_.isEqual(initialValues, formData) ||
      customSeverityScore !== customSeverityScoreProp
    ) {
      setIsSaveDisabled(false);

      if (
        formData?.isCustomScore === 'true' &&
        !customSeverityScore &&
        customSeverityScoreProp
      ) {
        setIsSaveDisabled(true);
      }
    } else {
      setIsSaveDisabled(true);
    }
  }, [formData, initialValues, customSeverityScore, customSeverityScoreProp]);

  useEffect(() => {
    if (
      (initialValues?.isCustomScore !== formData?.isCustomScore &&
        customSeverityScore !== customSeverityScoreProp &&
        formData?.isCustomScore === 'true') ||
      (initialValues?.isCustomScore !== formData?.isCustomScore &&
        formData?.isCustomScore === 'false')
    ) {
      setIsSaveDisabled(false);
    }

    if (
      formData?.isCustomScore === 'true' &&
      (customSeverityScore === customSeverityScoreProp || !customSeverityScore)
    ) {
      setIsSaveDisabled(true);
    }
  }, [
    formData?.isCustomScore,
    customSeverityScore,
    customSeverityScoreProp,
    initialValues,
    setValue,
  ]);

  useEffect(() => {
    if (initialValues?.isCustomScore === formData?.isCustomScore) {
      setValue('isCustomScore', formData?.isCustomScore);
    } else {
      setValue('isCustomScore', initialValues?.isCustomScore);
    }
    setIsSaveDisabled(true);
    setCustomSeverityScore(customSeverityScoreProp);
  }, [severityDisclosure?.isOpen]);

  const { variables } = useOutletContext<IAlertCatalogOutletContext>();

  const [setSeverityOnAlerts, { loading: severityLoading, called }] =
    useSetSeverityOnAlerts({
      variables: {
        items: [
          {
            content_id: String(content_id),
            tenant: orgTenantsQueryParam?.[0],
            severity_info: {
              custom_severity: Number(customSeverityScore || alert?._score),
              custom_severity_enabled: formData?.isCustomScore === 'true',
            },
            updated_timestamp: moment().unix(),
          },
        ],
      },
      refetchQueries: [
        {
          query: QUERY_ALERT_METADATA_PAGINATED,
          variables,
        },
        {
          query: GET_ALERT_METADATA,
          variables: {
            items: [
              {
                content_id,
                tenant: orgTenantsQueryParam?.[0],
              },
            ],
          },
        },
      ],
      onCompleted: (d) => {
        showToastMessage({
          title: successRes,
          description: successAlertCategoryScoreDescription,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
      },
      onError: (err) => {
        showToastMessage({
          title: errorRes,
          description: errorAlertCategoryScoreDescription,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const handleCloseSeverityDrawer = (resetStatusSwitch?: boolean) => {
    severityDisclosure?.onClose && severityDisclosure?.onClose();
    setIsSaveDisabled(true);
    setCustomSeverityScore(customSeverityScoreProp);

    if (initialValues?.isCustomScore === formData?.isCustomScore) {
      setValue('isCustomScore', initialValues?.isCustomScore);
    } else {
      setValue('isCustomScore', formData?.isCustomScore);
    }
  };

  const handleSubmitSeverityDrawer = async () => {
    if (
      formData?.isCustomScore === 'false' ||
      (formData?.isCustomScore === 'true' &&
        customSeverityScore &&
        customSeverityScore !== customSeverityScoreProp &&
        orgTenantsQueryParam?.[0])
    ) {
      await setSeverityOnAlerts();
    }

    handleCloseSeverityDrawer(true);
  };

  const modalTitle = isFromAlertCatalogTable
    ? i18n.formatString(editScoreFor, alert?.title ?? '')
    : editScore;

  return (
    <Modal
      size="lg"
      {...severityDisclosure}
      isCentered={true}
      onClose={handleCloseSeverityDrawer}
      isCloseDisabled={severityLoading}
      title={modalTitle}
      body={
        <>
          <Text
            data-testid="customize-severity-score-title"
            textStyle="body-md"
          >
            {customSeverityScoreText}
          </Text>
          <Flex mt={2}>
            {/* @ts-ignore */}
            <Controller
              control={control}
              name="isCustomScore"
              defaultValue={
                initialValues?.isCustomScore === 'true' ? 'true' : 'false'
              }
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  isDisabled={severityLoading}
                  value={value}
                  onChange={() => {
                    if (formData?.isCustomScore === 'true') {
                      setCustomSeverityScore(customSeverityScoreProp);
                    }
                  }}
                >
                  <Radio
                    data-testid="corelight-score-radio-button"
                    {...register('isCustomScore')}
                    value={'false'}
                  >
                    <Text
                      data-testid="corelight-score-text"
                      textStyle="body-md"
                    >
                      {corelightScore}
                    </Text>
                  </Radio>
                  <Box mt={2} ml={7}>
                    <SeverityScore
                      dataTestId="corelight-score-from-server"
                      score={alert?.severity_info?.default_severity as number}
                      customStyles={{
                        height: '24px',
                        width: '24px',
                        fontSize: '12px',
                      }}
                    />
                  </Box>
                  <Radio
                    data-testid="custom-score-radio-button"
                    mt={4}
                    {...register('isCustomScore')}
                    value={'true'}
                  >
                    <Text data-testid="custom-score-text" textStyle="body-md">
                      {customScore}
                    </Text>
                  </Radio>
                  <Box mt={2} ml={7}>
                    <Text
                      data-testid="please-select-new-score-text"
                      mb={2}
                      textStyle="body-sm"
                    >
                      {pleaseSelectNewScore}
                    </Text>
                    <Flex mt={2}>
                      {_.range(1, 11).map((score) => (
                        <Flex
                          data-testid={`set-custom-severity-score-button-${score}`}
                          key={score}
                          cursor="pointer"
                          textDecor="none"
                          onClick={() => {
                            if (formData.isCustomScore === 'true') {
                              setCustomSeverityScore(score);
                              setIsSaveDisabled(
                                score === customSeverityScoreProp,
                              );
                            }
                          }}
                          mr={2}
                        >
                          <SeverityScore
                            isSelected={
                              score === customSeverityScore &&
                              formData.isCustomScore === 'true'
                            }
                            isActive={
                              formData.isCustomScore !== 'true' ||
                              severityLoading
                            }
                            isCustom={true}
                            score={score}
                            customStyles={{
                              height: '24px',
                              width: '24px',
                              fontSize: '12px',
                            }}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </Box>
                </RadioGroup>
              )}
            />
          </Flex>
        </>
      }
      footer={
        <ButtonGroup
          justifyContent="flex-end"
          isDisabled={severityLoading}
          w="100%"
        >
          <Button
            onClick={() => handleCloseSeverityDrawer()}
            variant="solid"
            colorScheme="gray"
          >
            {cancel}
          </Button>
          <Button
            data-testid="save-severity-score-button"
            isDisabled={isSaveDisabled}
            isLoading={severityLoading}
            onClick={handleSubmitSeverityDrawer}
            variant="solid"
            colorScheme="blue"
          >
            {save}
          </Button>
        </ButtonGroup>
      }
    />
  );
};
