import { Select } from 'chakra-react-select';
import { ComboboxWrapper } from '../Components';
import { OptionType, useCombobox } from '../hooks';

export interface ComboboxProps<Option extends OptionType> {
  'data-testid'?: string;
  id?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  category: string;
  placeholder?: string;
  options: Option[];
  value: Option[];
  onChange?: (value: Option[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  size?: 'xs' | 'sm' | 'md';
}

export const Combobox = <Option extends OptionType>(
  props: ComboboxProps<Option>,
) => {
  const {
    'data-testid': dataTestId = 'gamma-combobox',
    isMulti = true,
    placeholder = `Search ${props.category}`,
    size = 'md',
  } = props;
  const { wrapperProps, selectProps } = useCombobox<Option>({
    ...props,
    'data-testid': dataTestId,
    isMulti,
    placeholder,
    size,
  });
  return (
    <ComboboxWrapper {...wrapperProps}>
      <Select {...selectProps} />
    </ComboboxWrapper>
  );
};
