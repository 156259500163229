import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { PaginatedExcludedEntities } from './types';

export const REMOVE_EXCLUDED_ENTITIES_TO_ALERT_METADATA = gql`
  mutation removeExcludedEntitiesFromAlertMetadata(
    $items: [ExcludedEntityInput]!
  ) {
    removeExcludedEntitiesFromAlertMetadata(items: $items) {
      offset
      size
      excluded_entities {
        _score
        content_id
        entity_id
        entity_ip
        entity_ip_range
        entity_name
        entity_type
        tenant
        created_by
        created_by_alias
        created_timestamp
      }
      total_items
    }
  }
`;

interface IRemoveExcludedEntitiesFromAlertMetadata {
  removeExcludedEntitiesFromAlertMetadata: PaginatedExcludedEntities;
}

export const useRemoveExcludedEntitiesFromAlertMetadata = (
  options: MutationHookOptions<
    IRemoveExcludedEntitiesFromAlertMetadata,
    OperationVariables
  >,
) => useMutation(REMOVE_EXCLUDED_ENTITIES_TO_ALERT_METADATA, options);
