import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Link,
  StylesProvider,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import * as AllIcons from '@gamma/icons';
import { CorelightLogo } from '@gamma/icons';
import { ReactNode, useState } from 'react';

import { CardPageContent, CardPageContext } from '../CardPage';

export interface SplitPageProps {
  left: ReactNode;
  right?: ReactNode;
  logo: ReactNode;
  /** The links to render underneath the card */
  links?: {
    icon: keyof Omit<typeof AllIcons, 'Illustrations'>;
    name: string;
    url: string;
    isExternal?: boolean;
  }[];
}

export const SplitPage = ({
  left,
  right,
  links,
  logo = <CorelightLogo />,
}: SplitPageProps) => {
  const styles = useMultiStyleConfig('SplitPage', {});
  const [hasIllustration, setHasIllustration] = useState<boolean>(false);

  return (
    <CardPageContext.Provider value={{ hasIllustration, setHasIllustration }}>
      <StylesProvider value={styles}>
        <Grid __css={styles.background} data-testid="gamma-split-page">
          <GridItem __css={styles.panel} data-testid="gamma-split-page-left">
            <Box height="24px">{logo}</Box>
            <CardPageContent>{left}</CardPageContent>
            <HStack>
              {links?.map(({ icon, name, url, isExternal = false }) => {
                const Icon = AllIcons[icon];
                return (
                  <Button
                    as={Link}
                    variant="link"
                    href={url}
                    key={name}
                    isExternal={isExternal}
                    data-testid="card-page-link"
                    leftIcon={<Icon boxSize={4} color="blue.500" />}
                    color="text.primary"
                    fontWeight="normal"
                  >
                    {name}
                  </Button>
                );
              })}
            </HStack>
          </GridItem>
          <GridItem data-testid="gamma-split-page-right" position="relative">
            {right}
          </GridItem>
        </Grid>
      </StylesProvider>
    </CardPageContext.Provider>
  );
};
