import { useColorMode } from '@chakra-ui/react';
import { usePrevious } from '@gamma/hooks';
import { AuthContext, LogScaleContext } from '@gamma/investigator/context';
import { useContext, useEffect, useRef, useState } from 'react';

interface dashboardDetails {
  name?: string;
  dashboardId?: string;
  investigatorApp?: string;
  corelightDashboard?: string;
}

export const useReloadHumioUI = ({
  iframeType,
  params,
  dashboardId,
  dashboardDetails,
}: {
  iframeType: string;
  params?: string;
  dashboardId?: string;
  dashboardDetails?: dashboardDetails;
}) => {
  const { user, isPOVTenant } = useContext(AuthContext);
  const {
    logScaleURL,
    setLogScaleURL,
    logScaleDashboardId,
    setLogScaleDashboardId,
    logScaleURLParams,
    setLogScaleURLParams,
    iframeKey,
    setIframeKey,
  } = useContext(LogScaleContext);

  const { colorMode } = useColorMode();
  const previousColorMode = usePrevious(colorMode);

  const userTenant = user?.attributes?.['custom:tenant_id'];

  const iframeSrcRoot = isPOVTenant ? 'search-internal' : 'search';

  const base =
    iframeType === 'search'
      ? `/${iframeSrcRoot}/${userTenant}-logs/search`
      : iframeType === 'dashboards'
        ? `/${iframeSrcRoot}/${userTenant}-logs/dashboards${
            dashboardDetails?.corelightDashboard
              ? `/${dashboardDetails?.corelightDashboard}`
              : ''
          }${
            dashboardDetails?.investigatorApp
              ? `/${dashboardDetails?.investigatorApp}`
              : ''
          }${dashboardDetails?.name ? `/${dashboardDetails?.name}` : ''}${
            dashboardDetails?.dashboardId
              ? `?dashboardId=${dashboardDetails?.dashboardId}`
              : ''
          }`
        : iframeType === 'sensorsMonitoring'
          ? `/${iframeSrcRoot}/${userTenant}-logs/dashboards/${dashboardId}`
          : '';

  const [iframeSrc, setIframeSrc] = useState<string>('');
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // sets iframe source based on the view type
  useEffect(() => {
    if (iframeType === 'search') {
      setIframeSrc(`${base}?${params}`);
    } else {
      setIframeSrc(`${base}`);
    }
  }, [iframeType, params, base, userTenant]);

  // reloads the iframe view on color mode change
  useEffect(() => {
    if (previousColorMode && colorMode !== previousColorMode) {
      setIframeKey && iframeKey !== undefined && setIframeKey(iframeKey + 1);
    }
  }, [colorMode, iframeKey, previousColorMode]);

  // sets iframe url into global context on a 500ms interval
  useEffect(() => {
    const timer = setInterval(() => {
      if (iframeRef?.current?.contentDocument?.URL) {
        const url = iframeRef?.current?.contentDocument?.URL;
        const params =
          decodeURIComponent(url)?.split('?')?.[1]?.split('&') || [];
        const dashboardId =
          params[
            params.findIndex((element) => element.includes('dashboardId'))
          ]?.split('=')?.[1];
        if (url !== logScaleURL && setLogScaleURL) {
          setLogScaleURL(url);
        }
        if (dashboardId !== logScaleDashboardId && setLogScaleDashboardId) {
          setLogScaleDashboardId(dashboardId);
          if (
            params.sort().toString() !==
              logScaleURLParams?.sort()?.toString() &&
            setLogScaleURLParams
          ) {
            setLogScaleURLParams(params);
          }
        }
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [logScaleURL, logScaleDashboardId, logScaleURLParams]);

  return {
    iframeRef,
    iframeSrc,
  };
};
