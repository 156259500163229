import { useDisclosure } from '@chakra-ui/react';
import { RefObject, createContext, createRef, useContext } from 'react';

type UseDisclosureReturn = ReturnType<typeof useDisclosure>;

export const SlideDrawerPortalContext = createContext<{
  slideDrawerPortalRef: RefObject<HTMLDivElement>;
}>({
  slideDrawerPortalRef: createRef<HTMLDivElement>(),
});

export const useSlideDrawerContext = () => {
  const { slideDrawerPortalRef } = useContext(SlideDrawerPortalContext);

  return {
    portalRef: slideDrawerPortalRef,
  };
};
