import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

enum LicenseTypes {
  ADVANCED,
  EVAL,
  STANDARD,
}

enum TenantStatus {
  active,
  waiting_approval,
  creating_configs,
  inactive,
  error,
}

interface EditLicenseInput {
  license_type: LicenseTypes;
  start: number;
  end: number;
  retention_days: number;
  gbps: string;
}

interface PrimaryContactInput {
  name: string;
  email: string;
}

interface EditPrivilegeInput {
  chatgpt: boolean;
}

interface EditTenantInput {
  tenant_id: string;
  license_info: EditLicenseInput;
  tenant_location: string;
  primary_contact: PrimaryContactInput;
  status: TenantStatus;
  privilege: EditPrivilegeInput;
}

interface EditTenantVariables extends OperationVariables {
  input: {
    tenant_id: string;
    license_info?: EditLicenseInput;
    tenant_location?: string;
    primary_contact?: PrimaryContactInput;
    status?: TenantStatus;
    privilege?: EditPrivilegeInput;
  };
}

export const EDIT_TENANT = gql`
  mutation editTenant($input: EditTenantInput!) {
    editTenant(input: $input) {
      body {
        data
        message
        success
      }
      status_code
    }
  }
`;

export const useEditTenant = (
  options: MutationHookOptions<any, EditTenantVariables>,
) => useMutation<any, EditTenantVariables>(EDIT_TENANT, options);
