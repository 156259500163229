import './donut.style.scss';

import { ThemeContext } from '@emotion/react';
import { chartColorsByArraySize } from '@gamma/investigator/utilities';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

import { GraphHelper } from '../helpers';

export class DonutChart extends GraphHelper {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.parsedData = [];
    this.donutGroup = null;
    this.titleGroup = null;

    this.legend = null;

    this.state = {
      tooltipText: null,
    };
  }

  componentDidMount() {
    this.prepareData();
    super.componentDidMount();
    this.buildFilter();
  }

  componentDidUpdate() {
    this.prepareData();

    super.componentDidUpdate();
  }

  prepareData() {
    const colors = chartColorsByArraySize(this?.props?.data?.length);

    const totalValues = this?.props?.data?.reduce(
      (sum, item) => sum + item.contribution,
      0,
    );
    this.parsedData = this?.props?.data?.map((item, i) => {
      const color = colors[i].split('.')[0];
      const gradation = colors[i].split('.')[1];
      return {
        ...item,
        color: this?.props?.theme?.colors[color][gradation],
        contribution: (item.contribution * 100) / totalValues,
      };
    });
  }

  buildGraph() {
    super.buildGraph();
    this.donutGroup = this.svg.append('g').attr('class', 'donut-group');
    this.titleGroup = this.svg.append('g').attr('class', 'title-group');
  }

  updateGraphData() {
    const { radius, height, width } = this.svgDimensions;
    const arc = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius - this?.props?.donutSize);

    this.donutGroup.attr('transform', `translate(${width / 2} ${height / 2})`);

    if (this?.props?.title) {
      this.titleGroup.attr(
        'transform',
        `translate(${width / 2} ${height / 2})`,
      );

      const title = this.titleGroup.selectAll('text').data([1]);

      title.enter().append('text').merge(title).html(this?.props?.title);
      title.exit().remove();
    }
    const donut = d3
      .pie()
      .value((d) => d.contribution)
      .sort(null);

    const donutPaths = this.donutGroup
      .selectAll('path')
      .data(donut(this.parsedData));

    donutPaths
      .enter()
      .append('path')
      .merge(donutPaths)
      .attr('id', (d, i) => `donut-slice-${i}`)
      .attr('data-testid', (d, i) => `donut-slice-${i}`)
      .attr('class', 'donut-slice')
      .attr('fill', (d) => d.data.color)
      .attr('data-tooltip-header', (d) => d.data.column)
      .attr('data-tooltip-content', (d) => d.data.value)
      .on('mousemove', (event, d) => this.updateTooltip(event, d))
      .on('mouseleave', () => this.tooltip.attr('hidden', true))
      .each((d, i) => {
        const currentSlice = this.donutGroup.select(`#donut-slice-${i}`).node();

        if (!currentSlice.current) {
          currentSlice.current = {
            startAngle: 0,
            endAngle: 0,
          };
        }
      })
      .transition()
      .duration(500)
      .attrTween('d', (d, i) => {
        const currentSlice = this.svg.select(`#donut-slice-${i}`).node();
        const interpolate = d3.interpolate(currentSlice.current, d);
        currentSlice.current = interpolate(0);
        return (t) => arc(interpolate(t));
      });

    donutPaths.exit().remove();
  }

  buildFilter() {
    const filter = this.svg
      .append('defs')
      .append('filter')
      .attr('id', 'drop-shadow')
      .attr('height', '130%');

    filter
      .append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 5)
      .attr('result', 'blur');

    const feMerge = filter.append('feMerge');

    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  }

  updateSvgDimensions() {
    super.updateSvgDimensions();
    const { margin, hasLegend } = this?.props || {};

    // const width = this?.svgDimensions?.width / (hasLegend ? 2 : 1) || 0;
    // const height = this?.svgDimensions?.height || 0;

    const width = 143;
    const height = 150;
    this.svgDimensions.width = width;
    this.svgDimensions.radius =
      (Math.min(width, height) - Math.max(margin.top, margin.left)) / 2;

    this.updateGraphData();
  }

  updateTooltip(event, item) {
    const element = {
      getBoundingClientRect: () => ({
        left: event.x,
        width: 280,
        top: event.y,
        height: 0,
      }),
    };

    super.updateTooltip(element, {
      header: `${item.data.display_name || item.data.feature_name}`,
      content: `value: ${item.data.actual}<br />percent: ${Math.floor(
        item.data.contribution,
      )}`,
    });
  }

  render() {
    return (
      <div
        data-d3-chart
        className={this?.props?.className}
        id={this?.props?.id}
        ref={this.setContainerRefHandler}
      />
    );
  }
}

DonutChart.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.oneOf(['top', 'bottom']),
  title: PropTypes.string,
  donutSize: PropTypes.number,
  className: PropTypes.string,
  margin: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
};

DonutChart.defaultProps = {
  title: '',
  donutSize: 30,
  tooltipPosition: 'top',
  className: 'd3-donut-chart',
  margin: {
    top: 30,
    left: 30,
  },
};
