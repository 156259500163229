import { OperationVariables } from '@apollo/client';
import { ButtonProps, useDisclosure, useToast } from '@chakra-ui/react';
import { useIDPUploadXMLHandler } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  DESCRIBE_IDENTITY_PROVIDER,
  useConfigureIdentityProvider,
  useEnableDisableUserPoolAppClientIdp,
  useUpdateIdentityProvider,
} from '@gamma/investigator/queries';
import { ConfirmationModal } from '@gamma/overlay';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ModalBody } from './IdpConfigurationModalBody';

interface IdpConfigurationModalProps {
  children: ReactNode;
  data: OperationVariables | undefined;
  isUpdating?: boolean;
}

export const attributeMapping = JSON.stringify({
  email: 'email_address',
  'custom:group': 'group',
  'custom:roles': 'roles',
});

export enum ModalSteps {
  configureIDP = 1,
  configureInvestigator = 2,
  configureRoles = 3,
}

export interface storeIdentityProviderProps {
  attribute_mapping: string;
  provider_details: string;
  provider_type: string;
}

export const IdpConfigurationModal = ({
  children,
  data,
  isUpdating = false,
}: IdpConfigurationModalProps) => {
  const { idpConfigurationModal, button } = i18n.pages.access;
  const useFormProps = useForm();
  const [describeIDPData, setDescribeIDPData] = useState(data);
  const [step, setStep] = useState(ModalSteps.configureIDP);
  const [xmlFile, setXmlFile] = useState<File[]>([]);
  const [showAlert, setShowAlert] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [parsedData, setParsedData] = useState<Record<string, string>>();
  const [updateIDPConfig, setUpdateIDPConfig] = useState<boolean>(false);
  const [identityProviderData, setIdentityProviderData] =
    useState<storeIdentityProviderProps>({
      attribute_mapping: '',
      provider_details: '',
      provider_type: 'xml',
    });

  let isConfigured =
    !!describeIDPData?.describeIdentityProvider?.provider_details;
  const is_sso_enabled =
    !!describeIDPData?.describeIdentityProvider?.is_sso_enabled;

  const { onOpen, onClose, isOpen } = useDisclosure({
    onClose: () => {
      setStep(ModalSteps.configureIDP);
      resetModalForm();
    },
    onOpen: () => {
      if (isUpdating) {
        setStep(ModalSteps.configureIDP);
      }
    },
  });

  const useIDPUploadXMLHandlerProps = useIDPUploadXMLHandler({
    setFileError,
    setParsedData,
  });

  const variables = {
    attribute_mapping: attributeMapping,
    provider_details: useIDPUploadXMLHandlerProps.xml,
    provider_type: 'xml',
  };

  const [enableDisableIdpClient, { loading: enableSsoLoading }] =
    useEnableDisableUserPoolAppClientIdp({
      fetchPolicy: 'network-only',
      awaitRefetchQueries: true,
      refetchQueries: [DESCRIBE_IDENTITY_PROVIDER, 'describeIdentityProvider'],
      onCompleted: () => {
        showToastMessage({
          status: 'success',
          title: idpConfigurationModal.configure.toast.title,
          description: idpConfigurationModal.configure.toast.description,
          isClosable: true,
          position: 'bottom-right',
        });
        onClose();
      },
      onError: (error) => {
        showToastMessage({
          status: 'error',
          title: idpConfigurationModal.configure.toast.errorTitle,
          description: error?.message,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [configureIdentityProvider, { loading: configIdpLoading }] =
    useConfigureIdentityProvider({
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        if (response?.configureIdentityProvider) {
          isConfigured = !isConfigured;
          setDescribeIDPData({ describeIdentityProvider: variables });
        } else {
          showToastMessage({
            status: 'error',
            title: idpConfigurationModal.configure.toast.errorTitle,
            description: idpConfigurationModal.configure.toast.errorDescription,
            isClosable: true,
            position: 'bottom-right',
          });
        }
      },
      onError: (error) => {
        resetModalForm();
        setShowAlert(error?.message);

        showToastMessage({
          status: 'error',
          title: idpConfigurationModal.configure.toast.errorTitle,
          description: error?.message,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [updateIdentityProvider, { loading: updateIdpLoading }] =
    useUpdateIdentityProvider({
      fetchPolicy: 'network-only',
      refetchQueries: isUpdating
        ? [DESCRIBE_IDENTITY_PROVIDER, 'describeIdentityProvider']
        : [],
      onCompleted: (data) => {
        if (data?.updateIdentityProvider?.body?.message) {
          showToastMessage({
            status: 'success',
            title: idpConfigurationModal.update.toast.title,
            description: data.updateIdentityProvider.body.message,
            isClosable: true,
            position: 'bottom-right',
          });

          if (isUpdating) {
            onClose();
            return;
          }

          if (isConfigured) {
            setStep(ModalSteps.configureRoles);
            return;
          }
        }
      },
      onError: (error) => {
        showToastMessage({
          status: 'error',
          title: idpConfigurationModal.update.toast.errorTitle,
          description: error.message,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const storeIdentityProviderData = (variables: storeIdentityProviderProps) => {
    setIdentityProviderData(variables);
  };

  const getStoredIdentityProviderData = (): storeIdentityProviderProps =>
    identityProviderData;

  const loading = configIdpLoading || enableSsoLoading;

  const resetModalForm = () => {
    setParsedData({});
    setXmlFile([]);
    setShowAlert('');
    setFileError('');
  };

  const showToastMessage = useToast();
  let confirmProperties: ButtonProps = {};
  let cancelProperties: ButtonProps = {};

  const configInvCnfDisable = (): boolean => {
    if (isConfigured && !fileError) {
      return false;
    }

    return !xmlFile.length || Boolean(fileError);
  };

  const args = () => {
    switch (step) {
      case ModalSteps.configureIDP:
        confirmProperties = {
          onClick: (e) => {
            e.preventDefault();
            setStep(ModalSteps.configureInvestigator);
          },
        };

        return {
          confirmProps: confirmProperties,
          confirmText: button.next,
        };

      case ModalSteps.configureInvestigator:
        confirmProperties = {
          onClick: (e) => {
            const idProviderData =
              describeIDPData?.describeIdentityProvider || {};

            // when updating idp config with a new xml file
            if (isUpdating && updateIDPConfig) {
              storeIdentityProviderData({
                ...idProviderData,
                ...variables,
              });
            }

            // when updating idp config without a new xml file
            if (
              !updateIDPConfig &&
              idProviderData &&
              idProviderData?.provider_details
            ) {
              const existingIdpProviderData = JSON.parse(
                JSON.stringify(idProviderData),
              );
              existingIdpProviderData.provider_details = Buffer.from(
                JSON.parse(existingIdpProviderData?.provider_details),
                'utf-8',
              ).toString('base64');
              existingIdpProviderData.provider_type = 'xml';
              storeIdentityProviderData(existingIdpProviderData);
            }

            if (!idProviderData?.provider_details) {
              storeIdentityProviderData(variables);
            }

            setStep(ModalSteps.configureRoles);
            e.preventDefault();
          },
          isDisabled: configInvCnfDisable(),
        };

        cancelProperties = { isDisabled: configIdpLoading };

        return {
          confirmProps: confirmProperties,
          cancelProps: cancelProperties,
          isClosedOnCancel: false,
          confirmText: button.next,
          cancelText: button.back,
          onCancel: () => setStep(ModalSteps.configureIDP),
        };

      case ModalSteps.configureRoles:
        confirmProperties = {
          isLoading: enableSsoLoading || updateIdpLoading || configIdpLoading,
          type: 'submit',
          form: 'idpRoleConfigurationForm',
        };

        cancelProperties = {
          isDisabled: enableSsoLoading || updateIdpLoading || configIdpLoading,
        };

        return {
          confirmProps: confirmProperties,
          cancelProps: cancelProperties,
          isClosedOnCancel: false,
          confirmText: !isUpdating
            ? isConfigured && !is_sso_enabled
              ? button.enable
              : button.save
            : updateIDPConfig
              ? button.update
              : button.close,
          cancelText: button.back,
          onConfirm: () => {
            if (isConfigured && !is_sso_enabled) {
              enableDisableIdpClient({ variables: { enable: true } });
              return;
            }

            if (isUpdating && !updateIDPConfig) {
              onClose();
            }
          },
          isDisabled: !(isUpdating && updateIDPConfig),
          isLoading: configIdpLoading || updateIdpLoading || enableSsoLoading,
          onCancel: () => {
            setStep(ModalSteps.configureInvestigator);
          },
        };

      default:
        return {};
    }
  };

  return (
    <ConfirmationModal
      title={
        isUpdating
          ? idpConfigurationModal.update.title
          : idpConfigurationModal.configure.title
      }
      size="lg"
      body={
        <ModalBody
          step={step}
          setFileError={setFileError}
          xmlFile={xmlFile}
          setXmlFile={setXmlFile}
          useFormProps={useFormProps}
          resetModalForm={resetModalForm}
          showAlert={showAlert}
          configureIdentityProvider={configureIdentityProvider}
          updateIdentityProvider={updateIdentityProvider}
          storeIdentityProviderData={storeIdentityProviderData}
          getStoredIdentityProviderData={getStoredIdentityProviderData}
          useIDPUploadXMLHandlerProps={useIDPUploadXMLHandlerProps}
          fileError={fileError}
          data={describeIDPData}
          isUpdating={isUpdating}
          parsedData={parsedData}
          setParsedData={setParsedData}
          updateIDPConfig={updateIDPConfig}
          setUpdateIDPConfig={setUpdateIDPConfig}
        />
      }
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      isCentered
      closeOnEsc={!loading}
      closeOnOverlayClick={!loading}
      isCloseDisabled={loading}
      isClosedOnConfirm={false}
      {...args()}
    >
      {children}
    </ConfirmationModal>
  );
};
