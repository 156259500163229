// useSetAlertStatusBySearchTerm
import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { SetAlertStatusBySearchTermResult } from './types';

export const SET_ALERT_STATUS_BY_SEARCH_TERM = gql`
  mutation setAlertStatusBySearchTerm(
    $items: [SetAlertStatusBySearchTermInput]!
  ) {
    setAlertStatusBySearchTerm(items: $items) {
      updated_record_count
      tenant
    }
  }
`;

interface ISetAlertStatusBySearchTerm {
  setAlertStatusBySearchTerm: SetAlertStatusBySearchTermResult[];
}

export const useSetAlertStatusBySearchTerm = (
  options: MutationHookOptions<ISetAlertStatusBySearchTerm, OperationVariables>,
) => {
  return useMutation(SET_ALERT_STATUS_BY_SEARCH_TERM, options);
};
