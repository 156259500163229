import { DetectionAlertGroupCountSummary } from '@gamma/investigator/queries';
import moment from 'moment';
import { intColor } from './chartOptions';

export interface SeriesItem {
  name?: string;
  data: ApexAxisChartSeries[] | [];
}

const emptyDataItem = {
  name: '',
  data: [],
} as SeriesItem;

export interface seriesDataArgs {
  seriesMaxLength: number;
  seriesData?: DetectionAlertGroupCountSummary;
}

export const seriesData = ({ seriesData, seriesMaxLength }: seriesDataArgs) => {
  if (!seriesData) {
    return null;
  } else {
    const { summary, row_count } = seriesData;

    const series = [...new Array(seriesMaxLength)] as SeriesItem[];

    summary.forEach((summaryData, index) => {
      const { severity, alert_name, detections, entity_name } = summaryData;

      const summarySeries = detections?.map((detection) => {
        const detectionEarliest = detection.earliest_start_timestamp * 1000;
        const detectionLatest = detection.latest_start_timestamp * 1000;

        const totalAlerts = detection.buckets.reduce(
          (accumulator, bucket) => accumulator + bucket.alert_count,
          0,
        );

        const { detection_id } = detection;

        return {
          x: `${severity} ${alert_name} ${entity_name}`,
          y: [detectionEarliest, detectionLatest],
          severity,
          alert_name,
          entity_name,
          detection_id,
          ...(moment(detectionLatest).diff(moment(detectionEarliest), 'hours') >
            1 && {
            goals: detection.buckets.map((bucket) => {
              return {
                name: '',
                strokeColor: intColor(severity),
                detectionId: `${detection_id}`,
                toolTipTextStart: `${moment(bucket.bucket_start * 1000).format(
                  'lll',
                )}`,
                toolTipTextEnd: `${moment(bucket.bucket_end * 1000).format(
                  'lll',
                )}`,
                toolTipTextAlerts: `${bucket.alert_count} Alerts`,
                value: [bucket.bucket_start * 1000, bucket.bucket_end * 1000],
              };
            }),
          }),
          ...(moment(detectionLatest).diff(
            moment(detectionEarliest),
            'hours',
          ) <= 1 && {
            goals: [
              {
                name: '',
                strokeColor: intColor(severity),
                detectionId: `${detection_id}`,
                toolTipTextStart: `${moment(detectionEarliest).format('lll')}`,
                toolTipTextEnd: `${moment(detectionLatest).format('lll')}`,
                toolTipTextAlertName: alert_name,
                toolTipTextEntityName: entity_name,
                toolTipTextAlerts: `${totalAlerts} Alerts`,
                value:
                  detection.earliest_start_timestamp * 1000 +
                  (detection.latest_start_timestamp * 1000 -
                    detection.earliest_start_timestamp * 1000) /
                    2,
                strokeWidth: 12,
                strokeHeight: 0,
                strokeLineCap: 'round',
              },
            ],
          }),
        };
      });

      const formattedSummary = {
        name:
          severity && alert_name && entity_name
            ? `${severity} ${alert_name} ${entity_name}`
            : undefined,
        data: summarySeries,
      } as SeriesItem;

      series[index] = formattedSummary;
    });

    const treatedSeries = [
      ...series.map((series) => {
        if (series === undefined) {
          return emptyDataItem;
        } else {
          return series;
        }
      }),
    ] as SeriesItem[];

    return {
      row_count,
      series: treatedSeries,
    };
  }
};
