import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { IconButton } from '@gamma/buttons';
import { MuiIcon } from '@gamma/display';
import { Input, Select, Switch } from '@gamma/form-fields';
import { TrashIcon } from '@gamma/icons';
import { REGEX, URLS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  AuthStrategyOption,
  ExporterTLSInfoInput,
  GET_EXPORTERS,
  HttpExporter,
  HttpExporterAuthInput,
  HttpExporterHeader,
  useSaveExporter,
} from '@gamma/investigator/queries';
import { Panel } from '@gamma/layout';
import { Alert } from '@gamma/overlay';
import _ from 'lodash';
import {
  FormEvent,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useSetExporterFormState } from '../../../Hooks';

const { INVESTIGATOR_DOCS_ALERT_EXPORT, INVESTIGATOR_DOCS } = URLS;

interface HttpFormProps {
  isUpdating: boolean;
  deleteLoading: boolean;
  onDeleteOpen: () => void;
  clearInProgress: () => void;
  activeExporter: HttpExporter | null;
  setIsUpdating: (isUpdating: boolean) => void;
  setIsSaveLoading: (isSaveLoading: boolean) => void;
  setIsSaveDisabled: (isSaveDisabled: boolean) => void;
  setIsFormUnchanged: (isFormUnchanged: boolean) => void;
  setIsCancelDisabled: (isCancelDisabled: boolean) => void;
  setFormRef: (formRef: RefObject<HTMLFormElement> | null) => void;
}

interface AlertExportData {
  name: string;
  enabled: boolean;
  uri: string;
  headers: HttpExporterHeader[];
  http_auth: HttpExporterAuthInput;
  tls: ExporterTLSInfoInput;
}

export const HttpForm = ({
  isUpdating,
  setFormRef,
  onDeleteOpen,
  deleteLoading,
  setIsUpdating,
  activeExporter,
  clearInProgress,
  setIsSaveLoading,
  setIsSaveDisabled,
  setIsFormUnchanged,
  setIsCancelDisabled,
}: HttpFormProps) => {
  const { register, handleSubmit, setValue, control, watch } =
    useForm<AlertExportData>({
      mode: 'onChange',
      defaultValues: {
        name: '',
        enabled: false,
        uri: '',
        http_auth: {
          basic: {
            username: '',
            password: '',
          },
          bearer: {
            token: '',
          },
          strategy: 'none',
        },
        headers: [],
        tls: {
          verify_certificate: false,
        },
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headers',
  });

  const { enabled: enabledLabel, disabled: disabledLabel } =
    i18n.pages.integrations;

  const {
    forms,
    httpForm,
    investigatorDocumentation,
    userGuide,
    firewallRules,
    tooltips,
    toast,
  } = i18n.pages.alertExport;

  const {
    noSpaces,
    httpDocumentation,
    authenticationType,
    selectAuthenticationType,
    customHeaders,
  } = httpForm;

  const { saveSuccessDesc, saveSuccessTitle, saveFailDesc, saveFailTitle } =
    toast;

  const navigate = useNavigate();
  const showToastMessage = useToast();
  const { user } = useContext(AuthContext);
  const tenant = user?.attributes['custom:tenant_id'];

  const formData = watch();

  const formRef = useRef<HTMLFormElement>(null);

  const [exportConfig, setExportConfig] = useState<
    AlertExportData | HttpExporter | null
  >(activeExporter);
  const [shouldDisableSave, setShouldDisableSave] = useState(true);
  const [showAuthPasswordType, setShowAuthPasswordType] =
    useState<boolean>(false);

  const { name, enabled, uri, headers, http_auth, tls } = exportConfig || {};

  const authStrategyOptions: AuthStrategyOption[] = [
    { value: 'none', label: 'None' },
    { value: 'basic', label: 'Basic' },
    { value: 'bearer', label: 'Bearer' },
  ];

  const fillForm = useCallback(() => {
    setValue('name', name || '');
    setValue('enabled', enabled || false);
    setValue('uri', uri || '');
    setValue('headers', headers || []);
    setValue(
      'http_auth',
      http_auth || {
        bearer: {
          token: '',
        },
        basic: {
          username: '',
          password: '',
        },
        strategy: 'none',
      },
    );
    setValue('tls', tls || { verify_certificate: false });
  }, [setValue, name, enabled, uri, headers, http_auth, tls]);

  useEffect(() => {
    setExportConfig(activeExporter);
  }, [activeExporter]);

  useEffect(() => {
    fillForm();
  }, [activeExporter, exportConfig, fillForm]);

  const [saveExporter, { loading: saveLoading, error: saveError }] =
    useSaveExporter({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_EXPORTERS,
        },
      ],
      onCompleted: (data) => {
        const { saveExporter } = data;
        const { exporter_id } = saveExporter;
        setShouldDisableSave(true);
        setIsUpdating(false);
        showToastMessage({
          status: 'success',
          title: saveSuccessTitle,
          description: saveSuccessDesc,
          isClosable: true,
          position: 'bottom-right',
        });
        clearInProgress();
        navigate(exporter_id);
      },
      onError: (error) => {
        setShouldDisableSave(false);
        setIsUpdating(false);
        showToastMessage({
          status: 'error',
          title: saveFailTitle,
          description: saveFailDesc,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const areHeadersValid =
    formData.headers.length > 0
      ? formData.headers.every(
          (header) =>
            header.name && header.name.indexOf(' ') === -1 && header.value,
        )
      : true;

  const isBasicValid =
    formData.http_auth.strategy === 'basic'
      ? formData.http_auth.basic.username !== '' &&
        formData.http_auth.basic.password !== ''
      : true;

  const isBearerValid =
    formData.http_auth.strategy === 'bearer'
      ? formData.http_auth.bearer.token !== ''
      : true;

  const formIsPopulated =
    formData.name !== '' &&
    formData.uri !== '' &&
    isBasicValid &&
    isBearerValid &&
    areHeadersValid;

  const formIsUnchanged = _.isEqual(formData, {
    name,
    enabled,
    uri,
    http_auth,
    headers,
    tls,
  });

  const isSaveDisabled = formIsUnchanged || !formIsPopulated || saveLoading;

  // only need to do this once and update the state else where like after a save or delete network request.
  useEffect(() => {
    setShouldDisableSave(isSaveDisabled);
  }, []);

  const submitAlertExport = async (data: AlertExportData) => {
    setShouldDisableSave(false);
    setIsUpdating(true);
    setExportConfig(data);
    const { name, enabled, uri, headers, http_auth, tls } = data;
    const input = {
      tenant,
      name,
      enabled,
      exporter: 'http',
      http: {
        uri,
        headers: headers.map((header) => {
          return {
            name: header.name,
            value: header.value,
          };
        }),
        auth: {
          basic: {
            username: http_auth.basic.username,
            password: http_auth.basic.password,
          },
          bearer: {
            token: http_auth.bearer.token,
          },
          strategy: http_auth.strategy,
        },
        tls: {
          verify_certificate: tls.verify_certificate,
        },
      },
      exporter_id:
        activeExporter?.exporter_id !== '' ? activeExporter?.exporter_id : null,
    };
    saveExporter({
      variables: {
        input,
      },
    });
  };

  const handleVerifySSLChange = async (
    event: FormEvent<HTMLInputElement>,
    onChange: (val: boolean) => void,
  ) => {
    const isChecked = (event.target as HTMLInputElement).checked;
    onChange(isChecked);
    setValue('tls', { ...tls, verify_certificate: isChecked });
  };

  const handleAuthStrategyChange = async (event: any, onChange: any) => {
    const value = event.value;
    onChange(value);
    setAuthStrategy(value);
  };

  const [authStrategy, setAuthStrategy] = useState<string>(
    http_auth?.strategy || 'none',
  );

  const togglePasswordType = () => {
    if (isUpdating) return;
    setShowAuthPasswordType(!showAuthPasswordType);
  };

  useEffect(() => {
    setAuthStrategy(http_auth?.strategy || 'none');
  }, [http_auth]);

  useSetExporterFormState({
    formRef,
    isUpdating,
    saveLoading,
    deleteLoading,
    isSaveDisabled,
    formIsUnchanged,
    shouldDisableSave,
    setFormRef,
    setIsSaveLoading,
    setIsSaveDisabled,
    setIsFormUnchanged,
    setIsCancelDisabled,
  });

  return (
    <>
      <VStack my={2} spacing={4} alignItems="start">
        <Text textStyle="body-md">
          {httpDocumentation}{' '}
          <Link
            isExternal
            color="blue.400"
            textDecoration="none"
            href={INVESTIGATOR_DOCS}
          >
            {investigatorDocumentation}
          </Link>
          .
        </Text>
        <Alert variant="subtle">
          <Text textStyle="body-md">
            {firewallRules}{' '}
            <Link
              isExternal
              color="blue.400"
              textDecoration="none"
              href={`${INVESTIGATOR_DOCS_ALERT_EXPORT}#generic-http-exporter`}
            >
              {userGuide}
            </Link>
            .
          </Text>
        </Alert>
      </VStack>
      <VStack
        as="form"
        spacing={4}
        ref={formRef}
        align="flex-start"
        id="alertExportForm"
        onSubmit={handleSubmit(submitAlertExport)}
      >
        <Controller
          name="enabled"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <Switch
              inline
              name={name}
              isChecked={value}
              onChange={onChange}
              data-testid="exportSwitch"
              isDisabled={isUpdating}
              label={formData.enabled ? enabledLabel : disabledLabel}
            />
          )}
        />
        <Flex w="100%">
          <Input
            {...register('name', { required: true })}
            isRequired
            label={forms.exporterName}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%">
          <Input
            {...register('uri', { required: true })}
            isRequired
            label={forms.url}
            isDisabled={isUpdating}
          />
        </Flex>
        <Box w="100%">
          <Controller
            control={control}
            name={'http_auth.strategy'}
            render={({ field: { onChange, name, value } }) => {
              return (
                <Select
                  name={name}
                  isDisabled={isUpdating}
                  onChange={(event) => {
                    handleAuthStrategyChange(event, onChange);
                  }}
                  options={authStrategyOptions}
                  label={authenticationType}
                  placeholder={selectAuthenticationType}
                  value={authStrategyOptions.find(
                    (option) => option.value === value,
                  )}
                />
              );
            }}
          />
        </Box>
        {authStrategy === 'basic' && (
          <>
            <Flex w="100%">
              <Input
                isRequired={authStrategy === 'basic'}
                {...register('http_auth.basic.username')}
                label={forms.username}
                isDisabled={isUpdating}
              />
            </Flex>
            <Flex w="100%">
              <Input
                isRequired={authStrategy === 'basic'}
                {...register('http_auth.basic.password')}
                label={forms.password}
                isDisabled={isUpdating}
                type={showAuthPasswordType ? 'text' : 'password'}
                rightElement={
                  <Button
                    variant="link"
                    isDisabled={isUpdating}
                    onClick={togglePasswordType}
                  >
                    <MuiIcon cursor="pointer">
                      {showAuthPasswordType ? 'visibility' : 'visibility_off'}
                    </MuiIcon>
                  </Button>
                }
              />
            </Flex>
          </>
        )}
        {authStrategy === 'bearer' && (
          <Flex w="100%">
            <Input
              isRequired={authStrategy === 'bearer'}
              {...register('http_auth.bearer.token')}
              label={forms.token}
              isDisabled={isUpdating}
            />
          </Flex>
        )}
        <Flex w="100%">
          <Text
            textStyle="body-md"
            color="blue.300"
            fontWeight="600"
            textTransform="uppercase"
            opacity={isUpdating ? 0.4 : 1}
            aria-disabled={isUpdating}
          >
            {customHeaders.title}
          </Text>
        </Flex>
        <Flex w="100%">
          <Panel layerStyle="first">
            {fields.map((item, index) => (
              <Flex key={item.id} mb={4}>
                <HStack w="100%" spacing={6} alignItems="flex-end">
                  <Input
                    isRequired
                    tooltip={noSpaces}
                    label={customHeaders.key}
                    isDisabled={isUpdating}
                    error={formData.headers[index].name.includes(' ')}
                    {...register(`headers.${index}.name`, {
                      pattern: REGEX.INCLUDES_SPACE,
                    })}
                  />
                  <Input
                    isRequired
                    label={customHeaders.value}
                    isDisabled={isUpdating}
                    {...register(`headers.${index}.value`)}
                  />
                  <Box>
                    <Button
                      mb={1}
                      size="md"
                      variant="ghost"
                      aria-label={forms.deleteBtn}
                      isDisabled={isUpdating}
                      onClick={() => remove(index)}
                    >
                      <MuiIcon>delete</MuiIcon>
                    </Button>
                  </Box>
                </HStack>
              </Flex>
            ))}
            <Button
              variant="ghost"
              leftIcon={<MuiIcon>add</MuiIcon>}
              isDisabled={isUpdating}
              onClick={() => append({ name: '', value: '' })}
            >
              {customHeaders.newRow}
            </Button>
          </Panel>
        </Flex>
        <Flex w="100%" alignItems="end" justifyContent="space-between">
          <Controller
            name="tls"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <Switch
                name={name}
                isDisabled={isUpdating}
                label={forms.verifySsl}
                tooltip={tooltips.verifySsl}
                isChecked={value.verify_certificate}
                onChange={(event: FormEvent<HTMLInputElement>) =>
                  handleVerifySSLChange(event, (val) =>
                    onChange({ verify_certificate: val }),
                  )
                }
                data-testid="sslSwitch"
              />
            )}
          />
          {!activeExporter?.inProgress && (
            <IconButton
              size="sm"
              variant="solid"
              colorScheme="red"
              aria-label={forms.deleteBtn}
              isDisabled={saveLoading || deleteLoading}
              data-testid="alert-exporter-delete-button"
              onClick={() => {
                setIsUpdating(true);
                onDeleteOpen();
              }}
              icon={
                deleteLoading ? (
                  <Spinner size={'xs'} />
                ) : (
                  <TrashIcon boxSize={5} />
                )
              }
            />
          )}
        </Flex>
      </VStack>
    </>
  );
};
