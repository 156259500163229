import { DataTablePageSize } from '@gamma/data-table';
import {
  AlertMetadata,
  AlertMetadataPaginated,
  AlertSummary,
  Detection,
  DetectionSummary,
  ExcludedEntity,
  NICHistory,
  PaginatedAlerts,
  PaginatedAlertSummaries,
  PaginatedDetections,
  PaginatedDetectionSummaries,
  PaginatedExcludedEntities,
  PaginatedNICHistory,
  PaginatedUserHistory,
  UserHistory,
} from '@gamma/investigator/queries';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SortingRule } from 'react-table';

export const useManualPagination = <
  TableType extends
    | AlertSummary
    | AlertMetadata
    | ExcludedEntity
    | Detection
    | DetectionSummary
    | NICHistory
    | UserHistory,
>({
  initialFetch,
  sortBy: initialSortBy = [],
  offsetReset = false,
  setOffsetReset,
}: {
  initialFetch?: boolean;
  sortBy?: SortingRule<TableType>[];
  offsetReset?: boolean;
  setOffsetReset?: Dispatch<SetStateAction<boolean>>;
} = {}) => {
  const prevInitialFetch = useRef(initialFetch).current;
  // const prevInitialSortBy = useRef(initialSortBy).current;

  const [offset, setOffset] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<DataTablePageSize>(10);
  const [sortBy, setSortBy] = useState<SortingRule<TableType>[]>(initialSortBy);

  const getPageCount = (
    items?:
      | PaginatedAlerts
      | PaginatedAlertSummaries
      | AlertMetadataPaginated
      | PaginatedExcludedEntities
      | PaginatedDetections
      | PaginatedDetectionSummaries
      | PaginatedNICHistory
      | PaginatedUserHistory,
  ) => {
    const { total_items = 0, size = 0 } = items ?? {};
    const pages = Math.ceil(total_items / size);

    setPageCount(pages);
    return;
  };

  const formatNestedSorts = (sortBy: SortingRule<TableType>[]) =>
    sortBy.map((sort) => {
      return {
        id:
          sort.id === 'timestamp'
            ? 'alert_timestamp.observed'
            : sort.id === 'entity_name'
              ? 'entity_name.keyword'
              : sort.id === 'created_by_alias'
                ? 'created_by_alias.keyword'
                : sort.id,
        desc: sort.desc,
      };
    });

  const getSortsForAPI = (sortBy: SortingRule<TableType>[]) => {
    const sorts = sortBy.map((sort: SortingRule<TableType>) => {
      return {
        sort_by: sort.id,
        sort_dir: sort.desc === true ? 'desc' : 'asc',
      };
    });
    return sorts;
  };

  const onFetchData = useMemo(
    () =>
      (
        pageIndex: number,
        pageSize: number,
        sortBy: SortingRule<TableType>[],
      ) => {
        setSortBy(sortBy);
        setPageIndex(offsetReset ? 0 : pageIndex);
        setOffset(pageIndex * pageSize);
        setPageSize(pageSize as DataTablePageSize);
        setOffsetReset?.(false);
      },
    [offsetReset, setOffsetReset],
  );

  // useEffect(() => {
  //   if (prevInitialSortBy !== initialSortBy) {
  //     setSortBy(initialSortBy);
  //   }
  // }, [prevInitialSortBy, initialSortBy]);

  useEffect(() => {
    if (prevInitialFetch !== initialFetch) {
      setOffset(0);
      setPageIndex(0);
      setPageSize(10);
    }
  }, [prevInitialFetch, initialFetch]);

  return {
    sortBy,
    offset,
    pageSize,
    pageIndex,
    pageCount,
    onFetchData,
    getPageCount,
    getSortsForAPI,
    formatNestedSorts,
  };
};
