import { Heading, HeadingProps } from '@chakra-ui/layout';

export type PanelHeadingProps = HeadingProps;

export const PanelHeading = ({ children, ...rest }: PanelHeadingProps) => {
  return (
    <Heading as="h5" {...rest} textStyle="h5">
      {children}
    </Heading>
  );
};
