import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { PaginatedExcludedEntities } from './types';

export const ADD_EXCLUDED_ENTITIES_TO_ALERT_METADATA = gql`
  mutation addExcludedEntitiesToAlertMetadata($items: [ExcludedEntityInput]!) {
    addExcludedEntitiesToAlertMetadata(items: $items) {
      offset
      size
      excluded_entities {
        _score
        content_id
        entity_id
        entity_ip
        entity_ip_range
        entity_name
        entity_type
        tenant
        created_by
        created_by_alias
        created_timestamp
      }
      total_items
    }
  }
`;

interface IAddExcludedEntitiesToAlertMetadata {
  addExcludedEntitiesToAlertMetadata: PaginatedExcludedEntities;
}

export const useAddExcludedEntitiesToAlertMetadata = (
  options: MutationHookOptions<
    IAddExcludedEntitiesToAlertMetadata,
    OperationVariables
  >,
) => {
  return useMutation<IAddExcludedEntitiesToAlertMetadata>(
    ADD_EXCLUDED_ENTITIES_TO_ALERT_METADATA,
    options,
  );
};
