import {
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  useStyles,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { useControlledProp } from '@gamma/hooks';
import {
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface DataTablePaginationProps {
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  itemCount: number;
  setPageSize: (size: number) => void;
  onPageSizeChange?: (size: DataTablePageSize) => void;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  onPageIndexChange?: (page: number) => void;
  canPreviousPage: boolean;
  previousPage: () => void;
  canNextPage: boolean;
  nextPage: () => void;
}

export type DataTablePageSize = 5 | 10 | 20 | 25 | 50 | 100;
const rowSizes: DataTablePageSize[] = [5, 10, 20, 25, 50, 100];

export const DataTablePagination = ({
  pageSize,
  pageIndex,
  pageCount,
  itemCount,
  setPageSize,
  onPageSizeChange,
  gotoPage,
  onPageIndexChange,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
}: DataTablePaginationProps) => {
  const pageIndexRef = useRef<HTMLInputElement>(null);
  const [pageIndexVal, setPageIndexVal] = useState<number>(pageIndex + 1);
  const styles = useStyles();

  useControlledProp(
    pageIndex,
    (value) => {
      setPageIndexVal(value + 1);
    },
    // this looks weird, but the hook is set up use the value as a bool for
    // success/fail callbacks, this is the failure due to value === 0
    () => {
      setPageIndexVal(1);
    },
  );

  // clamp page index to max page count
  useEffect(() => {
    if (pageCount && pageIndexVal > pageCount) {
      setPageIndexVal(pageCount);
    }
  }, [pageCount, pageIndexVal]);

  const handleIndexKeydown: KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === 'Enter') {
      const parsedValue = Number((event.target as HTMLInputElement).value);
      // ensure the request doesn't try to go to page -1
      const actualVal = getAdjustedIndex(parsedValue);
      gotoPage(actualVal);
      onPageIndexChange?.(actualVal);
      pageIndexRef.current?.blur();
    }
  };

  const lastItemIndex = useMemo(() => {
    const index = (pageIndex + 1) * pageSize;
    if (itemCount < index) {
      return itemCount;
    }
    return index;
  }, [pageIndex, pageSize, itemCount]);

  return (
    <Flex __css={styles.pagination} data-testid="data-table-pagination">
      <HStack alignItems="center">
        <Select
          variant="pagination"
          size="sm"
          width="auto"
          aria-label="Page size"
          value={pageSize}
          onChange={(event) => {
            setPageSize(Number(event.target.value));
            onPageSizeChange?.(Number(event.target.value) as DataTablePageSize);
          }}
          bg="layer.1"
          borderRadius="base"
          data-testid="data-table-pagination-size"
          px={0}
          py={1.5}
        >
          {rowSizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </Select>
        <Text>
          {pageIndex * pageSize + 1}-{lastItemIndex} of {itemCount} items
        </Text>
      </HStack>
      <Flex alignItems="center" justifyContent="space-between">
        <HStack alignItems="center">
          <label style={{ display: 'contents' }}>
            <Text textStyle="body-md">Page</Text>
            <NumberInput
              value={pageIndexVal}
              onChange={(value) => {
                const page = value ? Number(value) : 0;
                setPageIndexVal(page);
              }}
              onBlur={() => {
                const newPage = getAdjustedIndex(pageIndexVal);
                gotoPage(newPage);
                onPageIndexChange?.(newPage);
              }}
              size="sm"
              min={1}
              max={pageCount || 1}
              bg="layer.1"
              borderRadius="base"
              overflow="hidden"
              minW={9}
              _focusWithin={{
                boxShadow: '0px 0px 0px 1.5px #0093ee',
                borderColor: 'blue.500',
              }}
            >
              <NumberInputField
                ref={pageIndexRef}
                onKeyPress={handleIndexKeydown}
                size={`${pageIndex + 1}`.length}
                width="full"
                minW="full"
                pl={1.5}
                pr={1}
                textAlign="center"
                bgColor="inherit"
                borderRadius="base"
                data-testid="data-table-pagination-page"
              />
            </NumberInput>
          </label>
          <Text
            textStyle="body-md"
            data-testid="data-table-pagination-page-count"
          >
            of {pageCount}
          </Text>
          <ButtonGroup
            spacing={2}
            variant="solid"
            colorScheme="gray"
            size="box-md"
          >
            <IconButton
              onClick={() => {
                previousPage();
                onPageIndexChange?.(pageIndex - 1);
              }}
              isDisabled={!canPreviousPage}
              icon={<MuiIcon size="sm">keyboard_arrow_left</MuiIcon>}
              aria-label="previous page"
              data-testid="data-table-pagination-previous-button"
            />
            <IconButton
              onClick={() => {
                nextPage();
                onPageIndexChange?.(pageIndex + 1);
              }}
              isDisabled={!canNextPage}
              icon={<MuiIcon size="sm">keyboard_arrow_right</MuiIcon>}
              aria-label="next page"
              data-testid="data-table-pagination-next-button"
            />
          </ButtonGroup>
        </HStack>
      </Flex>
    </Flex>
  );
};

const getAdjustedIndex = (index: number) => {
  return index ? index - 1 : index;
};
