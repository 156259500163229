import { CTAButton } from '@gamma/buttons';
import { Input } from '@gamma/form-fields';
import { CONSTANTS, ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { ErrorBoundary } from '@gamma/overlay';
import {
  CardPageBackButton,
  CardPageBody,
  CardPageCTA,
  CardPageHeading,
  CardPageSubtitle,
} from '@gamma/pages';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';

export const SSOLogin = () => {
  const [validateDomain, setValidateDomain] = useState<boolean>(false);

  const { title, subTitle, button, domain_required, invalid_domain } =
    i18n.pages.login.domain;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: { domain: Cookies.get('sso_domain') ?? '' },
  });

  const routerLocation = useLocation();

  const onSubmit = async (formData: FieldValues) => {
    const sso_domain = formData.domain;
    if (validator.isFQDN(sso_domain)) {
      Cookies.set('sso_domain', sso_domain, { expires: 30 });
      window.sessionStorage.setItem('sso_domain', sso_domain);
      setValidateDomain(true);

      const {
        SSO: { domain, client_id, redirect_uri },
      } = CONSTANTS;

      axios({
        method: 'get',
        url: `/verifydomain?domain=${sso_domain}`,
        headers: {
          'Content-Type': 'application/javascript',
        },
      })
        .then(function (response) {
          const urlParams = new URLSearchParams(routerLocation.search);
          const next = urlParams.get('next') ?? '';
          if (next) {
            localStorage.setItem('next', next);
          }

          const location = `https://${domain}/oauth2/authorize?idp_identifier=${sso_domain}&redirect_uri=${redirect_uri}&response_type=code&client_id=${client_id}&scope=aws.cognito.signin.user.admin+email+openid+profile`;
          window.location.href = location;
        })
        .catch(function (error) {
          setValidateDomain(false);
          setError(
            'domain',
            { type: 'message', message: invalid_domain },
            { shouldFocus: true },
          );
        });
    } else {
      setError(
        'domain',
        { type: 'message', message: invalid_domain },
        { shouldFocus: true },
      );
    }
  };

  return (
    <ErrorBoundary page="SSO Login Page">
      <CardPageBody
        as="form"
        data-testid="sso-login-card"
        id="domainForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CardPageBackButton
          onClick={() =>
            navigate(`${ROUTES.login}${routerLocation.search}`, {
              replace: true,
            })
          }
        />
        <CardPageHeading>{title}</CardPageHeading>
        <CardPageSubtitle textStyle="body-md">{subTitle}</CardPageSubtitle>
        <Input
          label="Domain"
          placeholder="domain.com"
          isRequired
          isReadOnly={validateDomain}
          error={errors?.domain?.message?.toString() ?? ''}
          w="full"
          {...register('domain', { required: domain_required })}
        />
      </CardPageBody>
      <CardPageCTA>
        <CTAButton
          data-testid="sso-continue-cta"
          type="submit"
          form="domainForm"
          isLoading={validateDomain}
        >
          {button}
        </CTAButton>
      </CardPageCTA>
    </ErrorBoundary>
  );
};
