import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

interface IGetPrivileges {
  getPrivileges: string;
}

const GET_PRIVILEGES = gql`
  query getPrivileges {
    getPrivileges
  }
`;

export const useLazyGetPrivileges = (
  options?: LazyQueryHookOptions<IGetPrivileges, OperationVariables>,
) => {
  return useLazyQuery(GET_PRIVILEGES, options);
};
